.interview-slider-mob {
	display: none;
}
.interview {
	background-image: linear-gradient( to bottom, rgb(255,255,255) 0%, rgb(236,236,236) 100%);
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		overflow: hidden;
	}
	.container {
		display: flex;
		position: relative;
		z-index: 1;
	}

	.section-title {
		font-family: $default-font-semibold;
		font-size: 24px;
		margin-bottom: 90px;
		&.mob {
			display: none;
		}
	}
}

.one-slide {
	.interview-text {
		max-width: 750px;
	}
	.interview-item__quote {
		max-width: 600px;
	}
	.interview-other {
		display: none;
	}
}

.interview-text {
	max-width: 410px;
	padding-top: 84px;
	padding-bottom: 144px;
	margin-right: 100px;
}

.interview-item {
	display: none;
	&.is-active {
		display: block;
	}
	&__name {
		display: inline-block;
		font-family: $default-font-bold;
		font-size: 12px;
		line-height: 1;
		text-transform: uppercase;
		color: #fff;
		background-color: $black-3;
		padding: 8px 12px;
		margin-bottom: 30px;
		position: relative;
		&::before {
			content: attr(data-name);
			font-family: $default-font-exbold;
			white-space: nowrap;
			font-size: 202px;
			letter-spacing: -.022em;
			text-transform: uppercase;
			color: $gray-ec;
			position: absolute;
			top: -60px;
			left: -10px;
			z-index: -1;
		}
	}
	&__quote {
		font-family: $default-font-light;
		font-size: 44px;
		line-height: 57px;
		max-width: 380px;
		margin-bottom: 35px;
	}
	&__quote-inner {
		position: relative;
		&::before {
			content: attr(data-before);
			position: absolute;
			top: 0;
			right: calc(100% + 18px);
		}
		&::after {
			content: attr(data-after);
			margin-left: 18px;
		}
	}
	&__link {
		display: inline-flex;
		align-items: center;
		color: $blue-00;
		font-size: 14px;
		line-height: 1;
		font-family: $default-font-semibold;
		&:hover {
			.icon {
				background-color: #fff;
				svg {
					fill: $blue-00;
				}
			}
		}
		.icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 52px;
			height: 52px;
			padding-left: 3px;
			border-radius: 50%;
			background-color: $blue-00;
			border: 1px solid $blue-00;
			margin-right: 23px;
			transition: .2s linear;
			transition-property: background-color;
			svg {
				fill: #fff;
				transition: fill .2s linear;
			}
		}
	}
}

.interview-photo {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	width: 530px;
	padding-top: 50px;
	&:before {
		content: '';
		position: absolute;
		bottom: 117px;
		left: 50%;
		transform: translateX(-50%);
		width: 530px;
		height: 530px;
		border-radius: 50%;
		background-color: $blue-00;
	}
	&__img {
		position: relative;
		display: block;
		width: 100%;
		margin: 0 auto;
		img {
			display: block;
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
		}
	}
}

.interview-other {
	display: flex;
	flex-direction: column;
	width: 370px;
	margin-left: auto;
	padding-top: 137px;
	padding-bottom: 48px;
}
.interview-item-sm {
	margin-bottom: 50px;
	&.hidden {
		display: none;
	}
	&.inverted {
		.img {
			order: 1;
			margin-left: 33px;
			margin-right: 0;
		}
	}
	a {
		display: flex;
		align-items: center;
	}
	.img {
		position: relative;
		display: flex;
		align-items: flex-end;
		flex-shrink: 0;
		width: 170px;
		height: 190px;
		margin-right: 33px;
		border-bottom-left-radius: 85px;
		border-bottom-right-radius: 85px;
		overflow: hidden;
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: $blue-00;
			width: 170px;
			height: 170px;
			border-radius: 50%;
		}
		img {
			position: relative;
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
		}
	}
	.text {
		font-family: $default-font;
		font-size: 18px;
		line-height: 24px;
		max-width: 155px;
	}
}
.interview-nav {
	display: flex;
	align-items: center;
	//margin-top: auto;
	&.hidden {
		display: none;
	}
}

@media (max-width: 1650px) {
	.interview-text {
		width: 415px;
		max-width: none;
		flex-shrink: 0;
	}
	.interview-item {
		&__quote {
			max-width: 415px;
			margin-left: 35px;
		}
	}
}
@media (max-width: 1500px) {
	.interview-text {
		margin-right: 50px;
	}
	.interview-item {
		&__name {
			&:before {
				font-size: 12vw;
				//white-space: normal;
			}
		}
	}
	.interview-photo {
		margin: 0 auto;
	}
	.interview-other {
		width: 330px;
		margin-left: 50px;
	}
	.interview-item-sm {
		&.inverted {
			.img {
				margin-left: 20px;
			}
		}
		.img {
			width: 130px;
			height: 145px;
			margin-right: 20px;
			&::before {
				width: 130px;
				height: 130px;
			}
		}
		.text {
			font-size: 16px;
			line-height: 22px;
			max-width: 200px;
		}
	}
}

@media (max-width: 1440px) {
	.interview {
		.section-title {
			white-space: nowrap;
		}
	}
	.interview-text {
		margin-right: 30px;
		padding-bottom: 90px;
		//max-width: 390px;
	}

	.interview-item {
		&__quote {
			font-size: 36px;
			line-height: 50px;
		}
	}

	.interview-photo {
		max-width: 400px;
		width: 100%;
		&::before {
			width: 400px;
			height: 400px;
		}
		&__img {
			width: 85%;
		}
	}

	.interview-other {
		margin-left: 30px;
	}
}

@media (max-width: 1350px) {
	.interview-text {
		width: 360px;
	}
}
@media (max-width: $desktop) {
	.interview-photo {
		//display: none;
	}

	.interview-text {
		flex-shrink: 0;
		//max-width: none;
		max-width: calc(100% - 400px);
		width: 50%;
		margin-right: 0;
		padding-right: 40px;
	}
	.interview-item {
		&__quote {
			max-width: 550px;
		}
	}
	.interview-other {
		width: 50%;
		margin-left: 0;
		padding: 0;
		position: absolute;
		bottom: 30px;
		left: 20px;
	}
	.interview-item-sm {
		display: none;
	}
}

@media (max-width: $tablet) {

}
@media (max-width: 900px) {
	.interview-text {
		max-width: calc(100% - 360px);
		padding-right: 20px;
	}
	.interview-item {
		&__quote-inner {
			&:before {
				right: calc(100% + 10px);
			}
			&:after {
				margin-left: 10px;
			}
		}
		&__quote {
			font-size: 32px;
			line-height: 42px;
			margin-left: 24px;
		}
		&__link {
			.icon {
				width: 46px;
				height: 46px;
			}
		}
	}
	.interview-photo {
		max-width: 360px;
		&::before {
			width: 360px;
			height: 360px;
		}
	}
}
@media (max-width: $mobile-big) {
	.interview {
		padding-top: 45px;
		padding-bottom: 54px;
		.container {
			display: block;
		}
		.section-title {
			display: none;
			text-align: center;
			white-space: normal;
			margin-bottom: 20px;
			&.mob {
				display: block;
			}
		}
	}
	.interview-other,
	.interview-photo {
		display: none;
	}
	.interview-text {
		padding: 0;
		max-width: none;
		width: 100%;
	}
	.interview-item {
		display: block;
		&__name {
			&:before {
				display: none;
			}
		}
	}
	.interview-slider-mob {
		display: block;
		margin-bottom: 32px;
	}
	.interview-slider-mob-img {
		max-width: 320px;
		margin: 0 auto;
		&__item {
			opacity: .5;
			transition: opacity .3s linear;
			&.slick-current {
				opacity: 1;
				.interview-slider-mob-img__item-inner {
					transform: translateX(-50%) scale(1);
					z-index: 1;
				}
			}
		}
		&__item-inner {
			//transform: scale(.5);
			//position: relative;
			position: relative;
			left: 50%;
			transform: translateX(-50%) scale(.5);
			transition: transform .3s linear;
			display: flex;
			align-items: flex-end;
			flex-shrink: 0;
			width: 170px;
			height: 190px;
			margin: 0 auto;
			border-bottom-left-radius: 85px;
			border-bottom-right-radius: 85px;
			overflow: hidden;
			&::before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0 auto;
				background-color: $blue-00;
				width: 170px;
				height: 170px;
				border-radius: 50%;
			}
			img {
				position: relative;
				max-width: 100%;
				max-height: 100%;
				margin: 0 auto;
			}
		}
	}
}
@media (max-width: $mobile) {
	.interview {
		.section-title {
			font-size: 18px;
			line-height: normal;
		}
	}
	.interview-item {
		&__name {
			padding: 6px 11px;
			margin-bottom: 25px;
		}
		&__quote {
			font-size: 24px;
			line-height: 30px;
			margin-bottom: 30px;
		}
	}
}
