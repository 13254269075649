.courses {
  background-color: $gray-ec;
  padding: 55px 0 115px;
  &__title {
    margin-right: 10px;
  }
  &__grid {
    display: flex;
  }
  .cat-select-block {
    align-self: flex-start;
    margin-right: auto;
  }
}

.courses-main {
  width: 100%;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}

.courses-item {
  background-color: #fff;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px solid rgba(0,0,0,0);
    pointer-events: none;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.is-active {
    &::before {
      border-color: $blue-00;
    }
  }
  &__top {
    display: flex;
    align-items: flex-start;
    padding: 45px 24px 40px 50px;
  }
  &__main {
    width: 100%;
    padding-right: 72px;
  }
  &__header {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px;
    line-height: normal;
  }
  &__profile {
    border: 1px solid $blue-00;
    color: $blue-00;
    text-transform: uppercase;
    padding: 4px 10px;
    margin-right: 24px;
  }
  &__level {
    text-transform: uppercase;
    margin-left: 22px;
    color: #fff;
    background-color: $blue-00;
    padding: 4px 12px;
  }
  &__review-block {
    display: flex;
    align-items: center;
    margin-left: 17px;
    img {
      margin-right: 9px;
    }
  }
  &__company-img {
    max-width: 90px;
    max-height: 50px;
    position: absolute;
    right: 15px;
    top: 20px;
  }
  &__title {
    font-family: $default-font-bold;
    font-size: 24px;
    line-height: normal;
    display: inline-block;
    transition: color .2s linear;
    margin: 20px 0;
    &:hover {
      color: $blue-00;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,0,0,.65);
  }
  &__bot {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eaeaea;
  }

  &__bot-icon-block {
    margin-right: 15px;
    margin-top: 5px;
    flex-shrink: 0;
    svg {
      max-width: 15px;
      max-height: 20px;
      fill: rgba(0,0,0,.63);
    }
    img {
      max-width: 18px;
      max-height: 18px;
    }
  }
  &__bot-text-block {
    display: flex;
    flex-direction: column;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 100%;
    }
  }
  &__bot-text {
    color: rgba(0,0,0,.63);
  }
  a.courses-item__bot-text {
    font-family: $default-font-bold;
    color: #000;
    transition: .2s linear;
    transition-property: color;
    &:hover {
      color: $blue-00;
    }
  }
  &__bot-item {
    font-size: 14px;
    line-height: 24px;
    padding: 30px 42px;
    display: flex;
    border-right: 1px solid #eaeaea;
    &:last-child {
      border: none;
    }
    &:first-child,
    &:last-child,
    &:nth-child(2) {
      flex-shrink: 0;
    }
    &--links {
      display: block;
      .courses-item {
        &__bot-text-block {
          display: flex;
          align-items: center;
          flex-direction: row;
        }
      }
      img {
        margin-right: 19px;
      }
      a.courses-item__bot-text {
        color: $blue-00;
        &:hover {
          color: #000;
        }
      }
    }
  }
}

.courses-slider {
  position: relative;
  width: 168px;
  //min-height: 168px;
  flex-shrink: 0;
  margin-right: 43px;
  //.slick-track {
  //  min-width: 168px;
  //}
  &__item {
    width: 100%;
    //min-width: 168px;
    height: 168px;
  }
  .img-wrapper {
    width: 100%;
    height: 100%;
  }
  .default-arrow {
    position: absolute;
    bottom: 12px;
    z-index: 1;
    @extend %blue-circle-arrow;
    &--next {
      left: 47px;
    }
    &--prev {
      left: 17px;
    }
  }
}

.courses-aside {
  width: 364px;
  flex-shrink: 0;
  margin-left: 30px;
  &__title {
    font-family: $default-font-semibold;
    font-size: 24px;
    line-height: normal;
    margin-bottom: 25px;
  }
  .cat-block {
    margin-bottom: 55px;
  }
}

@media (max-width: 1600px) {
  .courses-item {
    &__top {
      padding: 30px 20px 30px 20px;
    }
    &__bot-item {
      padding: 25px;
    }
  }
  .courses-slider {
    margin-right: 30px;
  }
  .courses-aside {
    margin-left: 20px;
  }
  .courses-item {
    &__bot-item {
      padding: 15px;
    }
  }
}
@media (max-width: 1279px) {
  .courses {
    &__grid {
      flex-direction: column;
    }
    .sorting-block .sorting-select__list {
      left: auto;
      right: 0;
    }
  }
  .courses-item {
    &__bot-item {
      align-items: center;
    }
    &__bot-item--address {
      flex: 1;
      justify-content: center;
    }
  }
  .courses-aside {
    width: 100%;
    order: -1;
    margin-left: 0;
    &__bot-title {
      display: none;
    }
    .training-item {
      display: none;
    }
    .cat-block {
      margin-bottom: 40px;
    }
  }
}
@media (max-width: 900px) {
  .courses-item {
    &__main {
      padding-right: 0;
    }
    &__header {
      flex-wrap: wrap;
      max-width: 220px;
    }
    &__bot {
      flex-wrap: wrap;
    }
    &__bot-item {
      width: 50%;
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid #eaeaea;
      }
      &:nth-child(2) {
        border-right: none;
      }
    }
    &__profile,
    &__rate,
    &__review-block,
    &__level {
      margin: 10px 20px 10px 0;
    }
    &__profile {
      order: -3;
      //margin-right: 15px;
    }
    &__level {
      order: -2;
      //margin-left: 15px;
    }
    &__rate {
      //margin-right: 15px;
    }
  }
}
@media (max-width: 639px) {
  .courses {
    padding: 40px 0;
  }
  .courses-aside {
    &__title {
      font-size: 18px;
    }
  }
  .courses-item {
    &__title {
      font-size: 18px;
    }
    &__top {
      display: block;
    }
    &__header {
      max-width: none;
    }
    &__profile,
    &__level {
      //margin-bottom: 0;
    }
    &__level {
      //margin-right: 15px;
    }
    &__company-img {
      display: none;
    }
    &__main {
      margin-top: 25px;
    }
  }
  .courses-slider {
    margin: auto;
  }
}
@media (max-width: $mobile) {
  .courses-item {
    &__header {
      //justify-content: space-between;
      //max-width: 220px;
    }
    &__profile,
    &__level {
      //margin-bottom: 20px;
    }
    &__bot-item {
      width: 100%;
      border: none;
      border-bottom: 1px solid #eaeaea;
      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: 1px solid #eaeaea;
      }
      &:nth-child(2) {
        border-right: none;
      }
    }
  }
}
@media (max-width: $mobile-sm) {
  .courses {
    &__title {
      margin-right: 0;
      margin-bottom: 15px;
    }
    .sorting-block .sorting-select__list {
      left: 0;
      right: auto;
    }
  }
  .courses-main {
    &__header {
      display: block;
    }
  }
  .courses-item {
    &__header {
      //justify-content: space-between;
      //margin: auto;
    }
  }
}