.franchise {
  background-color: $gray-ec;
  padding: 80px 0 102px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
    overflow: hidden;
  }
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__main {
    //max-width: 1276px;
    //width: 100%;
    width: calc(100% - 263px);
    margin-right: 23px;
  }
  &__aside {
    flex-shrink: 0;
    margin-top: 65px;
    a {
      display: inline-block;
    }
    img {
      max-width: 240px;
    }
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //padding-right: 264px;
    margin-bottom: 30px;
  }
  &__upgrade {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 45px;
    margin-right: auto;
    span {
      width: 32px;
      height: 32px;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px;
      svg {
        fill: $blue-00;
      }
    }
  }
  &__all-link {
    margin-left: 67px;
  }
  &__footer-block {
    display: none;
  }
}
.franchise-slider-wrap {
  overflow: hidden;
}
.franchise-slider {
  //width: 100%;
  //.slick-slide {
  //  width: calc(50% - 28px);
  //}
  margin-left: -28px;
}
.franchise-item {
  //width: calc(50% - 28px);
  //margin-right: 28px;
  &__grid {
    background-color: #fff;
    display: flex;
    margin-left: 28px;
  }
  &__prev-block {

    width: 232px;
    flex-shrink: 0;
    position: relative;
  }
  &__prev-img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-size: cover;
    &.mob {
      display: none;
    }
  }
  &__obj-block {
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: $blue-00;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: absolute;
    left: 24px;
    top: 17px;
  }
  &__obj-num {
    font-family: $default-font-bold;
    font-size: 18px;
    line-height: 1;
    margin-bottom: 7px;
  }
  &__obj-text {
    font-family: $default-font;
    font-size: 12px;
    line-height: 1;
    color: rgba(255,255,255,.67);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      width: 100%;
    }
  }
  &__main {
    width: 100%;
  }
  &__text-block {
    display: flex;
    min-height: 205px;
    padding: 35px 50px 30px 40px;
  }
  &__text-img-wrap {
    margin-right: 30px;
    img {
      max-width: 77px;
      max-height: 79px;
    }
  }
  &__title {
    font-family: $default-font-semibold;
    font-size: 18px;
    line-height: 24px;
    display: inline-block;
    margin-bottom: 15px;
  }
  &__text {
    font-size: 14px;
    line-height: 18px;
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
  }
  &__footer-item {
    width: 50%;
    min-height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 25px 40px;
    border: 1px solid $gray-ec;
    border-left: none;
    border-bottom: none;
    &:nth-child(even) {
      border-right: none;
    }
  }
  &__footer-item-title {
    font-size: 12px;
    line-height: 18px;
    color: rgba(0,0,0,.67);
    margin-bottom: 7px;
  }
  &__footer-item-value {
    font-family: $default-font-bold;
    font-size: 18px;
    line-height: 1;
  }
}

@media (max-width: 1600px) {
  .franchise-item {
    &__prev-block {
      width: 200px;
    }
    &__text-block {
      padding: 25px 25px 25px 25px;
    }
    &__footer-item {
      padding: 25px 25px;
    }
  }
}
@media (max-width: 1400px) {
  .franchise-item {
    &__text-img-wrap {
      margin-right: 15px;
    }
    &__text-block {
      padding: 15px;
    }
    &__footer-item {
      padding: 15px;
    }
  }
}
@media (max-width: 1280px) {
  .franchise {
    &__aside {
      display: none;
    }
    &__main {
      width: 100%;
      margin-right: 0;
    }
  }
}
@media (max-width: $tablet) {
  .franchise {
    &__title {
      margin-right: auto;
    }
    &__upgrade {
      display: none;
    }
  }
  .franchise-item {
    &__prev-block {
      width: 230px;
    }
    &__text-img-wrap {
      margin-right: 30px;
    }
    &__text-block {
      padding: 30px;
    }
    &__footer-item {
      padding: 30px;
    }
  }
}
@media (max-width: $mobile-big) {
  .franchise {
    padding: 50px 0 47px;
    a {
      &.franchise {
        &__all-link {
          display: none;
        }
      }
    }
    &__all-link {
      margin: auto;
    }
    &__footer-block {
      display: block;
      text-align: center;
      margin-top: 38px;
      a {
        &.franchise {
          &__all-link {
            display: inline-block;
          }
        }
      }
    }
  }
}
@media (max-width: 639px) {
  .franchise-slider {
    margin-left: 0;
    .franchise-item {
      display: none;
      &:first-child {
        display: block;
      }
      &__text-img-wrap {
        margin-right: 17px;
      }
      &__text-block {
        padding: 25px;
      }
      &__footer-item {
        padding: 25px 17px 25px 25px;
      }
      &__obj-block {
        left: 28px;
        top: 28px;
      }
    }
  }
  .franchise-item {
    &__grid {
      display: block;
      margin-left: 0;
    }
    &__text-block {
      min-height: auto;
    }
    &__prev-block {
      height: 300px;
      width: 100%;
    }
    &__prev-img {
      display: none;
      &.mob {
        display: block;
      }
    }
  }
}
@media (max-width: $mobile-sm) {
  .franchise {
    .container {
      padding: 0;
    }
    &__title {
      padding: 0 27px;
    }
  }
  .franchise-item {
    &__prev-block {
      height: 240px;
    }
    &__text-block {
      align-items: center;
    }
    &__text {
      display: none;
    }
    &__title {
      margin-bottom: 0;
    }
  }
}