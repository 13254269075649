.hero {
	position: relative;
	color: #fff;
	background-color: #000;

	.container {
		position: relative;
		padding-top: 110px;
		padding-bottom: 40px;
		z-index: 1;
	}

	.default-arrow {
		svg {
			fill: #fff;
		}
	}
	.slick-dots {
		button {
			background-color: rgba($gray-68, 1);
		}
	}
}
.hero-bg-slider {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	//height: 100%;
	//z-index: -1;
	.hero-bg {
		width: 100%;
		height: 100%;
		span {
			display: block;
			width: 100%;
			height: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			z-index: -1;
			opacity: .75;
		}
	}
	&::after,
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, rgba(0,0,0,.1), rgba(0,0,0,1));
		z-index: 1;
	}
	&::before {
		z-index: -2;
		background-image: none;
		background-color: #000;
	}
}

.hero-top {
	display: flex;
	padding-bottom: 46px;
	margin-bottom: 40px;
	border-bottom: 1px solid rgba(#fff, .14);
}

.hero-articles-slider {
	width: 850px;
}

.article-item {
	&__tags {
		display: flex;
		align-items: center;
		margin-bottom: 35px;
		font-size: 12px;
		line-height: 1;
		text-transform: uppercase;
		span {
			color: #fff;
			background-color: $blue-00;
			padding: 9px 12px 7px;
			margin-right: 7px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	&__title {
		line-height: 1;
		.icon {
			display: none;
			@extend %blue-circle-arrow;
		}
	}
	&__footer {
		display: flex;
		align-items: center;
		margin-top: 22px;
		&>* {
			margin-right: 25px;
			&:last-child {
				margin-right: 0;
			}
		}
		.icon {
			margin-right: 14px;
			font-size: 0;
			line-height: 1;
			svg {
				fill: #fff;
			}
		}
	}
	&__more {
		display: flex;
		align-items: center;
		.icon {
			@extend %blue-circle-arrow;
		}
	}
	&__published {
		display: flex;
		align-items: center;
		.icon {

		}
	}
	&__comments {
		display: flex;
		align-items: center;
		.icon {

		}
	}
	&__rating {
		margin-left: auto;
	}
}

.hero-articles-rb {
	max-width: 460px;
	margin-left: auto;
}

.hero-articles-nav {
	display: flex;
	align-items: center;
	padding-left: 90px;
	margin-top: 40px;
}
.interview-nav-tools,
.hero-articles-nav-tools {
	margin-right: 45px;
}

.interview-nav-slider,
.hero-articles-nav-slider {
	max-width: 386px;

	.interview-sl-item,
	.hero-articles-item {
		position: relative;
		width: 122px;
		height: 70px;
		background-color: #000;
		margin-right: 10px;
		&.slick-active {
			.svg-timer {
				display: block;
			}
			.line-fill {
				animation-play-state: running;
			}
		}
		a {
			display: flex;
			width: 100%;
			height: 100%;
		}
		.bg {
			display: block;
			width: 100%;
			height: 100%;
			background: center/cover no-repeat;
			opacity: .54;
		}
		.svg-timer {
			display: none;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-90deg);
			z-index: 2;
			pointer-events: none;
		}
		.line-fill {
			animation: animateOffset 8s linear forwards;
			animation-play-state: paused;
		}
	}

}

@keyframes animateOffset {
	0% {
		stroke-dashoffset: 106;
	}
	100% {
		stroke-dashoffset: 0;
	}
}

.hero-article-sm {
	display: inline-block;
	&.inverted {
		.img {
			order: 1;
			margin-right: 0;
			margin-left: 50px;
		}
	}
	a {
		display: flex;
		align-items: center;
	}
	.img {
		position: relative;
		display: flex;
		align-items: flex-end;
		flex-shrink: 0;
		width: 170px;
		height: 190px;
		margin-right: 50px;
		border-bottom-left-radius: 85px;
		border-bottom-right-radius: 85px;
		overflow: hidden;
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			background-color: $blue-00;
			width: 170px;
			height: 170px;
			border-radius: 50%;
		}
		img {
			position: relative;
			max-width: 100%;
			max-height: 100%;
			margin: 0 auto;
		}
	}
	.text {
		font-family: $default-font-semibold;
		font-size: 22px;
		line-height: 28px;
		max-width: 210px;
	}
}


.last-news-header {
	display: flex;
	align-items: baseline;
	margin-bottom: 46px;
	h2 {
		font-family: $default-font-semibold;
		font-size: 24px;
		line-height: normal;
		margin-right: 32px;
	}
	.last-news-tags {
		font-family: $default-font-semibold;
		text-transform: uppercase;
		font-size: 12px;
		color: $gray-93;
		span {
			margin-right: 25px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
}
.last-news-slider {
	.last-news-item {
		display: flex;
	}
}
.last-news-item {
	width: 232px;
	height: 258px;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	margin-right: 32px;
	padding: 26px;
	&:hover {
		&::after {
			opacity: 1;
		}
	}
	&::after,
	&::before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, rgba(0,0,0,0) 45%, rgba(0,0,0,7));
		opacity: .65;
	}
	&::after {
		opacity: 0;
		background-image: linear-gradient(to bottom, rgba(0,0,0,.2), rgba(0,0,0,95));
		transition: opacity .25s linear;
	}
	&__title {
		position: relative;
		font-family: $default-font-semibold;
		font-size: 18px;
		line-height: 24px;
		z-index: 1;
	}
}

@media (max-width: 1365px) {
	.hero-articles-slider {
		width: 650px;
	}
	.article-item {
		&__title {
			font-size: 50px;
			br {
				display: none;
			}
		}
	}
}
@media (max-width: $desktop) {
	.hero-top {
		display: block;
	}
	.hero-article-sm {
		display: none;
	}
	.hero-articles-slider {
		width: 100%;
		margin-bottom: 50px;
	}
	.hero-articles-rb {
		margin-left: 0;
	}
	.hero-articles-nav-slider {
		display: none;
	}
	.hero-articles-nav {
		padding-left: 0;
		margin-top: 0;
	}

	.last-news-slider {
		margin-left: -20px;
		margin-right: -20px;
		.slick-list {
			padding: 0 20px;
		}
	}
}
@media (max-width: $tablet) {
	.hero {
		.container {
			padding-top: 150px;
			padding-bottom: 30px;
		}
	}
	.hero-bg-slider {
		//height: calc(100% - 360px);
	}
	.article-item {
		&__title {
			font-size: 42px;
			line-height: 1.2;
		}
	}
}

@media (max-width: $mobile-big) {
	.last-news-header {
		display: block;
		margin-bottom: 35px;
		h2 {
			font-size: 18px;
			margin-right: 0;
			margin-bottom: 12px;
		}
		.last-news-tags {
			//margin-left: -20px;
			//padding-left: 20px;
			margin-right: -20px;
			font-size: 11px;
			overflow: auto;
		}
	}
}
@media (max-width: 640px) {
	.hero-top {
		margin-bottom: 35px;
	}
	.article-item {
		font-size: 10px;
		&__title {
			font-size: 36px;
			.icon {
				display: inline-flex;
				vertical-align: middle;
				margin-left: 10px;
			}
		}
		&__tags {
			margin-bottom: 30px;
			span {
				padding: 6px 10px;
			}
		}
		&__more {
			display: none;
		}
	}
}
