.header {
	color: $black-3;
	background-color: #fff;
	&.scrolled {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;
	}
	.container {
		position: relative;
	}
	&__search {
		margin-left: 72px;
		margin-right: 40px;
		input {
			width: 170px;
		}
	}

	&__exchange-rates {
		display: flex;
		align-items: center;
		margin-left: auto;
		margin-right: 55px;
		.exchange-rate {
			display: flex;
			align-items: center;
			margin-right: 35px;
			&:last-child {
				margin-right: 0;
			}
			&__ic {
				@extend %gray-circle;
				margin-right: 12px;
			}
		}
	}

	&__city {
		margin-right: 80px;
	}


	&__tools-mob,
	&__tools {
		display: inline-flex;
		align-items: center;
		.link-pa {
			display: flex;
			align-items: center;
			color: $blue-00;
			margin-right: 24px;
			transition: color .2s linear;
			&:hover {
				color: $black-3;
			}
			.avatar {
				flex-shrink: 0;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				margin-right: 20px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
		.icon-link {
			margin-right: 7px;
			@extend %gray-circle-hover;
			&:last-child {
				margin-right: 0;
			}
			&:hover {
				.text {
					color: $blue-00;
				}
			}
			.icon {
				@extend %gray-circle;
			}
			.text {
				display: none;
				transition: color .2s linear;
			}
		}
	}
	&__tools-mob {
		display: none;
	}

	.lang-toggle {
		margin-left: auto;
	}

}
.header-top {
	display: flex;
	align-items: center;
	font-size: 13px;
	padding-top: 15px;
	padding-bottom: 16px;
	position: relative;
	//border-bottom: 1px solid $gray-ec;
	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: -50vw;
		width: 200vw;
		height: 1px;
		background-color: $gray-ec;
	}
}
.header-bot {
	position: relative;
	display: flex;
	align-items: center;
	padding: 22px 0;
	font-size: 13px;
	.hamburger {
		margin-right: 32px;
	}
}
.header-nav-drop {
	font-size: 13px;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	background: #fff;
	//padding: 20px 20px 20px 72px;
	padding: 0px 20px 15px 72px;
	opacity: 0;
	visibility: hidden;
	transition: .2s linear;
	transition-property: opacity, visibility;
	z-index: 2;
	&.is-active {
		visibility: visible;
		opacity: 1;
	}
}
.header-nav-mob,
.header-nav {
	font-family: $default-font-bold;
	text-transform: uppercase;
	a {
		margin-right: 40px;
		transition: .2s linear;
		transition-property: color;
		&:hover {
			color: $blue-00;
		}
		&:last-child {
			margin-right: 0;
		}
	}
}

.search-block {
	&__btn-toggle {
		display: none;
	}
	&__form {
		display: flex;
		align-items: center;
	}
	button {
		background: none;
		padding: 0;
		margin-right: 22px;
		cursor: pointer;
		@extend %gray-circle-hover;
		span {
			@extend %gray-circle;
		}
	}
	input {
		font-size: inherit;
		height: 30px;
		padding: 0;
		border-bottom: 1px solid rgba(0,0,0,0);
		&:focus {
			border-color: $gray-93;
		}
	}
}

.lang-toggle {
	position: relative;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	color: $text-color;
	font-family: $default-font-semibold;
	&:hover {
		.text {
			color: $blue-00;
		}
	}
	&.is-active {
		.arrow {
			transform: rotateX(180deg);
		}
		.lang-list {
			visibility: visible;
			a {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
	.text {
		text-transform: uppercase;
		transition: color .2s linear;
	}
	.arrow {
		fill: rgba(#000, .9);
		margin-left: 8px;
		transition: .2s linear;
		transition-property: transform;
		opacity: .36;
		pointer-events: none;
	}
}
.lang-list {
	display: block;
	width: 40px;
	max-height: 252px;
	position: absolute;
	top: 100%;
	left: 50%;
	transform: translateX(-50%);
	padding: 0;
	margin: 0;
	margin-top: 15px;
	list-style: none;
	box-shadow: 0 5px 20px 0 rgba(36, 47, 83, 0.1);
	visibility: hidden;
	transition: visibility .2s linear;
	overflow: auto;
	z-index: 6;
	scrollbar-color: $blue-00 $gray-ec;
	scrollbar-width: thin;
	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
		border-radius: 2px;
		background-color: $gray-ec;
	}
	&::-webkit-scrollbar-thumb:vertical {
		background-color: $blue-00;
		border-radius: 2px;
	}
	&::-webkit-scrollbar-thumb:horizontal {
		background-color: $blue-00;
		border-radius: 2px;
	}

	li {
		overflow: hidden;
	}
	a {
		position: relative;
		display: block;
		padding: 8px 10px;
		text-decoration: none;
		background-color: #fff;
		transform: translateY(-15px);
		opacity: 0;
		transition: .2s linear;
		transition-property: opacity, transform;
		&:hover {
			background-color: $gray-ec;
		}
	}
}

.city-box {
	position: relative;
	cursor: pointer;
	color: $text-color;
	&.is-active {
		.arrow {
			svg {
				transform: rotateX(180deg);
			}
		}
		.cities-list {
			visibility: visible;
			a {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
}
.city-toggle {
	display: inline-flex;
	align-items: center;
	@extend %gray-circle-hover;
	.icon {
		@extend %gray-circle;
		margin-right: 20px;
	}
	.text {
		color: $blue-00;
		transition: color .2s linear;
	}
	.arrow {
		padding-bottom: 2px;
		svg {
			fill: #000;
			margin-left: 8px;
			transition: .2s linear;
			transition-property: transform;
			opacity: .36;
		}
	}
}
.cities-list {
	@extend .lang-list;
	width: auto;
	//left: 0;
	left: auto;
	right: 0;
	transform: none;
}

.hamburger {
	position: relative;
	width: 20px;
	height: 20px;
	cursor: pointer;
	&:hover {
		span {
			background-color: $blue-01;
		}
	}
	&.is-active {
		span {
			width: calc(100% + 2px);
			left: 50%;
			&:nth-child(1) {
				top: 50%;
				transform: translate(-50%, -50%) rotate(45deg);
			}
			&:nth-child(2) {
				width: calc(100% + 2px);
				top: 50%;
				transform: translate(-50%, -50%) rotate(-45deg);
			}
		}
	}
	span {
		position: absolute;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 3px;
		background-color: $black-01;
		transition: .2s linear;
		transition-property: background-color;
		&:nth-child(1) {
			top: calc(50% - 4px);
		}
		&:nth-child(2) {
			width: 55%;
			top: calc(50% + 1px);
		}
	}
}
.hamburger-mob {
	@extend %gray-circle;
	width: 32px;
	height: 32px;
	transition: .2s linear;
	transition-property: background-color;
	&:hover {
		background-color: lighten($blue-00, 20);
		span {
			background-color: $black-01;
		}
	}
	&.is-active {
		span {
			width: calc(56.25% + 2px);
			&:nth-child(2) {
				width: calc(56.25% + 2px);
			}
		}
	}
	span {
		width: 56.25%;
		left: 7px;
		&:nth-child(2) {
			width: 31.25%;
		}
	}
}

@media (max-width: 1440px) {

	.search-block {
		&__btn-toggle {
			display: block;
		}
		&__form {
			position: fixed;
			top: 200px;
			left: 50vw;
			z-index: 100;
			transform: translate(-50%, - 50px);
			visibility: hidden;
			opacity: 0;
			transition: .25s linear;
			transition-property: opacity, visibility, transform;
			background: #fff;
			padding: 10px 20px;
			border: 2px solid $blue-00;
			&.is-active {
				transform: translate(-50%, 0);
				opacity: 1;
				visibility: visible;
			}
			button {
				margin-left: 20px;
				order: 1;
			}
		}
		button {
			margin-right: 0;
		}
		input {
			width: 240px;
		}
	}

}

@media (max-width: 1279px) {
	.header__search {
		margin-left: 60px;
	}
	.header__city {
		margin-right: 60px;
	}
	.header-nav a {
		margin-right: 20px;
	}
}

@media (max-width: $desktop) {
	.header {
		.container {
			padding: 0;
		}
		&__exchange-rates {
			margin-right: auto;
		}
		&__city {
			margin-right: 0;
			margin-left: 30px;
		}
		.lang-toggle {
			margin: 0 30px;
		}
		&__tools {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			position: absolute;
			top: 100%;
			right: 0;
			padding: 30px 20px;
			background-color: #fff;
			z-index: 100;
			opacity: 0;
			visibility: hidden;
			transition: .25s linear;
			transition-property: opacity, visibility;
			&.is-active {
				opacity: 1;
				visibility: visible;
			}
			.icon-link {
				display: inline-flex;
				align-items: center;
				margin-top: 15px;
				.icon {
					margin-left: 2px;
					margin-right: 22px;
				}
				.text {
					display: inline-block;
				}
			}
		}
		&__tools-mob {
			display: inline-flex;
			&>* {
				margin-right: 6px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
		&__search {
			margin-left: 0;
		}
	}
	.header-bot {
		display: none;
	}
	.header-top {
		padding: 15px 20px;
	}
	.hamburger-mob {
		margin: 0;
	}
	.hamburger-mob-box {
		position: absolute;
		top: 100%;
		right: 0;
		//width: 50vw;
		width: 500px;
		padding: 30px 20px;
		background-color: #fff;
		z-index: 100;
		opacity: 0;
		visibility: hidden;
		transition: .25s linear;
		transition-property: opacity, visibility;
		overflow: auto;
		&.is-active {
			opacity: 1;
			visibility: visible;
		}
	}
	.header-nav-mob {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		a {
			width: calc(50% - 20px);
			margin-right:0;
		}
	}
	.search-block__form {
		top: 110px;
	}
}

@media (max-width: $tablet) {
	.header {
		.logo {
			margin-right: auto;
		}
		&__exchange-rates {
			margin: 0;
			margin-bottom: 20px;
		}
	}
	//.hamburger-mob-box {
		//width: 65vw;
	//}
}
@media (max-width: $mobile-big) {
	.header-top {
		padding-left: 27px;
		padding-right: 27px;
	}
	.header {
		.lang-toggle {
			margin: 0;
			margin-right: 40px;
			margin-bottom: 20px;
			vertical-align: middle;
		}
		&__city {
			display: inline-block;
			margin: 0;
			margin-bottom: 20px;
			vertical-align: middle;
		}
	}
	.hamburger-mob-box {
		padding: 30px 27px;
	}
	.header-nav-mob {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		a {
			width: auto;
		}
	}
}
@media (max-width: $mobile) {
	.header {
		&__exchange-rates {
			display: block;
			.exchange-rate {
				margin-bottom: 6px;
				margin-right: 0;
				&:last-child {
					margin: 0;
				}
			}
		}
	}
	.hamburger-mob-box {
		width: 280px;
	}
}
@media (max-width: 400px) {
	.search-block input {
		width: 200px;
	}
}
