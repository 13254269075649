input, textarea {
  font-family: inherit;
  color: $black-3;
  background-color: #fff;
  width: 100%;
  transition: border-color .2s linear;
}
//label.error {
//	display: none !important;
//}
input[type=submit] {
  width: auto;
}
input {
  height: 28px;
}
textarea {
  width: 100%;
  height: 112px;
  resize: none;
  border-radius: 0;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    padding: 10px;
  }
}
input,
textarea {
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  border-radius: 0;
}

::-webkit-input-placeholder {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    color: rgba(#404040, .5);
  }
  //color: $color-placeholder;
}

::-moz-placeholder {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    color: rgba(#404040, .5);
  }
  //color: $color-placeholder;
}

:-moz-placeholder {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    color: rgba(#404040, .5);
  }
  //color: $color-placeholder;
}

:-ms-input-placeholder {
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    color: rgba(#404040, .5);
  }
  //color: $color-placeholder;
}

.input-group {
  //font-family: $default-font;
  position: relative;
  margin-bottom: 20px;
  z-index: 1;
  &::before{
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: .25s linear;
    transition-property: opacity;
    z-index: -1;
  }
  &--notempty, &--focus {
    .input-group__label {
      top: 8px;
      //font-family: $default-font-medium;
      font-size: 12px;
      //color: $turquoise-blue;
    }
  }
  &--focus {
    &::before {
      opacity: 1;
    }
  }
  &__label {
    //font-family: $default-font;
    position: absolute;
    top: 19px;
    left: 20px;
    font-size: 18px;
    line-height: 1;
    pointer-events: none;
    transition: all .25s linear;
    color: rgba(#404040, .5);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: none;
    }
  }
  input,
  textarea {
    background-color: #fff;
    border: 1px solid #fff;
    padding: 18px 20px 6px;
    min-height: 56px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding: 5px 20px;
    }
    &:hover {

    }
    &:focus, &:active {
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
        & ~ .input-group__label {
          display: none;
        }
      }
    }
    &.error {
      border: 1px solid #FF0000;
      & ~ .input-group__label {
        color: #ff0000;
      }
    }
  }
  //error
  label.error {
    display: none !important;
  //  font-size: 12px;
  //  line-height: 1.4;
  //  position: absolute;
  //  top: 100%;
  //  left: 0;
  //  color: #af1c1c;
  }
  &.input-group-textarea {
    min-height: 56px;
    height: 112px;
    textarea {
      height: 100%;
      padding: 22px 20px 6px;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        padding: 12px 20px 6px;
      }
    }
  }
}

@media (max-width: $tablet) {
  .input-group {
    margin-bottom: 15px;
    &__label {
      top: 16px;
      left: 15px;
      font-size: 12px;
    }
    &--focus,
    &--notempty {
      .input-group {
        &__label {
          font-size: 10px;
          top: 5px;
        }
      }
    }
    input,
    textarea {
      //font-size: 12px;
      //line-height: 16px;
      line-height: 18px;
      min-height: 42px;
      padding: 14px 15px 4px;
    }
    input {
      height: 20px;
    }
  }
  .input-group.input-group-textarea textarea {
    padding: 16px 15px 4px;
  }
}
@media (max-width: $mobile-sm) {
  .input-group {
    input,
    textarea {
      font-size: 12px;
      line-height: 15px;
    }
    label.error {
      font-size: 10px;
    }
  }
}