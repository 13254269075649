.business {
	padding-top: 80px;
	padding-bottom: 64px;
	background-color: $gray-ec;

	.link-more {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: $blue-00;
		margin-top: auto;
		&:hover {
			.icon {
				background-color: #fff;
				svg {
					fill: $blue-00;
				}
			}
		}
		.icon {
			@extend %blue-circle-arrow;
			margin-right: 18px;
		}
	}
	&__footer {
		display: none;
		text-align: center;
	}
	&__mob-title {
		display: none;
		font-size: 24px;
		font-family: $default-font-semibold;
		line-height: normal;
	}
}

.business-grid,
.content {
	display: flex;
	justify-content: space-between;
}
.business-grid-col,
.business-col {
	//display: flex;
	//flex-direction: column;
	width: calc(50% - 16px);
}

.business-col-header {
	display: flex;
	align-items: center;
	margin-bottom: 44px;
	.business-col-title {
		font-family: $default-font-semibold;
		font-size: 24px;
		line-height: normal;
		margin-right: 30px;
	}
}

.business-item {
	margin-bottom: 32px;
}

.card-article__comments,
.business-item-sm__comments {
	display: flex;
	align-items: center;
	.icon {
		line-height: 1;
		margin-right: 12px;
	}
	svg {
		@extend %comment2;
		stroke: $gray-93;
		width: 14px;
		height: 13px;
	}
}

.business-wrapper-items {
	background-color: #fff;
	//align-self: stretch;
	height: 100%;
	padding: 35px 40px 30px;
}

.business-item-sm {
	padding-top: 20px;
	margin-bottom: 20px;
	border-top: 1px solid #dcdcdc;
	&:first-child {
		padding-top: 0;
		border: none;
	}
	&__title {
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 15px;
		transition: color .2s linear;
		&:hover {
			color: $blue-00;
		}
	}
	&__comments {
		font-size: 12px;
		line-height: normal;
		color: rgba(#000, .5);
	}
}

@media (max-width: 1400px) {
	.business-grid-col,
	.business-col {
		width: calc(50% - 10px);
	}
	.business-item {
		margin-bottom: 20px;
	}
}
@media (max-width: $desktop) {
	.business-grid {
		display: block;
	}
	.business-grid-col {
		width: 100%;
	}
	.business-col {
		width: calc(50% - 20px);
		.card-article {
			&:last-child {
				margin-bottom: 20px;
			}
		}
	}
}
@media (max-width: $tablet) {
	.business {
		padding: 40px 0 30px;
		&__footer {
			display: block;
			padding-top: 10px;
		}
		&__mob-title {
			display: block;
			margin-bottom: 30px;
		}
	}
	.business-com {
		display: none;
	}
	.business-col {
		width: calc(50% - 10px);
	}
	.business-col-header {
		display: none;
	}
}
@media (max-width: 639px) {
	.business {
		&__mob-title {
			font-size: 18px;
		}
	}
}
@media (max-width: $mobile) {
	.business-col {
		width: 100%;
	}
	.business {
		.content {
			display: block;
		}
	}
}
@media (max-width: $mobile-sm) {
	.business {
		padding-top: 30px;
		.container {
			padding: 0;
		}
		&__mob-title {
			text-align: center;
			padding: 0 27px;
		}
		&__footer {
			padding-top: 30px;
		}
	}
	.business-item {
		margin-bottom: 0;
	}
	.business-col .card-article:last-child {
		margin-bottom: 0;
	}
}