.catalog-comp {
  overflow: hidden;
  padding: 65px 0;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px;
  }
  &__grid {
    display: flex;
  }
  &__title {
    margin-right: auto;
  }
  &__all-link {
    margin-left: 67px;
  }
  &__footer {
    display: none;
    text-align: center;
  }
}
.catalog-comp-item {
  background-color: #f2f2f2;
  width: calc((100% - 90px)/7);
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
  &__inner {
    position: relative;
    padding: 7px 29px 35px;
  }
  &__title-block {
    min-height: 66px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(#c7c7c7,.59);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding-top: 20px;
    }
    a {
      transition: color .2s linear;
      &:hover {
        color: $blue-01;
      }
    }
  }
  &__title {
    font-family: $default-font-semibold;
    font-size: 14px;
    line-height: normal;
  }
  &__num {
    font-family: $default-font-semibold;
    font-size: 10px;
    line-height: normal;
    color: #fff;
    background-color: $blue-00;
    position: absolute;
    right: 9px;
    top: 7px;
    border-radius: 17px;
    padding: 3px 6px;
    min-height: 20px;
    min-width: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 25px;
  }
  &__list-item {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 13px;
    a {
      color: $blue-01;
      transition: color .2s linear;
      &:hover {
        color: #000;
      }
    }
  }
}

@media (max-width: 1600px) {
  .catalog-comp-item {
    &__inner {
      padding: 7px 20px 30px;
    }
  }
}
@media (max-width: 1500px) {
  .catalog-comp-item {
    width: 220px;
  }
}
@media (max-width: $mobile-big) {
  .catalog-comp {
    &__grid {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    a.catalog-comp__all-link {
      display: none;
    }
    &__footer {
      display: block;
      a.catalog-comp__all-link {
        display: inline-block;
        margin-top: 18px;
        margin-left: 0;
      }
    }
  }
  .catalog-comp-item {
    width: calc(50% - 10px);
    margin: 0;
    margin-bottom: 20px;
    display: none;
    &:first-child,
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }
}
@media (max-width: $mobile) {
  .catalog-comp {
    padding: 45px 0 48px;
    &__header {
      margin-bottom: 35px;
    }
  }
}
@media (max-width: $mobile-sm) {
  .catalog-comp {
    &__header {
      display: block;
      text-align: center;
    }
  }
  .catalog-comp-item {
    width: 100%;
    margin-bottom: 5px;
    &__inner {
      padding: 0;
    }
    &__title-block {
      border: none;
      min-height: 78px;
      padding: 15px 30px;
    }
    &__list {
      display: none;
    }
  }
}