.sale-contracts {
  background-color: $gray-ec;
  padding: 22px 0 25px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 41px;
  }
  &__title {
    margin-right: 20px;
  }
  &__grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }
  &__aside-link {
    display: inline-block;
    flex-shrink: 0;
    margin-left: 120px;
    img {
      max-width: 334px;
    }
  }
  &__footer {
    display: none;
  }
}
.sale-contract {
  max-width: 283px;
  width: 100%;
  margin-bottom: 45px;
  margin-right: 20px;
  &__grid {
    display: flex;
  }
  .img-wrapper {
    max-width: 102px;
    max-height: 150px;
    flex-shrink: 0;
    margin-right: 28px;
  }
  &__title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  &__price {
    font-family: $default-font-bold;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 15px;
  }
  &__link {
    display: inline-block;
    .icon {
      @extend %white-circle-arrow;
      background-color: #fff;
      svg {
        fill: $blue-00;
      }
    }
  }
}

@media (max-width: 1600px) {
  .sale-contracts {
    &__aside-link {
      margin-left: 40px;
    }
  }
}
@media (max-width: 1400px) {
  .sale-contracts {
    &__aside-link {
      margin-left: 20px;
    }
  }
  .sale-contract {
    max-width: 278px;
    margin-right: 15px;
    .img-wrapper {
      margin-right: 15px;
    }
  }
}
@media (max-width: 1280px) {
  .sale-contracts {
    padding-bottom: 40px;
    &__aside-link {
      margin-left: 30px;
    }
    &__grid {
      align-items: center;
      position: relative;
    }
    &__list {
      width: calc(100% - 364px);
    }
    .default-arrow {
      width: 27px;
      height: 27px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1;
      &--prev {
        left: 0;
      }
      &--next {
        right: 0;
      }
      svg {
        fill: $blue-00;
      }
    }
  }
  .sale-contract {
    margin-right: 0;
    max-width: none;
    margin-bottom: 0;
    &__grid {
      align-items: center;
      max-width: 280px;
      margin: auto;
    }
    &__link {
      display: none;
    }
    &__price {
      margin-bottom: 0;
    }
  }
}
@media (max-width: $tablet) {
  .sale-contracts {
    &__list {
      width: 100%;
      max-width: 320px;
      margin: auto;
    }
  }
  .sale-contract {
    &__grid {
      display: block;
      text-align: center;
    }
    .img-wrapper {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: $mobile-big) {
  .sale-contracts {
    text-align: center;
    &__header {
      display: block;
    }
    &__title {
      margin-right: 0;
    }
    &__grid {
      display: block;
    }
    a.sale-contracts__all-link {
      display: none;
    }
    &__footer {
      display: block;
      a.sale-contracts__all-link {
        display: inline-block;
        margin-top: 22px;
        margin-left: 0;
      }
    }
    &__aside-link {
      margin: 47px auto 0;
    }
  }
}
@media (max-width: $mobile-sm) {
  .sale-contracts {
    .default-arrow {
      transform: none;
      top: 30px;
      &--prev {
        left: 20px;
      }
      &--next {
        right: 20px;
      }
    }
  }
  .sale-contract {
    .img-wrapper {
      max-width: 62px;
      max-height: 88px;
    }
    &__grid {
      max-width: 200px;
    }
  }
}
@media (max-width: 400px) {
  .sale-contracts {
    padding-bottom: 0;
    .container {
      padding: 0;
    }
    &__aside-link {
      width: 100%;
      background-color: #fff;
      img {
        max-width: none;
        width: 100%;
      }
    }
  }
}