@charset "UTF-8";
/* open-sans-300 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-regular - cyrillic_latin 400*/
@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-800 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* play-regular - cyrillic_latin 400*/
@font-face {
  font-family: 'Play-Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Play Regular"), local("Play-Regular"), url("../fonts/play/play-v10-cyrillic_latin-regular.woff2") format("woff2"), url("../fonts/play/play-v10-cyrillic_latin-regular.woff") format("woff"), url("../fonts/play/play-v10-cyrillic_latin-regular.ttf") format("truetype"), url("../fonts/play/play-v10-cyrillic_latin-regular.eot"), url("../fonts/play/play-v10-cyrillic_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/play/play-v10-cyrillic_latin-regular.svg#Play") format("svg");
  /* Legacy iOS */ }

/* play-700 - cyrillic_latin */
@font-face {
  font-family: 'Play-Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Play Bold"), local("Play-Bold"), url("../fonts/play/play-v10-cyrillic_latin-700.woff2") format("woff2"), url("../fonts/play/play-v10-cyrillic_latin-700.woff") format("woff"), url("../fonts/play/play-v10-cyrillic_latin-700.ttf") format("truetype"), url("../fonts/play/play-v10-cyrillic_latin-700.eot"), url("../fonts/play/play-v10-cyrillic_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/play/play-v10-cyrillic_latin-700.svg#Play") format("svg");
  /* Legacy iOS */ }

/* roboto-900 - cyrillic_latin */
@font-face {
  font-family: 'Roboto-Black';
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Black"), local("Roboto-Black"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.woff2") format("woff2"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.woff") format("woff"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.ttf") format("truetype"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.eot"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* Bebas Neue Cyrillic */
@font-face {
  font-family: 'BebasNeueCyrillic';
  font-style: normal;
  font-weight: normal;
  src: local("BebasNeueCyrillic"), url("../fonts/bebas/BebasNeueCyrillic.woff2") format("woff2"), url("../fonts/bebas/BebasNeueCyrillic.woff") format("woff"), url("../fonts/bebas/BebasNeueCyrillic.ttf") format("truetype"), url("../fonts/bebas/BebasNeueCyrillic.eot"), url("../fonts/bebas/BebasNeueCyrillic.eot?#iefix") format("embedded-opentype"), url("../fonts/bebas/BebasNeueCyrillic.svg#BebasNeueCyrillic") format("svg");
  /* Legacy iOS */ }

/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
* {
  box-sizing: border-box;
  outline: none; }

*::before, *::after {
  box-sizing: inherit; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

ul, ol {
  margin: 0; }
  ul.no-style, ol.no-style {
    padding: 0;
    list-style: none; }

button {
  outline: none;
  border: none; }

img {
  border: none; }

a {
  text-decoration: none;
  color: inherit; }

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block;
  /* For Firefox */ }

select {
  appearance: none; }
  select::-ms-expand {
    display: none; }

button {
  cursor: pointer; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-text-fill-color: #404040 !important; }

blockquote {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: "OpenSans-SemiBold", sans-serif; }

h1 {
  font-size: 60px;
  line-height: normal; }

h2 {
  font-size: 24px;
  line-height: 1.2; }
  @media (max-width: 639px) {
    h2 {
      font-size: 18px; } }

h3 {
  font-size: 18px;
  line-height: 24px; }

html {
  touch-action: manipulation; }

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "OpenSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden; }
  @media screen and (max-width: 991px) {
    body {
      font-size: 14px;
      line-height: 24px; } }
  @media screen and (max-width: 480px) {
    body {
      font-size: 12px;
      line-height: 20px; } }

.container {
  max-width: 1580px;
  width: 100%;
  margin: auto;
  padding: 0 20px; }
  @media screen and (max-width: 767px) {
    .container {
      padding: 0 27px; } }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.apple-fix {
  width: 100%;
  position: fixed; }

.no-scroll {
  overflow: hidden; }

.img-wrapper {
  display: inline-block; }
  .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative; }
  .wrapper .header {
    flex: 0 0 auto; }
  .wrapper main {
    position: relative;
    flex: 1 0 auto; }
  .wrapper .footer {
    flex: 0 0 auto; }

.logo {
  display: inline-block;
  font-family: "Play-Regular", sans-serif;
  position: relative;
  flex-shrink: 0;
  padding-right: 24px; }
  .logo__main {
    font-family: "Play-Bold", sans-serif;
    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
    .logo__main span {
      width: 27px;
      height: 27px;
      background-color: #0099ff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 6px; }
      .logo__main span svg {
        fill: #fff; }
  .logo__bot {
    font-size: 11px;
    line-height: 1;
    letter-spacing: 0.39em; }
  .logo__abr {
    font-size: 12px;
    letter-spacing: 0.2em;
    position: absolute;
    right: 0;
    top: -4px; }

.header__exchange-rates .exchange-rate__ic, .header__tools-mob .icon-link .icon, .header__tools .icon-link .icon, .search-block button span, .city-toggle .icon, .hamburger-mob {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ececec; }

.header__tools-mob .icon-link .icon, .header__tools .icon-link .icon, .search-block button .icon, .city-toggle .icon {
  transition: .2s linear;
  transition-property: background-color; }

.header__tools-mob .icon-link:hover .icon, .header__tools .icon-link:hover .icon, .search-block button:hover .icon, .city-toggle:hover .icon {
  background-color: #66c2ff; }

.card-article.inverted .card-article__link, .sale-contract__link .icon, .link-back .icon, .card-article__link, .articles__more-link, .business .link-more .icon, .courses-slider .default-arrow, .article-item__title .icon, .article-item__more .icon, .training-item__link .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #0099ff;
  border: 1px solid #0099ff;
  transition: .2s linear;
  transition-property: background-color, border-color; }
  .card-article.inverted .card-article__link:hover, .sale-contract__link .icon:hover, .link-back .icon:hover, .card-article__link:hover, .articles__more-link:hover, .business .link-more .icon:hover, .courses-slider .default-arrow:hover, .article-item__title .icon:hover, .article-item__more .icon:hover, .training-item__link .icon:hover {
    background-color: #fff; }
    .card-article.inverted .card-article__link:hover svg, .sale-contract__link .icon:hover svg, .link-back .icon:hover svg, .card-article__link:hover svg, .articles__more-link:hover svg, .business .link-more .icon:hover svg, .courses-slider .default-arrow:hover svg, .article-item__title .icon:hover svg, .article-item__more .icon:hover svg, .training-item__link .icon:hover svg {
      fill: #0099ff; }
  .card-article.inverted .card-article__link svg, .sale-contract__link .icon svg, .link-back .icon svg, .card-article__link svg, .articles__more-link svg, .business .link-more .icon svg, .courses-slider .default-arrow svg, .article-item__title .icon svg, .article-item__more .icon svg, .training-item__link .icon svg {
    width: 8px;
    height: 7px;
    fill: #fff;
    transition: fill .2s linear; }

.card-article.inverted .card-article__link, .sale-contract__link .icon {
  background-color: #fff;
  border-color: #fff; }
  .card-article.inverted .card-article__link:hover, .sale-contract__link .icon:hover {
    background: #0099ff; }
    .card-article.inverted .card-article__link:hover svg, .sale-contract__link .icon:hover svg {
      fill: #fff; }
  .card-article.inverted .card-article__link svg, .sale-contract__link .icon svg {
    fill: #0099ff; }

.card-article__comments svg,
.business-item-sm__comments svg {
  fill: none;
  stroke-width: 1px;
  stroke: #000; }

.link-back {
  display: inline-flex;
  align-items: center; }
  .link-back:hover .icon {
    background-color: #fff; }
    .link-back:hover .icon svg {
      fill: #0099ff; }
  .link-back .icon {
    margin-right: 20px; }
    .link-back .icon svg {
      transform: rotate(180deg); }

.select {
  position: relative;
  z-index: 1; }
  .select.is-active .select-list {
    visibility: visible; }
    .select.is-active .select-list a {
      opacity: 1;
      transform: translateY(0); }
  .select .select-toggle {
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 184px;
    height: 35px;
    background-color: #0099ff;
    padding: 0 28px;
    border-radius: 70px;
    cursor: pointer;
    position: relative;
    z-index: 1; }
  .select .select-val {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff; }
    .select .select-val:empty:after {
      content: attr(data-placeholder); }
  .select .select-list {
    font-size: 14px;
    display: block;
    width: 100%;
    max-height: 256px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0 5px 20px 0 rgba(36, 47, 83, 0.1);
    visibility: hidden;
    transition: visibility .2s linear;
    overflow: auto;
    scrollbar-color: #0099ff #ececec;
    scrollbar-width: thin; }
    .select .select-list::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #ececec; }
    .select .select-list::-webkit-scrollbar-thumb:vertical {
      background-color: #0099ff;
      border-radius: 2px; }
    .select .select-list::-webkit-scrollbar-thumb:horizontal {
      background-color: #0099ff;
      border-radius: 2px; }
    .select .select-list li {
      overflow: hidden; }
      .select .select-list li:first-child a {
        padding-top: calc(18px + 5px); }
    .select .select-list a {
      position: relative;
      display: block;
      padding: 5px 10px;
      text-decoration: none;
      background-color: #fff;
      transform: translateY(-15px);
      opacity: 0;
      transition: .2s linear;
      transition-property: opacity, transform; }
      .select .select-list a:hover {
        background-color: #ececec; }

.card-article {
  position: relative;
  background-color: #fff; }
  .card-article.inverted {
    background-color: #000;
    color: #fff; }
    .card-article.inverted .card-article__tag {
      color: #fff;
      background-color: #0099ff; }
  .card-article.img {
    height: 460px; }
    .card-article.img .card-article__inner {
      height: calc(100% - 220px);
      padding-top: 25px;
      padding-bottom: 25px; }
    .card-article.img .card-article__bg {
      position: relative;
      width: 100%;
      height: 220px;
      opacity: 1; }
    .card-article.img .card-article__link {
      margin-top: auto; }
  .card-article.icon {
    padding-top: 45px; }
    .card-article.icon .card-article__bg {
      width: 168px;
      height: 168px;
      border-radius: 50%;
      margin: 0 auto 30px; }
    .card-article.icon .card-article__inner {
      height: calc(100% - 198px);
      padding-top: 0; }
  .card-article.big .card-article__inner {
    height: 100%;
    justify-content: center;
    align-items: center; }
  .card-article.big .card-article__title {
    font-family: "OpenSans-Light", sans-serif;
    font-size: 44px;
    line-height: 57px;
    margin-bottom: 30px; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .card-article.big .card-article__title {
        width: 100%; } }
  .card-article.big .card-article__wrapper {
    text-align: center;
    display: inline-block; }
    .card-article.big .card-article__wrapper:hover .card-article__link {
      background: #0099ff;
      border-color: #fff; }
      .card-article.big .card-article__wrapper:hover .card-article__link svg {
        fill: #fff; }
    .card-article.big .card-article__wrapper:hover .text {
      color: #0099ff; }
    .card-article.big .card-article__wrapper .text {
      font-family: "OpenSans-Light", sans-serif;
      font-size: 16px;
      line-height: normal;
      display: inline-block;
      transition: all .2s linear;
      margin-top: 22px; }
  .card-article:last-child {
    margin-bottom: 0; }
  .card-article__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: center/cover no-repeat; }
  .card-article__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 34px 50px 32px; }
  .card-article__tag {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    color: #0099ff;
    padding: 7px 12px;
    border: 1px solid #0099ff;
    text-align: center;
    margin: 0 auto 25px; }
  .card-article__title {
    font-family: "OpenSans-Regular", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
    transition: color .2s linear; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .card-article__title {
        width: 100%; } }
    .card-article__title:hover {
      color: #0099ff; }
  .card-article__link {
    margin: 0 auto; }

.card-article__info {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.card-article__info, .card-article__like-block {
  display: none; }

.card-article__view svg {
  fill: #a6a6a6; }

.card-article__review svg {
  fill: #fff;
  stroke: #a6a6a6; }

.card-article .articles-item-link {
  text-align: center;
  display: inline-block; }
  .card-article .articles-item-link:hover .card-article__link {
    background: #0099ff;
    border-color: #fff; }
    .card-article .articles-item-link:hover .card-article__link svg {
      fill: #fff; }
  .card-article .articles-item-link:hover .text {
    color: #0099ff; }
  .card-article .articles-item-link .text {
    font-family: "OpenSans-Light", sans-serif;
    font-size: 16px;
    line-height: normal;
    display: inline-block;
    transition: all .2s linear;
    margin-top: 22px; }

.like-box {
  display: flex;
  align-items: center; }
  .like-box .like-btn {
    background: none;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 0; }
    .like-box .like-btn.is-active {
      pointer-events: none; }
    .like-box .like-btn.is-active svg, .like-box .like-btn:hover svg {
      fill: #0099ff; }
    .like-box .like-btn svg {
      fill: #dcdcdc; }
  .like-box .like-bar-box {
    width: 260px;
    margin: 0 25px; }
  .like-box .like-value {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 8px; }
    .like-box .like-value span:before {
      content: attr(data-before);
      margin-right: 4px; }
    .like-box .like-value__like {
      color: #0099ff; }
  .like-box .like-bar-wrap {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #dcdcdc; }
  .like-box .like-bar {
    position: absolute;
    left: 0;
    height: 100%;
    background-color: #0099ff; }

@media (max-width: 1400px) {
  .card-article__inner {
    padding: 34px 30px 32px; } }

@media (max-width: 1279px) {
  .card-article__title {
    font-size: 16px;
    line-height: 22px; } }

@media (max-width: 1199px) {
  .card-article__inner {
    padding: 34px 15px 32px; } }

@media (max-width: 991px) {
  .card-article.icon .card-article__bg {
    width: 120px;
    height: 120px; }
  .card-article.icon .card-article__inner {
    height: calc(100% - 165px); } }

@media (max-width: 575px) {
  .logo {
    padding-right: 14px; }
    .logo__main {
      font-size: 25px;
      line-height: 30px; }
      .logo__main span {
        width: 23px;
        height: 23px; }
    .logo__bot {
      font-size: 10px; }
    .logo__abr {
      font-size: 10px; }
  .card-article.img .card-article__inner {
    height: auto; }
  .card-article__info {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.52);
    margin-bottom: 15px; }
    .card-article__info svg {
      margin-right: 10px; }
  .card-article__like-block {
    display: block;
    width: 100%; }
  .card-article__view, .card-article__review {
    display: flex;
    align-items: center; }
  .card-article__view svg {
    width: 23px;
    height: 17px; }
  .card-article.inverted .card-article__info {
    color: #fff; }
  .card-article.inverted .card-article svg {
    fill: #fff; }
  .card-article.inverted .card-article__view svg {
    fill: #fff; }
  .card-article.inverted .card-article__link {
    display: none; }
  .card-article.inverted .card-article .articles-item-link {
    display: none; }
  .card-article .like-box .like-bar-box {
    width: 100%;
    margin: 0; }
  .card-article__link {
    display: none; }
  .card-article .articles-item-link {
    display: none; } }

/* open-sans-300 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-Light';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-300.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-regular - cyrillic_latin 400*/
@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-600 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-600.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-700 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-700.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* open-sans-800 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.woff2") format("woff2"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.woff") format("woff"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.ttf") format("truetype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.eot"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.eot?#iefix") format("embedded-opentype"), url("../fonts/open-sans/open-sans-v16-cyrillic_latin-800.svg#OpenSans") format("svg");
  /* Legacy iOS */ }

/* play-regular - cyrillic_latin 400*/
@font-face {
  font-family: 'Play-Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Play Regular"), local("Play-Regular"), url("../fonts/play/play-v10-cyrillic_latin-regular.woff2") format("woff2"), url("../fonts/play/play-v10-cyrillic_latin-regular.woff") format("woff"), url("../fonts/play/play-v10-cyrillic_latin-regular.ttf") format("truetype"), url("../fonts/play/play-v10-cyrillic_latin-regular.eot"), url("../fonts/play/play-v10-cyrillic_latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/play/play-v10-cyrillic_latin-regular.svg#Play") format("svg");
  /* Legacy iOS */ }

/* play-700 - cyrillic_latin */
@font-face {
  font-family: 'Play-Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Play Bold"), local("Play-Bold"), url("../fonts/play/play-v10-cyrillic_latin-700.woff2") format("woff2"), url("../fonts/play/play-v10-cyrillic_latin-700.woff") format("woff"), url("../fonts/play/play-v10-cyrillic_latin-700.ttf") format("truetype"), url("../fonts/play/play-v10-cyrillic_latin-700.eot"), url("../fonts/play/play-v10-cyrillic_latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/play/play-v10-cyrillic_latin-700.svg#Play") format("svg");
  /* Legacy iOS */ }

/* roboto-900 - cyrillic_latin */
@font-face {
  font-family: 'Roboto-Black';
  font-style: normal;
  font-weight: normal;
  src: local("Roboto Black"), local("Roboto-Black"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.woff2") format("woff2"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.woff") format("woff"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.ttf") format("truetype"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.eot"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto/roboto-v19-cyrillic_latin-900.svg#Roboto") format("svg");
  /* Legacy iOS */ }

/* Bebas Neue Cyrillic */
@font-face {
  font-family: 'BebasNeueCyrillic';
  font-style: normal;
  font-weight: normal;
  src: local("BebasNeueCyrillic"), url("../fonts/bebas/BebasNeueCyrillic.woff2") format("woff2"), url("../fonts/bebas/BebasNeueCyrillic.woff") format("woff"), url("../fonts/bebas/BebasNeueCyrillic.ttf") format("truetype"), url("../fonts/bebas/BebasNeueCyrillic.eot"), url("../fonts/bebas/BebasNeueCyrillic.eot?#iefix") format("embedded-opentype"), url("../fonts/bebas/BebasNeueCyrillic.svg#BebasNeueCyrillic") format("svg");
  /* Legacy iOS */ }

/**
 * Преобразование пикселей в rem на основе переменной с базовым размером шрифта.
 * @param  {number} $px Число пикселей (с единицами измерения или без)
 * @param  {string} Число пикселей, размер контентного шрифта на проекте
 * @return {string} Результат преобразования $px в rem
 */
html {
  touch-action: manipulation; }

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: "OpenSans-Regular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  overflow-x: hidden; }
  @media screen and (max-width: 991px) {
    body {
      font-size: 14px;
      line-height: 24px; } }
  @media screen and (max-width: 480px) {
    body {
      font-size: 12px;
      line-height: 20px; } }

.container {
  max-width: 1580px;
  width: 100%;
  margin: auto;
  padding: 0 20px; }
  @media screen and (max-width: 767px) {
    .container {
      padding: 0 27px; } }

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden; }

.apple-fix {
  width: 100%;
  position: fixed; }

.no-scroll {
  overflow: hidden; }

.img-wrapper {
  display: inline-block; }
  .img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative; }
  .wrapper .header {
    flex: 0 0 auto; }
  .wrapper main {
    position: relative;
    flex: 1 0 auto; }
  .wrapper .footer {
    flex: 0 0 auto; }

.logo {
  display: inline-block;
  font-family: "Play-Regular", sans-serif;
  position: relative;
  flex-shrink: 0;
  padding-right: 24px; }
  .logo__main {
    font-family: "Play-Bold", sans-serif;
    font-size: 30px;
    line-height: 30px;
    text-transform: uppercase;
    display: flex;
    align-items: center; }
    .logo__main span {
      width: 27px;
      height: 27px;
      background-color: #0099ff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin: 0 6px; }
      .logo__main span svg {
        fill: #fff; }
  .logo__bot {
    font-size: 11px;
    line-height: 1;
    letter-spacing: 0.39em; }
  .logo__abr {
    font-size: 12px;
    letter-spacing: 0.2em;
    position: absolute;
    right: 0;
    top: -4px; }

.header__exchange-rates .exchange-rate__ic, .header__tools-mob .icon-link .icon, .header__tools .icon-link .icon, .search-block button span, .city-toggle .icon, .hamburger-mob {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ececec; }

.header__tools-mob .icon-link .icon, .header__tools .icon-link .icon, .search-block button .icon, .city-toggle .icon {
  transition: .2s linear;
  transition-property: background-color; }

.header__tools-mob .icon-link:hover .icon, .header__tools .icon-link:hover .icon, .search-block button:hover .icon, .city-toggle:hover .icon {
  background-color: #66c2ff; }

.card-article.inverted .card-article__link, .sale-contract__link .icon, .link-back .icon, .card-article__link, .articles__more-link, .business .link-more .icon, .courses-slider .default-arrow, .article-item__title .icon, .article-item__more .icon, .training-item__link .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  background-color: #0099ff;
  border: 1px solid #0099ff;
  transition: .2s linear;
  transition-property: background-color, border-color; }
  .card-article.inverted .card-article__link:hover, .sale-contract__link .icon:hover, .link-back .icon:hover, .card-article__link:hover, .articles__more-link:hover, .business .link-more .icon:hover, .courses-slider .default-arrow:hover, .article-item__title .icon:hover, .article-item__more .icon:hover, .training-item__link .icon:hover {
    background-color: #fff; }
    .card-article.inverted .card-article__link:hover svg, .sale-contract__link .icon:hover svg, .link-back .icon:hover svg, .card-article__link:hover svg, .articles__more-link:hover svg, .business .link-more .icon:hover svg, .courses-slider .default-arrow:hover svg, .article-item__title .icon:hover svg, .article-item__more .icon:hover svg, .training-item__link .icon:hover svg {
      fill: #0099ff; }
  .card-article.inverted .card-article__link svg, .sale-contract__link .icon svg, .link-back .icon svg, .card-article__link svg, .articles__more-link svg, .business .link-more .icon svg, .courses-slider .default-arrow svg, .article-item__title .icon svg, .article-item__more .icon svg, .training-item__link .icon svg {
    width: 8px;
    height: 7px;
    fill: #fff;
    transition: fill .2s linear; }

.card-article.inverted .card-article__link, .sale-contract__link .icon {
  background-color: #fff;
  border-color: #fff; }
  .card-article.inverted .card-article__link:hover, .sale-contract__link .icon:hover {
    background: #0099ff; }
    .card-article.inverted .card-article__link:hover svg, .sale-contract__link .icon:hover svg {
      fill: #fff; }
  .card-article.inverted .card-article__link svg, .sale-contract__link .icon svg {
    fill: #0099ff; }

.card-article__comments svg,
.business-item-sm__comments svg {
  fill: none;
  stroke-width: 1px;
  stroke: #000; }

.link-back {
  display: inline-flex;
  align-items: center; }
  .link-back:hover .icon {
    background-color: #fff; }
    .link-back:hover .icon svg {
      fill: #0099ff; }
  .link-back .icon {
    margin-right: 20px; }
    .link-back .icon svg {
      transform: rotate(180deg); }

.select {
  position: relative;
  z-index: 1; }
  .select.is-active .select-list {
    visibility: visible; }
    .select.is-active .select-list a {
      opacity: 1;
      transform: translateY(0); }
  .select .select-toggle {
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 184px;
    height: 35px;
    background-color: #0099ff;
    padding: 0 28px;
    border-radius: 70px;
    cursor: pointer;
    position: relative;
    z-index: 1; }
  .select .select-val {
    display: block;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff; }
    .select .select-val:empty:after {
      content: attr(data-placeholder); }
  .select .select-list {
    font-size: 14px;
    display: block;
    width: 100%;
    max-height: 256px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0 5px 20px 0 rgba(36, 47, 83, 0.1);
    visibility: hidden;
    transition: visibility .2s linear;
    overflow: auto;
    scrollbar-color: #0099ff #ececec;
    scrollbar-width: thin; }
    .select .select-list::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #ececec; }
    .select .select-list::-webkit-scrollbar-thumb:vertical {
      background-color: #0099ff;
      border-radius: 2px; }
    .select .select-list::-webkit-scrollbar-thumb:horizontal {
      background-color: #0099ff;
      border-radius: 2px; }
    .select .select-list li {
      overflow: hidden; }
      .select .select-list li:first-child a {
        padding-top: calc(18px + 5px); }
    .select .select-list a {
      position: relative;
      display: block;
      padding: 5px 10px;
      text-decoration: none;
      background-color: #fff;
      transform: translateY(-15px);
      opacity: 0;
      transition: .2s linear;
      transition-property: opacity, transform; }
      .select .select-list a:hover {
        background-color: #ececec; }

.card-article {
  position: relative;
  background-color: #fff; }
  .card-article.inverted {
    background-color: #000;
    color: #fff; }
    .card-article.inverted .card-article__tag {
      color: #fff;
      background-color: #0099ff; }
  .card-article.img {
    height: 460px; }
    .card-article.img .card-article__inner {
      height: calc(100% - 220px);
      padding-top: 25px;
      padding-bottom: 25px; }
    .card-article.img .card-article__bg {
      position: relative;
      width: 100%;
      height: 220px;
      opacity: 1; }
    .card-article.img .card-article__link {
      margin-top: auto; }
  .card-article.icon {
    padding-top: 45px; }
    .card-article.icon .card-article__bg {
      width: 168px;
      height: 168px;
      border-radius: 50%;
      margin: 0 auto 30px; }
    .card-article.icon .card-article__inner {
      height: calc(100% - 198px);
      padding-top: 0; }
  .card-article.big .card-article__inner {
    height: 100%;
    justify-content: center;
    align-items: center; }
  .card-article.big .card-article__title {
    font-family: "OpenSans-Light", sans-serif;
    font-size: 44px;
    line-height: 57px;
    margin-bottom: 30px; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .card-article.big .card-article__title {
        width: 100%; } }
  .card-article.big .card-article__wrapper {
    text-align: center;
    display: inline-block; }
    .card-article.big .card-article__wrapper:hover .card-article__link {
      background: #0099ff;
      border-color: #fff; }
      .card-article.big .card-article__wrapper:hover .card-article__link svg {
        fill: #fff; }
    .card-article.big .card-article__wrapper:hover .text {
      color: #0099ff; }
    .card-article.big .card-article__wrapper .text {
      font-family: "OpenSans-Light", sans-serif;
      font-size: 16px;
      line-height: normal;
      display: inline-block;
      transition: all .2s linear;
      margin-top: 22px; }
  .card-article:last-child {
    margin-bottom: 0; }
  .card-article__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    background: center/cover no-repeat; }
  .card-article__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 34px 50px 32px; }
  .card-article__tag {
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    color: #0099ff;
    padding: 7px 12px;
    border: 1px solid #0099ff;
    text-align: center;
    margin: 0 auto 25px; }
  .card-article__title {
    font-family: "OpenSans-Regular", sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
    transition: color .2s linear; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .card-article__title {
        width: 100%; } }
    .card-article__title:hover {
      color: #0099ff; }
  .card-article__link {
    margin: 0 auto; }

.card-article__info {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.card-article__info, .card-article__like-block {
  display: none; }

.card-article__view svg {
  fill: #a6a6a6; }

.card-article__review svg {
  fill: #fff;
  stroke: #a6a6a6; }

.card-article .articles-item-link {
  text-align: center;
  display: inline-block; }
  .card-article .articles-item-link:hover .card-article__link {
    background: #0099ff;
    border-color: #fff; }
    .card-article .articles-item-link:hover .card-article__link svg {
      fill: #fff; }
  .card-article .articles-item-link:hover .text {
    color: #0099ff; }
  .card-article .articles-item-link .text {
    font-family: "OpenSans-Light", sans-serif;
    font-size: 16px;
    line-height: normal;
    display: inline-block;
    transition: all .2s linear;
    margin-top: 22px; }

.like-box {
  display: flex;
  align-items: center; }
  .like-box .like-btn {
    background: none;
    padding: 0;
    width: 20px;
    height: 20px;
    border-radius: 0; }
    .like-box .like-btn.is-active {
      pointer-events: none; }
    .like-box .like-btn.is-active svg, .like-box .like-btn:hover svg {
      fill: #0099ff; }
    .like-box .like-btn svg {
      fill: #dcdcdc; }
  .like-box .like-bar-box {
    width: 260px;
    margin: 0 25px; }
  .like-box .like-value {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    line-height: 1;
    margin-bottom: 8px; }
    .like-box .like-value span:before {
      content: attr(data-before);
      margin-right: 4px; }
    .like-box .like-value__like {
      color: #0099ff; }
  .like-box .like-bar-wrap {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: #dcdcdc; }
  .like-box .like-bar {
    position: absolute;
    left: 0;
    height: 100%;
    background-color: #0099ff; }

@media (max-width: 1400px) {
  .card-article__inner {
    padding: 34px 30px 32px; } }

@media (max-width: 1279px) {
  .card-article__title {
    font-size: 16px;
    line-height: 22px; } }

@media (max-width: 1199px) {
  .card-article__inner {
    padding: 34px 15px 32px; } }

@media (max-width: 991px) {
  .card-article.icon .card-article__bg {
    width: 120px;
    height: 120px; }
  .card-article.icon .card-article__inner {
    height: calc(100% - 165px); } }

@media (max-width: 575px) {
  .logo {
    padding-right: 14px; }
    .logo__main {
      font-size: 25px;
      line-height: 30px; }
      .logo__main span {
        width: 23px;
        height: 23px; }
    .logo__bot {
      font-size: 10px; }
    .logo__abr {
      font-size: 10px; }
  .card-article.img .card-article__inner {
    height: auto; }
  .card-article__info {
    display: flex;
    width: 100%;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.52);
    margin-bottom: 15px; }
    .card-article__info svg {
      margin-right: 10px; }
  .card-article__like-block {
    display: block;
    width: 100%; }
  .card-article__view, .card-article__review {
    display: flex;
    align-items: center; }
  .card-article__view svg {
    width: 23px;
    height: 17px; }
  .card-article.inverted .card-article__info {
    color: #fff; }
  .card-article.inverted .card-article svg {
    fill: #fff; }
  .card-article.inverted .card-article__view svg {
    fill: #fff; }
  .card-article.inverted .card-article__link {
    display: none; }
  .card-article.inverted .card-article .articles-item-link {
    display: none; }
  .card-article .like-box .like-bar-box {
    width: 100%;
    margin: 0; }
  .card-article__link {
    display: none; }
  .card-article .articles-item-link {
    display: none; } }

* {
  box-sizing: border-box;
  outline: none; }

*::before, *::after {
  box-sizing: inherit; }

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0; }

ul, ol {
  margin: 0; }
  ul.no-style, ol.no-style {
    padding: 0;
    list-style: none; }

button {
  outline: none;
  border: none; }

img {
  border: none; }

a {
  text-decoration: none;
  color: inherit; }

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block;
  /* For Firefox */ }

select {
  appearance: none; }
  select::-ms-expand {
    display: none; }

button {
  cursor: pointer; }

input[type='number'] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-text-fill-color: #404040 !important; }

blockquote {
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-family: "OpenSans-SemiBold", sans-serif; }

h1 {
  font-size: 60px;
  line-height: normal; }

h2 {
  font-size: 24px;
  line-height: 1.2; }
  @media (max-width: 639px) {
    h2 {
      font-size: 18px; } }

h3 {
  font-size: 18px;
  line-height: 24px; }

.article {
  background-color: #ececec; }
  .article .container {
    display: flex;
    justify-content: space-between; }
  .article .article-bg {
    position: absolute;
    right: 0;
    top: 0;
    width: calc(100vw - 360px - 32px);
    height: 100%;
    background-color: #000; }
    .article .article-bg span {
      display: block;
      width: 100%;
      height: 100%;
      background: right/cover no-repeat;
      opacity: .33; }
  .article .article-item__tags {
    margin-bottom: 53px; }
  .article .article-item__title {
    margin-bottom: 63px; }
  .article .text-large {
    font-family: "OpenSans-Light", sans-serif;
    font-size: 24px;
    line-height: 42px; }
  .article .line-top {
    font-size: 14px;
    line-height: 24px; }
    .article .line-top::before {
      content: '';
      display: block;
      width: 26px;
      height: 2px;
      background-color: #000;
      margin-bottom: 50px; }
  .article .text-float {
    display: inline-block;
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    width: 445px;
    padding: 25px 0;
    transform: translateX(-90px);
    float: left; }
  .article .block-img {
    display: flex;
    align-items: flex-start;
    margin: 80px 0; }
    .article .block-img img {
      max-width: calc(100% - 170px - 50px + 90px);
      transform: translateX(-90px);
      margin-right: -90px; }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .article .block-img img {
          width: 100%; } }
    .article .block-img p {
      display: inline-block; }
    .article .block-img .line-top {
      display: inline-block;
      width: 170px;
      margin-left: 50px; }
  .article .row-share {
    margin-top: 80px; }

.article-content {
  width: calc(100% - 360px - 32px); }
  .article-content h3 {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 35px; }

.article-aside {
  width: 360px;
  padding-top: 25px; }
  .article-aside .card-article {
    margin-bottom: 25px; }

.article-header {
  position: relative;
  padding-top: 60px;
  padding-bottom: 74px;
  padding-right: 80px;
  background-color: #000;
  color: #fff; }
  .article-header .article-header-inner {
    position: relative; }
  .article-header .link-back {
    margin-bottom: 210px; }

.article-body {
  position: relative;
  padding-top: 110px;
  padding-left: 130px;
  padding-right: 30px;
  padding-bottom: 80px; }
  .article-body::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100%;
    background-color: #fff; }
  .article-body .article-body-inner {
    position: relative;
    max-width: 786px; }
  .article-body p {
    margin-bottom: 28px; }

.article-comments {
  padding-top: 60px;
  padding-bottom: 110px;
  padding-left: 130px; }

.article-comments-header {
  display: flex;
  align-items: center;
  margin-bottom: 32px; }
  .article-comments-header .sorting-block {
    margin-left: auto; }
  .article-comments-header .add-comment {
    margin-left: 85px; }

.article-comments-grid {
  margin-bottom: 53px; }

.comment-item {
  background-color: #fff;
  border-radius: 4px;
  padding: 39px 36px;
  margin-bottom: 7px;
  display: flex;
  position: relative; }
  .comment-item.reply {
    margin-left: 92px; }
    .comment-item.reply::before {
      content: '';
      position: absolute;
      top: 50px;
      left: -44px;
      width: 9px;
      height: 15px;
      background: url(../img/icons/arr-top-comment.svg) center/contain no-repeat; }
  .comment-item--gray .comment-item__body {
    color: rgba(0, 0, 0, 0.58); }
  .comment-item__rating {
    position: absolute;
    top: 25px;
    right: 26px;
    display: flex;
    align-items: center; }
    .comment-item__rating button {
      padding: 0;
      background: none; }
      .comment-item__rating button.is-active {
        pointer-events: none; }
      .comment-item__rating button:hover svg, .comment-item__rating button.is-active svg {
        fill: #0099ff; }
      .comment-item__rating button svg {
        fill: #a8a8a8;
        transition: fill .2s linear; }
  .comment-item__rating-val {
    width: 40px;
    text-align: center;
    font-size: 11px; }
    .comment-item__rating-val.minus {
      color: #e03a0d; }
  .comment-item__avatar {
    flex-shrink: 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 22px; }
    .comment-item__avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .comment-item__content {
    width: calc(100% - 44px - 22px); }
  .comment-item__header {
    font-size: 14px;
    line-height: 28px;
    margin-bottom: 10px; }
    .comment-item__header a {
      transition: color .2s linear; }
      .comment-item__header a:last-of-type:after {
        display: none; }
      .comment-item__header a:after {
        content: '→';
        display: inline-block;
        color: #0099ff;
        margin: 0 10px; }
      .comment-item__header a:hover {
        color: #0099ff; }
  .comment-item__time {
    color: #7f8187; }
  .comment-item__body {
    margin-bottom: 15px; }
  .comment-item__reply {
    font-size: 14px;
    color: #0099ff;
    transition: color .2s linear; }
    .comment-item__reply:hover {
      color: #000; }

.article-row-btns {
  display: flex;
  justify-content: center; }

.article-comments-more {
  display: inline-flex;
  align-items: center;
  color: #0099ff;
  font-size: 14px; }
  .article-comments-more:hover .icon {
    background-color: #fff;
    color: #0099ff; }
  .article-comments-more .icon {
    display: inline-flex;
    justify-content: center;
    flex-shrink: 0;
    align-items: center;
    text-align: center;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 1px solid #0099ff;
    background-color: #0099ff;
    color: #fff;
    line-height: 1;
    font-size: 12px;
    padding-bottom: 2px;
    margin-left: 12px;
    transition: .2s linear;
    transition-property: color, background-color; }
    .article-comments-more .icon:after {
      content: '↓'; }

.article-comment-widget {
  margin-top: 54px;
  padding-top: 38px;
  background-color: #fff; }
  .article-comment-widget .title {
    padding-left: 64px;
    color: #23252a; }

.comment-widget {
  background-color: #fff;
  padding: 38px 64px 38px 36px;
  border-radius: 4px;
  display: flex; }
  .comment-widget__avatar {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px; }
    .comment-widget__avatar img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .comment-widget__form {
    width: calc(100% - 64px); }
  .comment-widget__container {
    height: 214px;
    margin-bottom: 17px;
    scrollbar-color: #0099ff #ececec;
    scrollbar-width: thin; }
    .comment-widget__container::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      background-color: #ececec; }
    .comment-widget__container::-webkit-scrollbar-thumb:vertical {
      background-color: #0099ff; }
    .comment-widget__container::-webkit-scrollbar-thumb:horizontal {
      background-color: #0099ff; }
    .comment-widget__container .ql-editor {
      scrollbar-color: #0099ff #ececec;
      scrollbar-width: thin; }
      .comment-widget__container .ql-editor::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        background-color: #ececec; }
      .comment-widget__container .ql-editor::-webkit-scrollbar-thumb:vertical {
        background-color: #0099ff; }
      .comment-widget__container .ql-editor::-webkit-scrollbar-thumb:horizontal {
        background-color: #0099ff; }
  .comment-widget__form-footer {
    display: flex;
    align-items: center;
    font-size: 14px; }
    .comment-widget__form-footer .icon {
      width: 12px;
      height: 11px;
      fill: #0099ff;
      margin-right: 10px; }
  .comment-widget__notice {
    margin-right: 30px; }
  .comment-widget__form-submit {
    margin-left: auto; }
  .comment-widget .link-btn {
    color: #0099ff;
    text-transform: uppercase;
    border: 1px solid #0099ff;
    padding: 6px 12px;
    margin-left: 10px;
    transition: .2s linear;
    transition-property: color, background-color; }
    .comment-widget .link-btn:hover {
      color: #fff;
      background-color: #0099ff; }

@media (max-width: 1400px) {
  .article-header {
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 40px; }
  .article-content {
    width: calc(100% - 360px - 20px); }
  .article-aside {
    padding-top: 20px; }
    .article-aside .card-article {
      margin-bottom: 20px; }
  .article .like-box .like-bar-box {
    width: 200px; }
  .article .block-img img {
    transform: translateX(-100px); }
  .article .block-img .line-top {
    width: 190px;
    margin-left: 30px; }
  .article-item__footer {
    font-size: 14px; }
  .article-body {
    padding: 80px 30px 40px 80px; } }

@media (max-width: 1280px) {
  .comment-widget {
    padding: 38px 30px 38px 30px; } }

@media (max-width: 1199px) {
  .article-content {
    width: 100%; }
  .article-aside {
    display: none; }
  .article .article-bg {
    width: 100%; }
  .article-header {
    padding: 40px 20px; }
    .article-header .link-back {
      margin-bottom: 160px; }
  .article .container {
    padding: 0; }
  .article .block-img img {
    max-width: calc(100% - 400px - 50px + 90px); }
  .article .block-img .line-top {
    width: 400px; }
  .article .text-float {
    width: 500px;
    transform: translateX(-20px); }
  .article-comments {
    padding: 40px 20px; }
  .article-body {
    padding: 40px 20px; }
    .article-body .article-body-inner {
      max-width: none; } }

@media (max-width: 991px) {
  .article .like-box .like-bar-box {
    width: 155px; }
  .article .text-large {
    line-height: normal; }
  .comment-widget__form-footer {
    display: block; }
  .comment-widget__notice {
    margin-right: 0;
    margin-bottom: 15px; }
  .comment-widget .link-btn {
    line-height: 35px; } }

@media (max-width: 900px) {
  .article .block-img img {
    max-width: calc(100% - 250px - 50px + 90px); }
  .article .block-img .line-top {
    width: 250px; } }

@media (max-width: 767px) {
  .article .like-box .like-bar-box {
    width: 260px; }
  .article .text-float {
    width: 100%;
    transform: none;
    float: none;
    text-align: left;
    font-size: 18px;
    line-height: normal;
    padding: 10px 0 20px; }
  .article .block-img {
    margin: 50px 0; }
  .article .row-share {
    margin-top: 50px; }
  .article .text-large {
    font-size: 18px; }
  .article-content h3 {
    font-size: 24px;
    line-height: normal; }
  .article-header .link-back {
    margin-bottom: 60px; }
  .article-header,
  .article-body,
  .article-comments {
    padding: 40px 27px; }
  .article-item__footer {
    flex-wrap: wrap; }
  .article-item__rating {
    width: 100%; }
  .article-item__published, .article-item__comments, .article-item__view {
    margin-bottom: 20px; }
  .article-comments-header {
    flex-wrap: wrap; }
    .article-comments-header .add-comment {
      margin-left: 0;
      margin-top: 20px;
      margin-right: 300px; }
    .article-comments-header .sorting-block .sorting-select__list {
      left: auto;
      right: 0; }
  .comment-item__rating {
    top: 15px; } }

@media (max-width: 639px) {
  .comment-item {
    padding: 35px 25px 25px; }
    .comment-item.reply {
      margin-left: 40px; }
      .comment-item.reply::before {
        left: -30px; } }

@media (max-width: 575px) {
  .article .block-img {
    display: block;
    text-align: center;
    margin: 30px 0; }
    .article .block-img img {
      width: 100%;
      max-width: 300px;
      transform: none;
      margin: auto; }
    .article .block-img .line-top {
      display: block;
      width: 100%;
      margin-left: 0;
      text-align: left; }
      .article .block-img .line-top::before {
        margin: 15px auto; }
  .article .article-item__title {
    font-size: 28px;
    line-height: normal; }
  .article-content h3 {
    font-size: 18px;
    margin-bottom: 25px; }
  .comment-widget {
    padding: 35px 15px; } }

@media (max-width: 479px) {
  .article-comments-header {
    margin-bottom: 25px; }
    .article-comments-header .sorting-block {
      width: 100%;
      margin-left: 0;
      margin-top: 20px; }
    .article-comments-header .sorting-block .sorting-select__list {
      left: 0;
      right: auto; }
    .article-comments-header .add-comment {
      margin-right: 0; }
  .comment-item {
    display: block;
    padding: 35px 15px 25px; }
    .comment-item.reply {
      margin-left: 25px; }
      .comment-item.reply::before {
        left: -20px; }
    .comment-item__avatar {
      width: 40px;
      height: 40px; }
    .comment-item__header {
      line-height: 24px; }
    .comment-item__avatar {
      margin-bottom: 10px; }
    .comment-item__content {
      width: 100%; }
  .comment-widget {
    padding: 25px 15px; }
    .comment-widget__avatar {
      width: 40px;
      height: 40px;
      margin-right: 15px; }
  .article-comment-widget {
    padding-top: 25px; }
    .article-comment-widget .title {
      padding-left: 15px; } }

@media (max-width: 400px) {
  .comment-widget {
    padding: 25px 8px 25px 15px; } }

.related-articles {
  padding-top: 120px;
  padding-bottom: 90px;
  background-color: #242424;
  color: #fff; }
  .related-articles .section-title {
    margin-bottom: 50px; }
  .related-articles .card-article {
    color: #000;
    margin-bottom: 30px; }
    .related-articles .card-article:last-child {
      margin-bottom: 0; }
    .related-articles .card-article.inverted {
      color: #fff; }
    .related-articles .card-article.big {
      height: 100%; }
    .related-articles .card-article.icon {
      height: 100%;
      min-height: 460px; }

.related-articles-grid {
  display: flex;
  justify-content: space-between; }

.related-articles-col {
  display: flex;
  flex-direction: column;
  width: calc(50%/2 - 30px/2); }
  .related-articles-col:nth-child(3) {
    width: calc(50% - 60px/2); }

@media (max-width: 1199px) {
  .related-articles .card-article {
    margin-bottom: 15px; }
    .related-articles .card-article.icon {
      min-height: 435px; }
  .related-articles .card-article.big .card-article__title {
    font-size: 36px;
    line-height: normal; }
  .related-articles-col {
    width: calc(50%/2 - 15px/2); }
    .related-articles-col:nth-child(3) {
      width: calc(50% - 30px/2); } }

@media (max-width: 991px) {
  .related-articles {
    padding: 50px 0; }
  .related-articles-col {
    width: calc(100%/3 - 10px); }
    .related-articles-col:nth-child(3) {
      width: calc(100%/3 - 10px); }
    .related-articles-col .card-article.big .card-article__title {
      font-size: 24px; } }

@media (max-width: 767px) {
  .related-articles-grid {
    flex-wrap: wrap; }
  .related-articles-col {
    width: calc(50% - 15px); }
    .related-articles-col:nth-child(3) {
      width: 100%;
      margin-top: 30px; } }

@media (max-width: 575px) {
  .related-articles .section-title {
    margin-bottom: 20px; }
  .related-articles .card-article.icon {
    min-height: auto; }
  .related-articles-col {
    width: 100%;
    margin-bottom: 15px; }
    .related-articles-col:nth-child(3) {
      margin-top: 0; } }

.articles {
  background-color: #ececec;
  padding: 50px 0 150px; }
  .articles__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px; }
  .articles__grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -31px; }
  .articles .card-article {
    width: calc((100% - 124px)/4);
    margin-left: 31px;
    margin-bottom: 31px;
    display: none; }
    .articles .card-article.img {
      height: auto; }
    .articles .card-article.show {
      display: block; }
    .articles .card-article:last-child {
      margin-bottom: 31px; }
    .articles .card-article.inverted {
      width: calc((100% - 62px)/2); }
      .articles .card-article.inverted .card-article__inner {
        height: 100%;
        justify-content: center;
        align-items: center; }
      .articles .card-article.inverted .card-article__title {
        font-family: "OpenSans-Light", sans-serif;
        font-size: 44px;
        line-height: 57px;
        margin-bottom: 30px; }
  .articles__footer {
    text-align: center; }
  .articles__more-block {
    margin-top: 50px; }
  .articles__more-text {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 20px; }
  .articles__more-link {
    margin: auto; }
    .articles__more-link svg {
      transform: rotate(90deg); }

.articles-cat-block {
  width: calc((100% - 124px)/4);
  margin-bottom: 31px;
  margin-left: auto; }
  .articles-cat-block.mobile {
    display: none; }
  .articles-cat-block .cat-block {
    height: 100%; }

@media (max-width: 1700px) {
  .articles .sorting-block .sorting-select__list {
    left: auto;
    right: 0; } }

@media (max-width: 1279px) {
  .articles-cat-block {
    display: none;
    width: 100%; }
    .articles-cat-block.mobile {
      display: block; }
    .articles-cat-block .cat-block {
      width: 100%; }
      .articles-cat-block .cat-block__title {
        padding-top: 0;
        margin-bottom: 20px; }
  .articles {
    padding: 50px 0; }
    .articles__footer {
      margin-top: 40px; }
    .articles__grid {
      margin-left: -15px; }
    .articles .card-article {
      width: calc((100% - 60px)/4);
      margin-left: 15px;
      margin-bottom: 15px; }
      .articles .card-article.inverted {
        width: calc((100% - 30px)/2); }
        .articles .card-article.inverted .card-article__title {
          font-size: 40px;
          line-height: 52px; } }

@media (max-width: 1100px) {
  .articles .card-article {
    width: calc((100% - 46px)/3); }
    .articles .card-article.img .card-article__bg {
      height: 180px; }
    .articles .card-article.inverted {
      width: calc((100% - 46px)/3); }
      .articles .card-article.inverted .card-article__title {
        font-size: 24px;
        line-height: normal; }
    .articles .card-article:last-child {
      margin-bottom: 15px; } }

@media (max-width: 767px) {
  .articles .card-article {
    width: calc(50% - 15px); }
    .articles .card-article.inverted {
      width: calc(50% - 15px); } }

@media (max-width: 575px) {
  .articles .pagination {
    display: none; }
  .articles__footer {
    margin-top: 25px; }
  .articles__more-block {
    margin-top: 0; }
  .articles .cat-block__title {
    font-size: 18px; }
  .articles__header {
    display: block;
    margin-bottom: 30px; }
  .articles__title {
    margin-bottom: 15px; }
  .articles .card-article {
    width: 100%; }
    .articles .card-article.inverted {
      width: 100%; }
      .articles .card-article.inverted .card-article__inner {
        padding: 30px 29px 50px; }
    .articles .card-article.img .card-article__inner {
      padding: 30px 29px 50px; } }

@media (max-width: 479px) {
  .articles__grid {
    margin: 0 -27px; }
  .articles .card-article {
    margin-left: 0; } }

@media (max-width: 420px) {
  .articles .card-article.img .card-article__bg {
    height: 132px; } }

.business {
  padding-top: 80px;
  padding-bottom: 64px;
  background-color: #ececec; }
  .business .link-more {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #0099ff;
    margin-top: auto; }
    .business .link-more:hover .icon {
      background-color: #fff; }
      .business .link-more:hover .icon svg {
        fill: #0099ff; }
    .business .link-more .icon {
      margin-right: 18px; }
  .business__footer {
    display: none;
    text-align: center; }
  .business__mob-title {
    display: none;
    font-size: 24px;
    font-family: "OpenSans-SemiBold", sans-serif;
    line-height: normal; }

.business-grid,
.content {
  display: flex;
  justify-content: space-between; }

.business-grid-col,
.business-col {
  width: calc(50% - 16px); }

.business-col-header {
  display: flex;
  align-items: center;
  margin-bottom: 44px; }
  .business-col-header .business-col-title {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 24px;
    line-height: normal;
    margin-right: 30px; }

.business-item {
  margin-bottom: 32px; }

.card-article__comments,
.business-item-sm__comments {
  display: flex;
  align-items: center; }
  .card-article__comments .icon,
  .business-item-sm__comments .icon {
    line-height: 1;
    margin-right: 12px; }
  .card-article__comments svg,
  .business-item-sm__comments svg {
    stroke: #939393;
    width: 14px;
    height: 13px; }

.business-wrapper-items {
  background-color: #fff;
  height: 100%;
  padding: 35px 40px 30px; }

.business-item-sm {
  padding-top: 20px;
  margin-bottom: 20px;
  border-top: 1px solid #dcdcdc; }
  .business-item-sm:first-child {
    padding-top: 0;
    border: none; }
  .business-item-sm__title {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 15px;
    transition: color .2s linear; }
    .business-item-sm__title:hover {
      color: #0099ff; }
  .business-item-sm__comments {
    font-size: 12px;
    line-height: normal;
    color: rgba(0, 0, 0, 0.5); }

@media (max-width: 1400px) {
  .business-grid-col,
  .business-col {
    width: calc(50% - 10px); }
  .business-item {
    margin-bottom: 20px; } }

@media (max-width: 1199px) {
  .business-grid {
    display: block; }
  .business-grid-col {
    width: 100%; }
  .business-col {
    width: calc(50% - 20px); }
    .business-col .card-article:last-child {
      margin-bottom: 20px; } }

@media (max-width: 991px) {
  .business {
    padding: 40px 0 30px; }
    .business__footer {
      display: block;
      padding-top: 10px; }
    .business__mob-title {
      display: block;
      margin-bottom: 30px; }
  .business-com {
    display: none; }
  .business-col {
    width: calc(50% - 10px); }
  .business-col-header {
    display: none; } }

@media (max-width: 639px) {
  .business__mob-title {
    font-size: 18px; } }

@media (max-width: 575px) {
  .business-col {
    width: 100%; }
  .business .content {
    display: block; } }

@media (max-width: 479px) {
  .business {
    padding-top: 30px; }
    .business .container {
      padding: 0; }
    .business__mob-title {
      text-align: center;
      padding: 0 27px; }
    .business__footer {
      padding-top: 30px; }
  .business-item {
    margin-bottom: 0; }
  .business-col .card-article:last-child {
    margin-bottom: 0; } }

.cat-item {
  display: none; }
  .cat-item.show {
    display: block; }

.cat-block {
  background-color: #fff;
  padding: 30px 55px 50px; }
  .cat-block__title {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 24px;
    line-height: normal;
    padding-top: 10px; }
  .cat-block__more-item {
    display: inline-flex;
    align-items: center;
    color: #0099ff;
    line-height: normal;
    cursor: pointer;
    padding-left: 10px;
    margin-top: 15px; }
    .cat-block__more-item svg {
      margin-left: 16px;
      fill: #0099ff;
      transition: .2s linear;
      transition-property: transform; }
    .cat-block__more-item.is-active svg {
      transform: rotateX(180deg); }

.cat-block-tabs .wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.cat-block-tabs__item-m-block, .cat-block-tabs__item-a-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.cat-block-tabs__item {
  font-size: 16px;
  line-height: normal;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  background-color: rgba(0, 153, 255, 0);
  transition: all .25s linear;
  padding: 2px 10px; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .cat-block-tabs__item {
      min-height: 26px; } }
  .cat-block-tabs__item::before {
    content: '';
    position: absolute;
    left: -11px;
    top: 0;
    bottom: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 13px 11px 13px 0;
    border-color: transparent #0099ff transparent transparent;
    opacity: 0;
    transition: opacity .25s linear; }
  .cat-block-tabs__item:last-child {
    margin-bottom: 0; }
  .cat-block-tabs__item span {
    position: relative;
    transition: color .25s linear;
    display: inline-block; }
  .cat-block-tabs__item:hover, .cat-block-tabs__item.is-active {
    background-color: #0099ff; }
    .cat-block-tabs__item:hover::before, .cat-block-tabs__item.is-active::before {
      opacity: 1; }
    .cat-block-tabs__item:hover span, .cat-block-tabs__item.is-active span {
      color: #fff; }

.cat-block-tabs__item-m-block {
  margin-top: 15px; }

.cat-block-tabs__item-a-block {
  display: none;
  margin-top: 15px; }

@media (max-width: 1600px) {
  .cat-block {
    padding: 30px; } }

@media (max-width: 1279px) {
  .cat-block-tabs__item-m-block {
    flex-direction: row;
    flex-wrap: wrap;
    display: inline-block;
    margin-top: 0; }
  .cat-block-tabs__item-a-block {
    margin-top: 0;
    display: none; }
  .cat-block-tabs__item {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px; }
    .cat-block-tabs__item:last-child {
      margin-bottom: 10px; }
    .cat-block-tabs__item::before {
      display: none; }
  .cat-block {
    padding: 20px 20px 10px; }
    .cat-block__more-item {
      display: none; } }

@media (max-width: 479px) {
  .cat-block-tabs__item-m-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  .cat-block-tabs__item::before {
    display: block; }
  .cat-block {
    padding-bottom: 20px; }
    .cat-block__more-item {
      font-size: 16px;
      display: flex;
      margin-top: 10px; } }

.cat-select-block {
  display: flex; }

.cat-select {
  position: relative;
  z-index: 5;
  margin-right: 15px; }
  .cat-select.is-active .cat-select__list {
    visibility: visible; }
  .cat-select.is-active .cat-select__item-link {
    opacity: 1;
    transform: translateY(0); }
  .cat-select__main {
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 215px;
    height: 35px;
    background-color: #0099ff;
    padding: 0 25px;
    border-radius: 70px;
    cursor: pointer;
    position: relative;
    z-index: 1; }
  .cat-select__main-init, .cat-select__main-ready {
    display: block;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff; }
  .cat-select__main-ready {
    display: none; }
    .cat-select__main-ready span {
      margin-left: 3px; }
  .cat-select input {
    display: none; }
  .cat-select__list {
    font-size: 14px;
    display: block;
    width: 100%;
    max-height: 256px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0 5px 20px 0 rgba(36, 47, 83, 0.1);
    visibility: hidden;
    transition: visibility .2s linear;
    overflow: auto;
    scrollbar-color: #0099ff #ececec;
    scrollbar-width: thin; }
    .cat-select__list::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: #ececec; }
    .cat-select__list::-webkit-scrollbar-thumb:vertical {
      background-color: #0099ff;
      border-radius: 2px; }
    .cat-select__list::-webkit-scrollbar-thumb:horizontal {
      background-color: #0099ff;
      border-radius: 2px; }
  .cat-select .cat-select__item {
    overflow: hidden; }
    .cat-select .cat-select__item:first-child .cat-select__item-link {
      padding-top: calc(18px + 5px); }
  .cat-select__item.is-active {
    pointer-events: none; }
    .cat-select__item.is-active .cat-select__item-link {
      background-color: #ececec; }
  .cat-select__item-link {
    position: relative;
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;
    transform: translateY(-15px);
    opacity: 0;
    transition: .2s linear;
    transition-property: opacity, transform; }
    .cat-select__item-link:hover {
      background-color: #ececec; }

.cat-list-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap; }
  .cat-list-block__item {
    font-size: 12px;
    line-height: normal;
    border: 1px solid #0099ff;
    color: #0099ff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    margin-right: 10px;
    margin-bottom: 10px; }
  .cat-list-block__arrow {
    width: 10px;
    height: 10px;
    display: block;
    position: relative;
    cursor: pointer;
    margin-left: 8px; }
    .cat-list-block__arrow::before, .cat-list-block__arrow::after {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
      background-color: #0099ff;
      width: 10px;
      height: 1px; }
    .cat-list-block__arrow::before {
      transform: rotate(-45deg); }
    .cat-list-block__arrow::after {
      transform: rotate(45deg); }

.catalog-comp {
  overflow: hidden;
  padding: 65px 0; }
  .catalog-comp__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px; }
  .catalog-comp__grid {
    display: flex; }
  .catalog-comp__title {
    margin-right: auto; }
  .catalog-comp__all-link {
    margin-left: 67px; }
  .catalog-comp__footer {
    display: none;
    text-align: center; }

.catalog-comp-item {
  background-color: #f2f2f2;
  width: calc((100% - 90px)/7);
  margin-right: 15px; }
  .catalog-comp-item:last-child {
    margin-right: 0; }
  .catalog-comp-item__inner {
    position: relative;
    padding: 7px 29px 35px; }
  .catalog-comp-item__title-block {
    min-height: 66px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(199, 199, 199, 0.59); }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .catalog-comp-item__title-block {
        padding-top: 20px; } }
    .catalog-comp-item__title-block a {
      transition: color .2s linear; }
      .catalog-comp-item__title-block a:hover {
        color: #018ae6; }
  .catalog-comp-item__title {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 14px;
    line-height: normal; }
  .catalog-comp-item__num {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 10px;
    line-height: normal;
    color: #fff;
    background-color: #0099ff;
    position: absolute;
    right: 9px;
    top: 7px;
    border-radius: 17px;
    padding: 3px 6px;
    min-height: 20px;
    min-width: 34px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .catalog-comp-item__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    margin-top: 25px; }
  .catalog-comp-item__list-item {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 13px; }
    .catalog-comp-item__list-item a {
      color: #018ae6;
      transition: color .2s linear; }
      .catalog-comp-item__list-item a:hover {
        color: #000; }

@media (max-width: 1600px) {
  .catalog-comp-item__inner {
    padding: 7px 20px 30px; } }

@media (max-width: 1500px) {
  .catalog-comp-item {
    width: 220px; } }

@media (max-width: 767px) {
  .catalog-comp__grid {
    flex-wrap: wrap;
    justify-content: space-between; }
  .catalog-comp a.catalog-comp__all-link {
    display: none; }
  .catalog-comp__footer {
    display: block; }
    .catalog-comp__footer a.catalog-comp__all-link {
      display: inline-block;
      margin-top: 18px;
      margin-left: 0; }
  .catalog-comp-item {
    width: calc(50% - 10px);
    margin: 0;
    margin-bottom: 20px;
    display: none; }
    .catalog-comp-item:first-child, .catalog-comp-item:nth-child(2), .catalog-comp-item:nth-child(3), .catalog-comp-item:nth-child(4) {
      display: block; } }

@media (max-width: 575px) {
  .catalog-comp {
    padding: 45px 0 48px; }
    .catalog-comp__header {
      margin-bottom: 35px; } }

@media (max-width: 479px) {
  .catalog-comp__header {
    display: block;
    text-align: center; }
  .catalog-comp-item {
    width: 100%;
    margin-bottom: 5px; }
    .catalog-comp-item__inner {
      padding: 0; }
    .catalog-comp-item__title-block {
      border: none;
      min-height: 78px;
      padding: 15px 30px; }
    .catalog-comp-item__list {
      display: none; } }

.courses {
  background-color: #ececec;
  padding: 55px 0 115px; }
  .courses__title {
    margin-right: 10px; }
  .courses__grid {
    display: flex; }
  .courses .cat-select-block {
    align-self: flex-start;
    margin-right: auto; }

.courses-main {
  width: 100%; }
  .courses-main__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }

.courses-item {
  background-color: #fff;
  width: 100%;
  position: relative;
  margin-bottom: 15px; }
  .courses-item::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px solid rgba(0, 0, 0, 0);
    pointer-events: none; }
  .courses-item:last-child {
    margin-bottom: 0; }
  .courses-item.is-active::before {
    border-color: #0099ff; }
  .courses-item__top {
    display: flex;
    align-items: flex-start;
    padding: 45px 24px 40px 50px; }
  .courses-item__main {
    width: 100%;
    padding-right: 72px; }
  .courses-item__header {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 12px;
    line-height: normal; }
  .courses-item__profile {
    border: 1px solid #0099ff;
    color: #0099ff;
    text-transform: uppercase;
    padding: 4px 10px;
    margin-right: 24px; }
  .courses-item__level {
    text-transform: uppercase;
    margin-left: 22px;
    color: #fff;
    background-color: #0099ff;
    padding: 4px 12px; }
  .courses-item__review-block {
    display: flex;
    align-items: center;
    margin-left: 17px; }
    .courses-item__review-block img {
      margin-right: 9px; }
  .courses-item__company-img {
    max-width: 90px;
    max-height: 50px;
    position: absolute;
    right: 15px;
    top: 20px; }
  .courses-item__title {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 24px;
    line-height: normal;
    display: inline-block;
    transition: color .2s linear;
    margin: 20px 0; }
    .courses-item__title:hover {
      color: #0099ff; }
  .courses-item__text {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.65); }
  .courses-item__bot {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eaeaea; }
  .courses-item__bot-icon-block {
    margin-right: 15px;
    margin-top: 5px;
    flex-shrink: 0; }
    .courses-item__bot-icon-block svg {
      max-width: 15px;
      max-height: 20px;
      fill: rgba(0, 0, 0, 0.63); }
    .courses-item__bot-icon-block img {
      max-width: 18px;
      max-height: 18px; }
  .courses-item__bot-text-block {
    display: flex;
    flex-direction: column; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .courses-item__bot-text-block {
        width: 100%; } }
  .courses-item__bot-text {
    color: rgba(0, 0, 0, 0.63); }
  .courses-item a.courses-item__bot-text {
    font-family: "OpenSans-Bold", sans-serif;
    color: #000;
    transition: .2s linear;
    transition-property: color; }
    .courses-item a.courses-item__bot-text:hover {
      color: #0099ff; }
  .courses-item__bot-item {
    font-size: 14px;
    line-height: 24px;
    padding: 30px 42px;
    display: flex;
    border-right: 1px solid #eaeaea; }
    .courses-item__bot-item:last-child {
      border: none; }
    .courses-item__bot-item:first-child, .courses-item__bot-item:last-child, .courses-item__bot-item:nth-child(2) {
      flex-shrink: 0; }
    .courses-item__bot-item--links {
      display: block; }
      .courses-item__bot-item--links .courses-item__bot-text-block {
        display: flex;
        align-items: center;
        flex-direction: row; }
      .courses-item__bot-item--links img {
        margin-right: 19px; }
      .courses-item__bot-item--links a.courses-item__bot-text {
        color: #0099ff; }
        .courses-item__bot-item--links a.courses-item__bot-text:hover {
          color: #000; }

.courses-slider {
  position: relative;
  width: 168px;
  flex-shrink: 0;
  margin-right: 43px; }
  .courses-slider__item {
    width: 100%;
    height: 168px; }
  .courses-slider .img-wrapper {
    width: 100%;
    height: 100%; }
  .courses-slider .default-arrow {
    position: absolute;
    bottom: 12px;
    z-index: 1; }
    .courses-slider .default-arrow--next {
      left: 47px; }
    .courses-slider .default-arrow--prev {
      left: 17px; }

.courses-aside {
  width: 364px;
  flex-shrink: 0;
  margin-left: 30px; }
  .courses-aside__title {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 24px;
    line-height: normal;
    margin-bottom: 25px; }
  .courses-aside .cat-block {
    margin-bottom: 55px; }

@media (max-width: 1600px) {
  .courses-item__top {
    padding: 30px 20px 30px 20px; }
  .courses-item__bot-item {
    padding: 25px; }
  .courses-slider {
    margin-right: 30px; }
  .courses-aside {
    margin-left: 20px; }
  .courses-item__bot-item {
    padding: 15px; } }

@media (max-width: 1279px) {
  .courses__grid {
    flex-direction: column; }
  .courses .sorting-block .sorting-select__list {
    left: auto;
    right: 0; }
  .courses-item__bot-item {
    align-items: center; }
  .courses-item__bot-item--address {
    flex: 1;
    justify-content: center; }
  .courses-aside {
    width: 100%;
    order: -1;
    margin-left: 0; }
    .courses-aside__bot-title {
      display: none; }
    .courses-aside .training-item {
      display: none; }
    .courses-aside .cat-block {
      margin-bottom: 40px; } }

@media (max-width: 900px) {
  .courses-item__main {
    padding-right: 0; }
  .courses-item__header {
    flex-wrap: wrap;
    max-width: 220px; }
  .courses-item__bot {
    flex-wrap: wrap; }
  .courses-item__bot-item {
    width: 50%; }
    .courses-item__bot-item:nth-child(1), .courses-item__bot-item:nth-child(2) {
      border-bottom: 1px solid #eaeaea; }
    .courses-item__bot-item:nth-child(2) {
      border-right: none; }
  .courses-item__profile, .courses-item__rate, .courses-item__review-block, .courses-item__level {
    margin: 10px 20px 10px 0; }
  .courses-item__profile {
    order: -3; }
  .courses-item__level {
    order: -2; } }

@media (max-width: 639px) {
  .courses {
    padding: 40px 0; }
  .courses-aside__title {
    font-size: 18px; }
  .courses-item__title {
    font-size: 18px; }
  .courses-item__top {
    display: block; }
  .courses-item__header {
    max-width: none; }
  .courses-item__company-img {
    display: none; }
  .courses-item__main {
    margin-top: 25px; }
  .courses-slider {
    margin: auto; } }

@media (max-width: 575px) {
  .courses-item__bot-item {
    width: 100%;
    border: none;
    border-bottom: 1px solid #eaeaea; }
    .courses-item__bot-item:nth-child(1), .courses-item__bot-item:nth-child(2) {
      border-bottom: 1px solid #eaeaea; }
    .courses-item__bot-item:nth-child(2) {
      border-right: none; } }

@media (max-width: 479px) {
  .courses__title {
    margin-right: 0;
    margin-bottom: 15px; }
  .courses .sorting-block .sorting-select__list {
    left: 0;
    right: auto; }
  .courses-main__header {
    display: block; } }

.footer {
  background-color: #181818;
  color: #fff;
  padding: 94px 0 100px; }
  .footer__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  .footer__logo {
    flex-shrink: 0; }
  .footer__tools {
    display: none; }
    .footer__tools .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(236, 236, 236, 0.12);
      margin-right: 6px; }
      .footer__tools .icon:last-child {
        margin-right: 0; }
  .footer__text-block {
    max-width: 374px;
    width: 100%;
    padding: 0 10px; }
  .footer__text {
    font-size: 12px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.26); }
  .footer__agreement {
    color: #0099ff;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    margin-top: 27px;
    transition: color .2s linear; }
    .footer__agreement:hover {
      color: #fff; }
  .footer__copy {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.26);
    margin-top: 20px; }
  .footer__nav {
    column-count: 2;
    max-width: 470px;
    padding-right: 10px;
    width: 100%;
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 13px;
    line-height: 1.2;
    text-transform: uppercase; }
  .footer__nav-link {
    transition: color .2s linear; }
    .footer__nav-link:hover {
      color: #0099ff; }
  .footer__nav-item {
    margin-bottom: 20px; }
  .footer__work-text {
    font-size: 12px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.26);
    text-align: left;
    margin-bottom: 12px; }
  .footer__work {
    text-align: right; }
    .footer__work a {
      display: inline-block; }
  .footer__work-copy {
    display: none;
    font-size: 12px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.26); }

@media (max-width: 1199px) {
  .footer {
    padding: 50px 0; }
    .footer__text-block {
      max-width: 330px; } }

@media (max-width: 991px) {
  .footer__grid {
    flex-wrap: wrap; }
  .footer__text-block {
    order: 1;
    width: calc(100% - 175px);
    max-width: none; }
  .footer__work {
    order: 2; }
  .footer__nav {
    width: calc(100% - 175px);
    max-width: none;
    padding-right: 0;
    padding-left: 50px;
    margin-bottom: 30px; } }

@media (max-width: 767px) {
  .footer__nav {
    padding-left: 20px; } }

@media (max-width: 639px) {
  .footer__nav {
    font-size: 12px; } }

@media (max-width: 575px) {
  .footer {
    padding: 45px 0 30px; }
    .footer__grid {
      flex-direction: column; }
    .footer__copy {
      display: none; }
    .footer__logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 40px; }
    .footer__tools {
      display: flex;
      align-items: center; }
    .footer__nav {
      width: 100%;
      padding-left: 0;
      margin-bottom: 25px; }
    .footer__text-block {
      width: 100%;
      padding: 0;
      margin-bottom: 30px; }
    .footer__text {
      line-height: 16px; }
    .footer__agreement {
      margin-top: 22px; }
    .footer__work {
      display: flex;
      align-items: center; }
    .footer__work-text-block {
      margin-right: 17px; }
    .footer__work-text {
      margin-bottom: 2px; }
    .footer__work-copy {
      display: block; } }

@media (max-width: 479px) {
  .footer__nav {
    column-count: unset;
    margin-bottom: 10px; } }

.franchise {
  background-color: #ececec;
  padding: 80px 0 102px; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .franchise {
      overflow: hidden; } }
  .franchise__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  .franchise__main {
    width: calc(100% - 263px);
    margin-right: 23px; }
  .franchise__aside {
    flex-shrink: 0;
    margin-top: 65px; }
    .franchise__aside a {
      display: inline-block; }
    .franchise__aside img {
      max-width: 240px; }
  .franchise__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px; }
  .franchise__upgrade {
    font-size: 14px;
    display: flex;
    align-items: center;
    margin-left: 45px;
    margin-right: auto; }
    .franchise__upgrade span {
      width: 32px;
      height: 32px;
      background-color: #fff;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 15px; }
      .franchise__upgrade span svg {
        fill: #0099ff; }
  .franchise__all-link {
    margin-left: 67px; }
  .franchise__footer-block {
    display: none; }

.franchise-slider-wrap {
  overflow: hidden; }

.franchise-slider {
  margin-left: -28px; }

.franchise-item__grid {
  background-color: #fff;
  display: flex;
  margin-left: 28px; }

.franchise-item__prev-block {
  width: 232px;
  flex-shrink: 0;
  position: relative; }

.franchise-item__prev-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover; }
  .franchise-item__prev-img.mob {
    display: none; }

.franchise-item__obj-block {
  width: 82px;
  height: 82px;
  border-radius: 50%;
  background-color: #0099ff;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  position: absolute;
  left: 24px;
  top: 17px; }

.franchise-item__obj-num {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 7px; }

.franchise-item__obj-text {
  font-family: "OpenSans-Regular", sans-serif;
  font-size: 12px;
  line-height: 1;
  color: rgba(255, 255, 255, 0.67); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .franchise-item__obj-text {
      width: 100%; } }

.franchise-item__main {
  width: 100%; }

.franchise-item__text-block {
  display: flex;
  min-height: 205px;
  padding: 35px 50px 30px 40px; }

.franchise-item__text-img-wrap {
  margin-right: 30px; }
  .franchise-item__text-img-wrap img {
    max-width: 77px;
    max-height: 79px; }

.franchise-item__title {
  font-family: "OpenSans-SemiBold", sans-serif;
  font-size: 18px;
  line-height: 24px;
  display: inline-block;
  margin-bottom: 15px; }

.franchise-item__text {
  font-size: 14px;
  line-height: 18px; }

.franchise-item__footer {
  display: flex;
  flex-wrap: wrap; }

.franchise-item__footer-item {
  width: 50%;
  min-height: 98px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px 40px;
  border: 1px solid #ececec;
  border-left: none;
  border-bottom: none; }
  .franchise-item__footer-item:nth-child(even) {
    border-right: none; }

.franchise-item__footer-item-title {
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.67);
  margin-bottom: 7px; }

.franchise-item__footer-item-value {
  font-family: "OpenSans-Bold", sans-serif;
  font-size: 18px;
  line-height: 1; }

@media (max-width: 1600px) {
  .franchise-item__prev-block {
    width: 200px; }
  .franchise-item__text-block {
    padding: 25px 25px 25px 25px; }
  .franchise-item__footer-item {
    padding: 25px 25px; } }

@media (max-width: 1400px) {
  .franchise-item__text-img-wrap {
    margin-right: 15px; }
  .franchise-item__text-block {
    padding: 15px; }
  .franchise-item__footer-item {
    padding: 15px; } }

@media (max-width: 1280px) {
  .franchise__aside {
    display: none; }
  .franchise__main {
    width: 100%;
    margin-right: 0; } }

@media (max-width: 991px) {
  .franchise__title {
    margin-right: auto; }
  .franchise__upgrade {
    display: none; }
  .franchise-item__prev-block {
    width: 230px; }
  .franchise-item__text-img-wrap {
    margin-right: 30px; }
  .franchise-item__text-block {
    padding: 30px; }
  .franchise-item__footer-item {
    padding: 30px; } }

@media (max-width: 767px) {
  .franchise {
    padding: 50px 0 47px; }
    .franchise a.franchise__all-link {
      display: none; }
    .franchise__all-link {
      margin: auto; }
    .franchise__footer-block {
      display: block;
      text-align: center;
      margin-top: 38px; }
      .franchise__footer-block a.franchise__all-link {
        display: inline-block; } }

@media (max-width: 639px) {
  .franchise-slider {
    margin-left: 0; }
    .franchise-slider .franchise-item {
      display: none; }
      .franchise-slider .franchise-item:first-child {
        display: block; }
      .franchise-slider .franchise-item__text-img-wrap {
        margin-right: 17px; }
      .franchise-slider .franchise-item__text-block {
        padding: 25px; }
      .franchise-slider .franchise-item__footer-item {
        padding: 25px 17px 25px 25px; }
      .franchise-slider .franchise-item__obj-block {
        left: 28px;
        top: 28px; }
  .franchise-item__grid {
    display: block;
    margin-left: 0; }
  .franchise-item__text-block {
    min-height: auto; }
  .franchise-item__prev-block {
    height: 300px;
    width: 100%; }
  .franchise-item__prev-img {
    display: none; }
    .franchise-item__prev-img.mob {
      display: block; } }

@media (max-width: 479px) {
  .franchise .container {
    padding: 0; }
  .franchise__title {
    padding: 0 27px; }
  .franchise-item__prev-block {
    height: 240px; }
  .franchise-item__text-block {
    align-items: center; }
  .franchise-item__text {
    display: none; }
  .franchise-item__title {
    margin-bottom: 0; } }

.header {
  color: #333;
  background-color: #fff; }
  .header.scrolled {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100; }
  .header .container {
    position: relative; }
  .header__search {
    margin-left: 72px;
    margin-right: 40px; }
    .header__search input {
      width: 170px; }
  .header__exchange-rates {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 55px; }
    .header__exchange-rates .exchange-rate {
      display: flex;
      align-items: center;
      margin-right: 35px; }
      .header__exchange-rates .exchange-rate:last-child {
        margin-right: 0; }
      .header__exchange-rates .exchange-rate__ic {
        margin-right: 12px; }
  .header__city {
    margin-right: 80px; }
  .header__tools-mob, .header__tools {
    display: inline-flex;
    align-items: center; }
    .header__tools-mob .link-pa, .header__tools .link-pa {
      display: flex;
      align-items: center;
      color: #0099ff;
      margin-right: 24px;
      transition: color .2s linear; }
      .header__tools-mob .link-pa:hover, .header__tools .link-pa:hover {
        color: #333; }
      .header__tools-mob .link-pa .avatar, .header__tools .link-pa .avatar {
        flex-shrink: 0;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 20px;
        overflow: hidden; }
        .header__tools-mob .link-pa .avatar img, .header__tools .link-pa .avatar img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    .header__tools-mob .icon-link, .header__tools .icon-link {
      margin-right: 7px; }
      .header__tools-mob .icon-link:last-child, .header__tools .icon-link:last-child {
        margin-right: 0; }
      .header__tools-mob .icon-link:hover .text, .header__tools .icon-link:hover .text {
        color: #0099ff; }
      .header__tools-mob .icon-link .text, .header__tools .icon-link .text {
        display: none;
        transition: color .2s linear; }
  .header__tools-mob {
    display: none; }
  .header .lang-toggle {
    margin-left: auto; }

.header-top {
  display: flex;
  align-items: center;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 16px;
  position: relative; }
  .header-top::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -50vw;
    width: 200vw;
    height: 1px;
    background-color: #ececec; }

.header-bot {
  position: relative;
  display: flex;
  align-items: center;
  padding: 22px 0;
  font-size: 13px; }
  .header-bot .hamburger {
    margin-right: 32px; }

.header-nav-drop {
  font-size: 13px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 0px 20px 15px 72px;
  opacity: 0;
  visibility: hidden;
  transition: .2s linear;
  transition-property: opacity, visibility;
  z-index: 2; }
  .header-nav-drop.is-active {
    visibility: visible;
    opacity: 1; }

.header-nav-mob,
.header-nav {
  font-family: "OpenSans-Bold", sans-serif;
  text-transform: uppercase; }
  .header-nav-mob a,
  .header-nav a {
    margin-right: 40px;
    transition: .2s linear;
    transition-property: color; }
    .header-nav-mob a:hover,
    .header-nav a:hover {
      color: #0099ff; }
    .header-nav-mob a:last-child,
    .header-nav a:last-child {
      margin-right: 0; }

.search-block__btn-toggle {
  display: none; }

.search-block__form {
  display: flex;
  align-items: center; }

.search-block button {
  background: none;
  padding: 0;
  margin-right: 22px;
  cursor: pointer; }

.search-block input {
  font-size: inherit;
  height: 30px;
  padding: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0); }
  .search-block input:focus {
    border-color: #939393; }

.lang-toggle {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-family: "OpenSans-SemiBold", sans-serif; }
  .lang-toggle:hover .text {
    color: #0099ff; }
  .lang-toggle.is-active .arrow {
    transform: rotateX(180deg); }
  .lang-toggle.is-active .lang-list, .lang-toggle.is-active .cities-list {
    visibility: visible; }
    .lang-toggle.is-active .lang-list a, .lang-toggle.is-active .cities-list a {
      opacity: 1;
      transform: translateY(0); }
  .lang-toggle .text {
    text-transform: uppercase;
    transition: color .2s linear; }
  .lang-toggle .arrow {
    fill: rgba(0, 0, 0, 0.9);
    margin-left: 8px;
    transition: .2s linear;
    transition-property: transform;
    opacity: .36;
    pointer-events: none; }

.lang-list, .cities-list {
  display: block;
  width: 40px;
  max-height: 252px;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
  margin: 0;
  margin-top: 15px;
  list-style: none;
  box-shadow: 0 5px 20px 0 rgba(36, 47, 83, 0.1);
  visibility: hidden;
  transition: visibility .2s linear;
  overflow: auto;
  z-index: 6;
  scrollbar-color: #0099ff #ececec;
  scrollbar-width: thin; }
  .lang-list::-webkit-scrollbar, .cities-list::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #ececec; }
  .lang-list::-webkit-scrollbar-thumb:vertical, .cities-list::-webkit-scrollbar-thumb:vertical {
    background-color: #0099ff;
    border-radius: 2px; }
  .lang-list::-webkit-scrollbar-thumb:horizontal, .cities-list::-webkit-scrollbar-thumb:horizontal {
    background-color: #0099ff;
    border-radius: 2px; }
  .lang-list li, .cities-list li {
    overflow: hidden; }
  .lang-list a, .cities-list a {
    position: relative;
    display: block;
    padding: 8px 10px;
    text-decoration: none;
    background-color: #fff;
    transform: translateY(-15px);
    opacity: 0;
    transition: .2s linear;
    transition-property: opacity, transform; }
    .lang-list a:hover, .cities-list a:hover {
      background-color: #ececec; }

.city-box {
  position: relative;
  cursor: pointer;
  color: #333; }
  .city-box.is-active .arrow svg {
    transform: rotateX(180deg); }
  .city-box.is-active .cities-list {
    visibility: visible; }
    .city-box.is-active .cities-list a {
      opacity: 1;
      transform: translateY(0); }

.city-toggle {
  display: inline-flex;
  align-items: center; }
  .city-toggle .icon {
    margin-right: 20px; }
  .city-toggle .text {
    color: #0099ff;
    transition: color .2s linear; }
  .city-toggle .arrow {
    padding-bottom: 2px; }
    .city-toggle .arrow svg {
      fill: #000;
      margin-left: 8px;
      transition: .2s linear;
      transition-property: transform;
      opacity: .36; }

.cities-list {
  width: auto;
  left: auto;
  right: 0;
  transform: none; }

.hamburger {
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer; }
  .hamburger:hover span {
    background-color: #018ae6; }
  .hamburger.is-active span {
    width: calc(100% + 2px);
    left: 50%; }
    .hamburger.is-active span:nth-child(1) {
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg); }
    .hamburger.is-active span:nth-child(2) {
      width: calc(100% + 2px);
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg); }
  .hamburger span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 3px;
    background-color: #010101;
    transition: .2s linear;
    transition-property: background-color; }
    .hamburger span:nth-child(1) {
      top: calc(50% - 4px); }
    .hamburger span:nth-child(2) {
      width: 55%;
      top: calc(50% + 1px); }

.hamburger-mob {
  width: 32px;
  height: 32px;
  transition: .2s linear;
  transition-property: background-color; }
  .hamburger-mob:hover {
    background-color: #66c2ff; }
    .hamburger-mob:hover span {
      background-color: #010101; }
  .hamburger-mob.is-active span {
    width: calc(56.25% + 2px); }
    .hamburger-mob.is-active span:nth-child(2) {
      width: calc(56.25% + 2px); }
  .hamburger-mob span {
    width: 56.25%;
    left: 7px; }
    .hamburger-mob span:nth-child(2) {
      width: 31.25%; }

@media (max-width: 1440px) {
  .search-block__btn-toggle {
    display: block; }
  .search-block__form {
    position: fixed;
    top: 200px;
    left: 50vw;
    z-index: 100;
    transform: translate(-50%, -50px);
    visibility: hidden;
    opacity: 0;
    transition: .25s linear;
    transition-property: opacity, visibility, transform;
    background: #fff;
    padding: 10px 20px;
    border: 2px solid #0099ff; }
    .search-block__form.is-active {
      transform: translate(-50%, 0);
      opacity: 1;
      visibility: visible; }
    .search-block__form button {
      margin-left: 20px;
      order: 1; }
  .search-block button {
    margin-right: 0; }
  .search-block input {
    width: 240px; } }

@media (max-width: 1279px) {
  .header__search {
    margin-left: 60px; }
  .header__city {
    margin-right: 60px; }
  .header-nav a {
    margin-right: 20px; } }

@media (max-width: 1199px) {
  .header .container {
    padding: 0; }
  .header__exchange-rates {
    margin-right: auto; }
  .header__city {
    margin-right: 0;
    margin-left: 30px; }
  .header .lang-toggle {
    margin: 0 30px; }
  .header__tools {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 30px 20px;
    background-color: #fff;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: .25s linear;
    transition-property: opacity, visibility; }
    .header__tools.is-active {
      opacity: 1;
      visibility: visible; }
    .header__tools .icon-link {
      display: inline-flex;
      align-items: center;
      margin-top: 15px; }
      .header__tools .icon-link .icon {
        margin-left: 2px;
        margin-right: 22px; }
      .header__tools .icon-link .text {
        display: inline-block; }
  .header__tools-mob {
    display: inline-flex; }
    .header__tools-mob > * {
      margin-right: 6px; }
      .header__tools-mob > *:last-child {
        margin-right: 0; }
  .header__search {
    margin-left: 0; }
  .header-bot {
    display: none; }
  .header-top {
    padding: 15px 20px; }
  .hamburger-mob {
    margin: 0; }
  .hamburger-mob-box {
    position: absolute;
    top: 100%;
    right: 0;
    width: 500px;
    padding: 30px 20px;
    background-color: #fff;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    transition: .25s linear;
    transition-property: opacity, visibility;
    overflow: auto; }
    .hamburger-mob-box.is-active {
      opacity: 1;
      visibility: visible; }
  .header-nav-mob {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .header-nav-mob a {
      width: calc(50% - 20px);
      margin-right: 0; }
  .search-block__form {
    top: 110px; } }

@media (max-width: 991px) {
  .header .logo {
    margin-right: auto; }
  .header__exchange-rates {
    margin: 0;
    margin-bottom: 20px; } }

@media (max-width: 767px) {
  .header-top {
    padding-left: 27px;
    padding-right: 27px; }
  .header .lang-toggle {
    margin: 0;
    margin-right: 40px;
    margin-bottom: 20px;
    vertical-align: middle; }
  .header__city {
    display: inline-block;
    margin: 0;
    margin-bottom: 20px;
    vertical-align: middle; }
  .hamburger-mob-box {
    padding: 30px 27px; }
  .header-nav-mob {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .header-nav-mob a {
      width: auto; } }

@media (max-width: 575px) {
  .header__exchange-rates {
    display: block; }
    .header__exchange-rates .exchange-rate {
      margin-bottom: 6px;
      margin-right: 0; }
      .header__exchange-rates .exchange-rate:last-child {
        margin: 0; }
  .hamburger-mob-box {
    width: 280px; } }

@media (max-width: 400px) {
  .search-block input {
    width: 200px; } }

.hero {
  position: relative;
  color: #fff;
  background-color: #000; }
  .hero .container {
    position: relative;
    padding-top: 110px;
    padding-bottom: 40px;
    z-index: 1; }
  .hero .default-arrow svg {
    fill: #fff; }
  .hero .slick-dots button {
    background-color: #686868; }

.hero-bg-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; }
  .hero-bg-slider .hero-bg {
    width: 100%;
    height: 100%; }
    .hero-bg-slider .hero-bg span {
      display: block;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      z-index: -1;
      opacity: .75; }
  .hero-bg-slider::after, .hero-bg-slider::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), black);
    z-index: 1; }
  .hero-bg-slider::before {
    z-index: -2;
    background-image: none;
    background-color: #000; }

.hero-top {
  display: flex;
  padding-bottom: 46px;
  margin-bottom: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.14); }

.hero-articles-slider {
  width: 850px; }

.article-item__tags {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase; }
  .article-item__tags span {
    color: #fff;
    background-color: #0099ff;
    padding: 9px 12px 7px;
    margin-right: 7px; }
    .article-item__tags span:last-child {
      margin-right: 0; }

.article-item__title {
  line-height: 1; }
  .article-item__title .icon {
    display: none; }

.article-item__footer {
  display: flex;
  align-items: center;
  margin-top: 22px; }
  .article-item__footer > * {
    margin-right: 25px; }
    .article-item__footer > *:last-child {
      margin-right: 0; }
  .article-item__footer .icon {
    margin-right: 14px;
    font-size: 0;
    line-height: 1; }
    .article-item__footer .icon svg {
      fill: #fff; }

.article-item__more {
  display: flex;
  align-items: center; }

.article-item__published {
  display: flex;
  align-items: center; }

.article-item__comments {
  display: flex;
  align-items: center; }

.article-item__rating {
  margin-left: auto; }

.hero-articles-rb {
  max-width: 460px;
  margin-left: auto; }

.hero-articles-nav {
  display: flex;
  align-items: center;
  padding-left: 90px;
  margin-top: 40px; }

.interview-nav-tools,
.hero-articles-nav-tools {
  margin-right: 45px; }

.interview-nav-slider,
.hero-articles-nav-slider {
  max-width: 386px; }
  .interview-nav-slider .interview-sl-item,
  .interview-nav-slider .hero-articles-item,
  .hero-articles-nav-slider .interview-sl-item,
  .hero-articles-nav-slider .hero-articles-item {
    position: relative;
    width: 122px;
    height: 70px;
    background-color: #000;
    margin-right: 10px; }
    .interview-nav-slider .interview-sl-item.slick-active .svg-timer,
    .interview-nav-slider .hero-articles-item.slick-active .svg-timer,
    .hero-articles-nav-slider .interview-sl-item.slick-active .svg-timer,
    .hero-articles-nav-slider .hero-articles-item.slick-active .svg-timer {
      display: block; }
    .interview-nav-slider .interview-sl-item.slick-active .line-fill,
    .interview-nav-slider .hero-articles-item.slick-active .line-fill,
    .hero-articles-nav-slider .interview-sl-item.slick-active .line-fill,
    .hero-articles-nav-slider .hero-articles-item.slick-active .line-fill {
      animation-play-state: running; }
    .interview-nav-slider .interview-sl-item a,
    .interview-nav-slider .hero-articles-item a,
    .hero-articles-nav-slider .interview-sl-item a,
    .hero-articles-nav-slider .hero-articles-item a {
      display: flex;
      width: 100%;
      height: 100%; }
    .interview-nav-slider .interview-sl-item .bg,
    .interview-nav-slider .hero-articles-item .bg,
    .hero-articles-nav-slider .interview-sl-item .bg,
    .hero-articles-nav-slider .hero-articles-item .bg {
      display: block;
      width: 100%;
      height: 100%;
      background: center/cover no-repeat;
      opacity: .54; }
    .interview-nav-slider .interview-sl-item .svg-timer,
    .interview-nav-slider .hero-articles-item .svg-timer,
    .hero-articles-nav-slider .interview-sl-item .svg-timer,
    .hero-articles-nav-slider .hero-articles-item .svg-timer {
      display: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-90deg);
      z-index: 2;
      pointer-events: none; }
    .interview-nav-slider .interview-sl-item .line-fill,
    .interview-nav-slider .hero-articles-item .line-fill,
    .hero-articles-nav-slider .interview-sl-item .line-fill,
    .hero-articles-nav-slider .hero-articles-item .line-fill {
      animation: animateOffset 8s linear forwards;
      animation-play-state: paused; }

@keyframes animateOffset {
  0% {
    stroke-dashoffset: 106; }
  100% {
    stroke-dashoffset: 0; } }

.hero-article-sm {
  display: inline-block; }
  .hero-article-sm.inverted .img {
    order: 1;
    margin-right: 0;
    margin-left: 50px; }
  .hero-article-sm a {
    display: flex;
    align-items: center; }
  .hero-article-sm .img {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    width: 170px;
    height: 190px;
    margin-right: 50px;
    border-bottom-left-radius: 85px;
    border-bottom-right-radius: 85px;
    overflow: hidden; }
    .hero-article-sm .img::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #0099ff;
      width: 170px;
      height: 170px;
      border-radius: 50%; }
    .hero-article-sm .img img {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto; }
  .hero-article-sm .text {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 22px;
    line-height: 28px;
    max-width: 210px; }

.last-news-header {
  display: flex;
  align-items: baseline;
  margin-bottom: 46px; }
  .last-news-header h2 {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 24px;
    line-height: normal;
    margin-right: 32px; }
  .last-news-header .last-news-tags {
    font-family: "OpenSans-SemiBold", sans-serif;
    text-transform: uppercase;
    font-size: 12px;
    color: #939393; }
    .last-news-header .last-news-tags span {
      margin-right: 25px; }
      .last-news-header .last-news-tags span:last-child {
        margin-right: 0; }

.last-news-slider .last-news-item {
  display: flex; }

.last-news-item {
  width: 232px;
  height: 258px;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  margin-right: 32px;
  padding: 26px; }
  .last-news-item:hover::after {
    opacity: 1; }
  .last-news-item::after, .last-news-item::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 45%, black);
    opacity: .65; }
  .last-news-item::after {
    opacity: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2), black);
    transition: opacity .25s linear; }
  .last-news-item__title {
    position: relative;
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 24px;
    z-index: 1; }

@media (max-width: 1365px) {
  .hero-articles-slider {
    width: 650px; }
  .article-item__title {
    font-size: 50px; }
    .article-item__title br {
      display: none; } }

@media (max-width: 1199px) {
  .hero-top {
    display: block; }
  .hero-article-sm {
    display: none; }
  .hero-articles-slider {
    width: 100%;
    margin-bottom: 50px; }
  .hero-articles-rb {
    margin-left: 0; }
  .hero-articles-nav-slider {
    display: none; }
  .hero-articles-nav {
    padding-left: 0;
    margin-top: 0; }
  .last-news-slider {
    margin-left: -20px;
    margin-right: -20px; }
    .last-news-slider .slick-list {
      padding: 0 20px; } }

@media (max-width: 991px) {
  .hero .container {
    padding-top: 150px;
    padding-bottom: 30px; }
  .article-item__title {
    font-size: 42px;
    line-height: 1.2; } }

@media (max-width: 767px) {
  .last-news-header {
    display: block;
    margin-bottom: 35px; }
    .last-news-header h2 {
      font-size: 18px;
      margin-right: 0;
      margin-bottom: 12px; }
    .last-news-header .last-news-tags {
      margin-right: -20px;
      font-size: 11px;
      overflow: auto; } }

@media (max-width: 640px) {
  .hero-top {
    margin-bottom: 35px; }
  .article-item {
    font-size: 10px; }
    .article-item__title {
      font-size: 36px; }
      .article-item__title .icon {
        display: inline-flex;
        vertical-align: middle;
        margin-left: 10px; }
    .article-item__tags {
      margin-bottom: 30px; }
      .article-item__tags span {
        padding: 6px 10px; }
    .article-item__more {
      display: none; } }

.rent {
  padding: 79px 0 69px; }
  .rent__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px; }
  .rent__grid {
    display: flex; }
  .rent__ctrl-block {
    flex-shrink: 0; }
  .rent__all-link {
    flex-shrink: 0;
    margin-left: 67px; }
  .rent__footer {
    display: none;
    text-align: center; }

.rent-item {
  width: calc((100% - 150px)/6);
  background-color: #f2f2f2;
  margin-right: 30px; }
  .rent-item:last-child {
    margin-right: 0; }
  .rent-item__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .rent-item__inner {
        display: block; } }
  .rent-item__img-block {
    display: inline-block;
    width: 100%;
    height: 145px;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0; }
  .rent-item__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 30px 27px; }
  .rent-item__class-block {
    position: absolute;
    right: 15px;
    top: 10px;
    background-color: #0099ff;
    padding: 5px 7px 5px 10px; }
  .rent-item__class {
    font-size: 12px;
    line-height: 14px;
    color: #fff; }
  .rent-item__title {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 18px;
    margin-bottom: 10px; }
  .rent-item__text {
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.65);
    margin-bottom: 10px; }
  .rent-item__value {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-top: auto; }

@media (max-width: 1600px) {
  .rent-item {
    width: calc((100% - 100px)/6);
    margin-right: 20px; }
    .rent-item__content {
      padding: 25px 20px; } }

@media (max-width: 1400px) {
  .rent-item {
    width: calc((100% - 75px)/6);
    margin-right: 15px; } }

@media (max-width: 1280px) {
  .rent .container {
    padding-right: 0; }
  .rent__title {
    margin-right: auto; }
  .rent-item {
    width: 240px;
    min-height: 320px; }
    .rent-item__inner {
      min-height: 320px; } }

@media (max-width: 767px) {
  .rent {
    padding: 40px 0 47px; }
    .rent__header {
      padding-right: 27px; }
    .rent a.rent__all-link {
      display: none; }
    .rent__footer {
      display: block; }
      .rent__footer a.rent__all-link {
        display: inline-block;
        margin-top: 28px;
        margin-left: 0; } }

@media (max-width: 575px) {
  .rent__ctrl-block.slider-ctrl {
    display: none; }
  .rent__header {
    display: block; }
  .rent__title {
    margin-right: 0; } }

@media (max-width: 479px) {
  .rent__header {
    text-align: center; }
  .rent-item {
    width: 232px;
    min-height: 330px; }
    .rent-item__inner {
      min-height: 330px; }
    .rent-item__content {
      padding: 27px; } }

.sale-contracts {
  background-color: #ececec;
  padding: 22px 0 25px; }
  .sale-contracts__header {
    display: flex;
    align-items: center;
    margin-bottom: 41px; }
  .sale-contracts__title {
    margin-right: 20px; }
  .sale-contracts__grid {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }
  .sale-contracts__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }
  .sale-contracts__aside-link {
    display: inline-block;
    flex-shrink: 0;
    margin-left: 120px; }
    .sale-contracts__aside-link img {
      max-width: 334px; }
  .sale-contracts__footer {
    display: none; }

.sale-contract {
  max-width: 283px;
  width: 100%;
  margin-bottom: 45px;
  margin-right: 20px; }
  .sale-contract__grid {
    display: flex; }
  .sale-contract .img-wrapper {
    max-width: 102px;
    max-height: 150px;
    flex-shrink: 0;
    margin-right: 28px; }
  .sale-contract__title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px; }
  .sale-contract__price {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 16px;
    line-height: normal;
    margin-bottom: 15px; }
  .sale-contract__link {
    display: inline-block; }
    .sale-contract__link .icon {
      background-color: #fff; }
      .sale-contract__link .icon svg {
        fill: #0099ff; }

@media (max-width: 1600px) {
  .sale-contracts__aside-link {
    margin-left: 40px; } }

@media (max-width: 1400px) {
  .sale-contracts__aside-link {
    margin-left: 20px; }
  .sale-contract {
    max-width: 278px;
    margin-right: 15px; }
    .sale-contract .img-wrapper {
      margin-right: 15px; } }

@media (max-width: 1280px) {
  .sale-contracts {
    padding-bottom: 40px; }
    .sale-contracts__aside-link {
      margin-left: 30px; }
    .sale-contracts__grid {
      align-items: center;
      position: relative; }
    .sale-contracts__list {
      width: calc(100% - 364px); }
    .sale-contracts .default-arrow {
      width: 27px;
      height: 27px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 1; }
      .sale-contracts .default-arrow--prev {
        left: 0; }
      .sale-contracts .default-arrow--next {
        right: 0; }
      .sale-contracts .default-arrow svg {
        fill: #0099ff; }
  .sale-contract {
    margin-right: 0;
    max-width: none;
    margin-bottom: 0; }
    .sale-contract__grid {
      align-items: center;
      max-width: 280px;
      margin: auto; }
    .sale-contract__link {
      display: none; }
    .sale-contract__price {
      margin-bottom: 0; } }

@media (max-width: 991px) {
  .sale-contracts__list {
    width: 100%;
    max-width: 320px;
    margin: auto; }
  .sale-contract__grid {
    display: block;
    text-align: center; }
  .sale-contract .img-wrapper {
    margin-right: 0;
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  .sale-contracts {
    text-align: center; }
    .sale-contracts__header {
      display: block; }
    .sale-contracts__title {
      margin-right: 0; }
    .sale-contracts__grid {
      display: block; }
    .sale-contracts a.sale-contracts__all-link {
      display: none; }
    .sale-contracts__footer {
      display: block; }
      .sale-contracts__footer a.sale-contracts__all-link {
        display: inline-block;
        margin-top: 22px;
        margin-left: 0; }
    .sale-contracts__aside-link {
      margin: 47px auto 0; } }

@media (max-width: 479px) {
  .sale-contracts .default-arrow {
    transform: none;
    top: 30px; }
    .sale-contracts .default-arrow--prev {
      left: 20px; }
    .sale-contracts .default-arrow--next {
      right: 20px; }
  .sale-contract .img-wrapper {
    max-width: 62px;
    max-height: 88px; }
  .sale-contract__grid {
    max-width: 200px; } }

@media (max-width: 400px) {
  .sale-contracts {
    padding-bottom: 0; }
    .sale-contracts .container {
      padding: 0; }
    .sale-contracts__aside-link {
      width: 100%;
      background-color: #fff; }
      .sale-contracts__aside-link img {
        max-width: none;
        width: 100%; } }

.interview-slider-mob {
  display: none; }

.interview {
  background-image: linear-gradient(to bottom, white 0%, #ececec 100%); }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .interview {
      overflow: hidden; } }
  .interview .container {
    display: flex;
    position: relative;
    z-index: 1; }
  .interview .section-title {
    font-family: "OpenSans-SemiBold", sans-serif;
    font-size: 24px;
    margin-bottom: 90px; }
    .interview .section-title.mob {
      display: none; }

.one-slide .interview-text {
  max-width: 750px; }

.one-slide .interview-item__quote {
  max-width: 600px; }

.one-slide .interview-other {
  display: none; }

.interview-text {
  max-width: 410px;
  padding-top: 84px;
  padding-bottom: 144px;
  margin-right: 100px; }

.interview-item {
  display: none; }
  .interview-item.is-active {
    display: block; }
  .interview-item__name {
    display: inline-block;
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 12px;
    line-height: 1;
    text-transform: uppercase;
    color: #fff;
    background-color: #333;
    padding: 8px 12px;
    margin-bottom: 30px;
    position: relative; }
    .interview-item__name::before {
      content: attr(data-name);
      font-family: "OpenSans-ExtraBold", sans-serif;
      white-space: nowrap;
      font-size: 202px;
      letter-spacing: -.022em;
      text-transform: uppercase;
      color: #ececec;
      position: absolute;
      top: -60px;
      left: -10px;
      z-index: -1; }
  .interview-item__quote {
    font-family: "OpenSans-Light", sans-serif;
    font-size: 44px;
    line-height: 57px;
    max-width: 380px;
    margin-bottom: 35px; }
  .interview-item__quote-inner {
    position: relative; }
    .interview-item__quote-inner::before {
      content: attr(data-before);
      position: absolute;
      top: 0;
      right: calc(100% + 18px); }
    .interview-item__quote-inner::after {
      content: attr(data-after);
      margin-left: 18px; }
  .interview-item__link {
    display: inline-flex;
    align-items: center;
    color: #0099ff;
    font-size: 14px;
    line-height: 1;
    font-family: "OpenSans-SemiBold", sans-serif; }
    .interview-item__link:hover .icon {
      background-color: #fff; }
      .interview-item__link:hover .icon svg {
        fill: #0099ff; }
    .interview-item__link .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 52px;
      height: 52px;
      padding-left: 3px;
      border-radius: 50%;
      background-color: #0099ff;
      border: 1px solid #0099ff;
      margin-right: 23px;
      transition: .2s linear;
      transition-property: background-color; }
      .interview-item__link .icon svg {
        fill: #fff;
        transition: fill .2s linear; }

.interview-photo {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 530px;
  padding-top: 50px; }
  .interview-photo:before {
    content: '';
    position: absolute;
    bottom: 117px;
    left: 50%;
    transform: translateX(-50%);
    width: 530px;
    height: 530px;
    border-radius: 50%;
    background-color: #0099ff; }
  .interview-photo__img {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto; }
    .interview-photo__img img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto; }

.interview-other {
  display: flex;
  flex-direction: column;
  width: 370px;
  margin-left: auto;
  padding-top: 137px;
  padding-bottom: 48px; }

.interview-item-sm {
  margin-bottom: 50px; }
  .interview-item-sm.hidden {
    display: none; }
  .interview-item-sm.inverted .img {
    order: 1;
    margin-left: 33px;
    margin-right: 0; }
  .interview-item-sm a {
    display: flex;
    align-items: center; }
  .interview-item-sm .img {
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    width: 170px;
    height: 190px;
    margin-right: 33px;
    border-bottom-left-radius: 85px;
    border-bottom-right-radius: 85px;
    overflow: hidden; }
    .interview-item-sm .img::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #0099ff;
      width: 170px;
      height: 170px;
      border-radius: 50%; }
    .interview-item-sm .img img {
      position: relative;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto; }
  .interview-item-sm .text {
    font-family: "OpenSans-Regular", sans-serif;
    font-size: 18px;
    line-height: 24px;
    max-width: 155px; }

.interview-nav {
  display: flex;
  align-items: center; }
  .interview-nav.hidden {
    display: none; }

@media (max-width: 1650px) {
  .interview-text {
    width: 415px;
    max-width: none;
    flex-shrink: 0; }
  .interview-item__quote {
    max-width: 415px;
    margin-left: 35px; } }

@media (max-width: 1500px) {
  .interview-text {
    margin-right: 50px; }
  .interview-item__name:before {
    font-size: 12vw; }
  .interview-photo {
    margin: 0 auto; }
  .interview-other {
    width: 330px;
    margin-left: 50px; }
  .interview-item-sm.inverted .img {
    margin-left: 20px; }
  .interview-item-sm .img {
    width: 130px;
    height: 145px;
    margin-right: 20px; }
    .interview-item-sm .img::before {
      width: 130px;
      height: 130px; }
  .interview-item-sm .text {
    font-size: 16px;
    line-height: 22px;
    max-width: 200px; } }

@media (max-width: 1440px) {
  .interview .section-title {
    white-space: nowrap; }
  .interview-text {
    margin-right: 30px;
    padding-bottom: 90px; }
  .interview-item__quote {
    font-size: 36px;
    line-height: 50px; }
  .interview-photo {
    max-width: 400px;
    width: 100%; }
    .interview-photo::before {
      width: 400px;
      height: 400px; }
    .interview-photo__img {
      width: 85%; }
  .interview-other {
    margin-left: 30px; } }

@media (max-width: 1350px) {
  .interview-text {
    width: 360px; } }

@media (max-width: 1199px) {
  .interview-text {
    flex-shrink: 0;
    max-width: calc(100% - 400px);
    width: 50%;
    margin-right: 0;
    padding-right: 40px; }
  .interview-item__quote {
    max-width: 550px; }
  .interview-other {
    width: 50%;
    margin-left: 0;
    padding: 0;
    position: absolute;
    bottom: 30px;
    left: 20px; }
  .interview-item-sm {
    display: none; } }

@media (max-width: 900px) {
  .interview-text {
    max-width: calc(100% - 360px);
    padding-right: 20px; }
  .interview-item__quote-inner:before {
    right: calc(100% + 10px); }
  .interview-item__quote-inner:after {
    margin-left: 10px; }
  .interview-item__quote {
    font-size: 32px;
    line-height: 42px;
    margin-left: 24px; }
  .interview-item__link .icon {
    width: 46px;
    height: 46px; }
  .interview-photo {
    max-width: 360px; }
    .interview-photo::before {
      width: 360px;
      height: 360px; } }

@media (max-width: 767px) {
  .interview {
    padding-top: 45px;
    padding-bottom: 54px; }
    .interview .container {
      display: block; }
    .interview .section-title {
      display: none;
      text-align: center;
      white-space: normal;
      margin-bottom: 20px; }
      .interview .section-title.mob {
        display: block; }
  .interview-other,
  .interview-photo {
    display: none; }
  .interview-text {
    padding: 0;
    max-width: none;
    width: 100%; }
  .interview-item {
    display: block; }
    .interview-item__name:before {
      display: none; }
  .interview-slider-mob {
    display: block;
    margin-bottom: 32px; }
  .interview-slider-mob-img {
    max-width: 320px;
    margin: 0 auto; }
    .interview-slider-mob-img__item {
      opacity: .5;
      transition: opacity .3s linear; }
      .interview-slider-mob-img__item.slick-current {
        opacity: 1; }
        .interview-slider-mob-img__item.slick-current .interview-slider-mob-img__item-inner {
          transform: translateX(-50%) scale(1);
          z-index: 1; }
    .interview-slider-mob-img__item-inner {
      position: relative;
      left: 50%;
      transform: translateX(-50%) scale(0.5);
      transition: transform .3s linear;
      display: flex;
      align-items: flex-end;
      flex-shrink: 0;
      width: 170px;
      height: 190px;
      margin: 0 auto;
      border-bottom-left-radius: 85px;
      border-bottom-right-radius: 85px;
      overflow: hidden; }
      .interview-slider-mob-img__item-inner::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #0099ff;
        width: 170px;
        height: 170px;
        border-radius: 50%; }
      .interview-slider-mob-img__item-inner img {
        position: relative;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto; } }

@media (max-width: 575px) {
  .interview .section-title {
    font-size: 18px;
    line-height: normal; }
  .interview-item__name {
    padding: 6px 11px;
    margin-bottom: 25px; }
  .interview-item__quote {
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 30px; } }

.training {
  background-color: #ececec;
  padding: 74px 0 22px; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .training {
      overflow: hidden; } }
  .training__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px; }
  .training__grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -28px; }
  .training .training-item {
    width: calc((100% - 112px)/4);
    margin-left: 28px;
    margin-bottom: 28px;
    background-repeat: no-repeat;
    background-size: cover; }
    .training .training-item--wide {
      width: calc((100% - 56px)/2); }
  .training__footer {
    display: none; }

.training-item {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 364px;
  padding: 30px 30px 25px 40px; }
  .training-item__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px; }
  .training-item__profile {
    font-size: 12px;
    line-height: normal;
    text-transform: uppercase;
    color: #0099ff;
    border: 1px solid #0099ff;
    padding: 4px 10px; }
  .training-item__company {
    max-width: 150px;
    max-height: 50px; }
  .training-item__main {
    display: flex; }
  .training-item__img-wrap {
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    max-width: 100px;
    max-height: 100px;
    margin-right: 30px; }
  .training-item__content {
    max-width: 125px; }
  .training-item__title {
    font-family: "OpenSans-Bold", sans-serif;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 15px; }
  .training-item__info-time {
    display: flex;
    align-items: center; }
    .training-item__info-time svg {
      flex-shrink: 0;
      fill: #272727; }
  .training-item__time-block {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.63);
    margin-left: 12px; }
  .training-item__footer {
    display: flex;
    align-items: center;
    margin-top: auto; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .training-item__footer {
        margin-top: 30px; } }
  .training-item__reviews {
    display: flex;
    align-items: center;
    margin-left: 12px; }
    .training-item__reviews img {
      flex-shrink: 0;
      margin-right: 10px; }
  .training-item__reviews-text {
    font-size: 12px;
    line-height: normal; }
  .training-item__link {
    display: inline-block;
    margin-left: auto; }
  .training-item--alone .training-item__profile {
    background-color: #0099ff;
    color: #fff; }
  .training-item--alone .training-item__content {
    max-width: 245px;
    color: #fff; }
  .training-item--alone .training-item__title {
    font-family: "OpenSans-Light", sans-serif;
    font-size: 44px;
    line-height: 57px;
    margin: 0; }
  .training-item--alone .training-item__footer {
    color: #fff; }
  .training-item--wide {
    color: #fff; }
    .training-item--wide .training-item__header {
      margin-bottom: 65px; }
    .training-item--wide .training-item__profile {
      color: #fff;
      background-color: #0099ff; }
    .training-item--wide .training-item__content {
      max-width: 420px; }
    .training-item--wide .training-item__title {
      font-family: "OpenSans-Light", sans-serif;
      font-size: 44px;
      line-height: 57px;
      margin: 0;
      margin-bottom: 20px; }
    .training-item--wide .training-item__info-time svg {
      fill: #fff; }
    .training-item--wide .training-item__time-block {
      display: flex;
      color: #fff;
      margin-left: 16px; }
    .training-item--wide .training-item__link {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: normal;
      color: #0099ff;
      order: -1;
      margin-left: 0;
      margin-right: auto; }
      .training-item--wide .training-item__link .text {
        margin-left: 14px; }

@media (max-width: 1600px) {
  .training__grid {
    margin-left: -20px; }
  .training .training-item {
    width: calc((100% - 80px)/4);
    margin-left: 20px;
    padding: 30px 20px 25px 20px;
    margin-bottom: 20px; }
    .training .training-item--wide {
      width: calc((100% - 40px)/2); } }

@media (max-width: 1280px) {
  .training__wrap {
    max-width: 1111px;
    margin: auto; }
  .training .training-item {
    width: calc((100% - 60px)/3); }
    .training .training-item--wide {
      width: calc(100% - 100%/3 - 20px); }
    .training .training-item__img-wrap {
      margin-right: 20px; } }

@media (max-width: 991px) {
  .training__grid {
    justify-content: space-between;
    margin-left: 0; }
  .training .training-item {
    width: calc(50% - 10px);
    margin-left: 0; }
    .training .training-item--wide {
      width: 100%; }
    .training .training-item__content {
      max-width: none; } }

@media (max-width: 700px) {
  .training .training-item {
    width: calc(50% - 4px);
    margin-bottom: 8px; } }

@media (max-width: 639px) {
  .training .training-item {
    width: 100%;
    display: none;
    margin-bottom: 0;
    min-height: 300px; }
    .training .training-item:first-child, .training .training-item:nth-child(2), .training .training-item:nth-child(3) {
      display: flex; }
    .training .training-item:nth-child(2) {
      order: 2; }
    .training .training-item--alone {
      order: 1; } }

@media (max-width: 575px) {
  .training {
    padding-top: 45px; }
    .training__header {
      margin-bottom: 25px; } }

@media (max-width: 479px) {
  .training .container {
    padding: 0; }
  .training__header {
    display: block;
    text-align: center;
    padding: 0 27px; }
  .training .training-item {
    min-height: 364px;
    padding-left: 27px;
    padding-right: 27px; }
  .training a.training__all-link {
    display: none; }
  .training__footer {
    display: block;
    text-align: center; }
    .training__footer a.training__all-link {
      display: inline-block;
      margin-top: 45px;
      margin-left: 0; } }

.btn {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 5px 20px;
  border: 2px solid #0099ff;
  border-radius: 17px;
  outline: none;
  cursor: pointer;
  z-index: 1;
  background-color: #0099ff;
  transition: background-color .25s linear; }
  .btn .btn-inner {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: .25s linear;
    transition-property: color, background-color, border-color; }
  .btn:hover {
    background-color: rgba(0, 0, 0, 0); }
    .btn:hover .btn-inner {
      color: #0099ff; }

.slider-ctrl {
  display: flex;
  align-items: center; }
  .slider-ctrl .default-arrow--prev {
    order: -1; }
  .slider-ctrl .default-arrow--next {
    order: 1; }

.default-arrow {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .default-arrow svg {
    transition: fill .25s linear; }
  .default-arrow:hover svg {
    fill: #0099ff; }

.slider-dots,
.slick-dots {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 20px; }
  .slider-dots li,
  .slick-dots li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    cursor: pointer;
    margin-right: 10px;
    background: none; }
    .slider-dots li:last-child,
    .slick-dots li:last-child {
      margin-right: 0; }
    .slider-dots li.is-active button, .slider-dots li.slick-active button, .slider-dots li:hover button,
    .slick-dots li.is-active button,
    .slick-dots li.slick-active button,
    .slick-dots li:hover button {
      background-color: #0099ff; }
  .slider-dots button,
  .slick-dots button {
    font-size: 0;
    width: 6px;
    height: 6px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background-color: #bebebe;
    transition: background-color .25s linear; }

.default-checkbox {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px; }
  .default-checkbox__custom {
    position: relative;
    border: 2px solid #fff;
    border-radius: 7px;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-right: 20px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center; }
    .default-checkbox__custom svg {
      fill: none;
      transform: scale(0);
      transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28); }
  .default-checkbox__text {
    padding-top: 2px;
    text-align: left;
    transition: all 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28); }
  .default-checkbox input {
    display: none; }
    .default-checkbox input:checked ~ .default-checkbox__custom svg {
      transform: scale(1); }
    .default-checkbox input.error ~ .default-checkbox__custom {
      border-color: #FF0000; }
  .default-checkbox label.error {
    display: none !important; }

@media (max-width: 575px) {
  .default-checkbox__custom {
    width: 20px;
    height: 20px;
    border-width: 1px;
    border-radius: 5px; }
    .default-checkbox__custom svg {
      max-width: 9px;
      max-height: 8px; } }

@media (max-width: 479px) {
  .default-checkbox__custom {
    margin-right: 8px; }
  .default-checkbox__text {
    font-size: 10px;
    line-height: 15px; } }

input, textarea {
  font-family: inherit;
  color: #333;
  background-color: #fff;
  width: 100%;
  transition: border-color .2s linear; }

input[type=submit] {
  width: auto; }

input {
  height: 28px; }

textarea {
  width: 100%;
  height: 112px;
  resize: none;
  border-radius: 0; }
  @media screen and (max-width: 768px) {
    textarea {
      font-size: 12px;
      padding: 10px; } }

input, textarea {
  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  border-radius: 0; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ::-webkit-input-placeholder {
    color: rgba(64, 64, 64, 0.5); } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  ::-moz-placeholder {
    color: rgba(64, 64, 64, 0.5); } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :-moz-placeholder {
    color: rgba(64, 64, 64, 0.5); } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  :-ms-input-placeholder {
    color: rgba(64, 64, 64, 0.5); } }

.input-group {
  position: relative;
  margin-bottom: 20px;
  z-index: 1; }
  .input-group::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    opacity: 0;
    transition: .25s linear;
    transition-property: opacity;
    z-index: -1; }
  .input-group--notempty .input-group__label, .input-group--focus .input-group__label {
    top: 8px;
    font-size: 12px; }
  .input-group--focus::before {
    opacity: 1; }
  .input-group__label {
    position: absolute;
    top: 19px;
    left: 20px;
    font-size: 18px;
    line-height: 1;
    pointer-events: none;
    transition: all .25s linear;
    color: rgba(64, 64, 64, 0.5); }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .input-group__label {
        display: none; } }
  .input-group input,
  .input-group textarea {
    background-color: #fff;
    border: 1px solid #fff;
    padding: 18px 20px 6px;
    min-height: 56px; }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .input-group input,
      .input-group textarea {
        padding: 5px 20px; } }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      .input-group input:focus ~ .input-group__label, .input-group input:active ~ .input-group__label,
      .input-group textarea:focus ~ .input-group__label,
      .input-group textarea:active ~ .input-group__label {
        display: none; } }
    .input-group input.error,
    .input-group textarea.error {
      border: 1px solid #FF0000; }
      .input-group input.error ~ .input-group__label,
      .input-group textarea.error ~ .input-group__label {
        color: #ff0000; }
  .input-group label.error {
    display: none !important; }
  .input-group.input-group-textarea {
    min-height: 56px;
    height: 112px; }
    .input-group.input-group-textarea textarea {
      height: 100%;
      padding: 22px 20px 6px; }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .input-group.input-group-textarea textarea {
          padding: 12px 20px 6px; } }

@media (max-width: 991px) {
  .input-group {
    margin-bottom: 15px; }
    .input-group__label {
      top: 16px;
      left: 15px;
      font-size: 12px; }
    .input-group--focus .input-group__label, .input-group--notempty .input-group__label {
      font-size: 10px;
      top: 5px; }
    .input-group input,
    .input-group textarea {
      line-height: 18px;
      min-height: 42px;
      padding: 14px 15px 4px; }
    .input-group input {
      height: 20px; }
  .input-group.input-group-textarea textarea {
    padding: 16px 15px 4px; } }

@media (max-width: 479px) {
  .input-group input,
  .input-group textarea {
    font-size: 12px;
    line-height: 15px; }
  .input-group label.error {
    font-size: 10px; } }

.pagination {
  font-size: 14px;
  line-height: normal; }
  .pagination__list {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .pagination__item {
    margin-right: 5px; }
    .pagination__item:last-child {
      margin-right: 0; }
    .pagination__item.prev .pagination__link, .pagination__item.next .pagination__link {
      width: auto;
      height: 42px;
      padding: 2px 20px;
      position: relative; }
      .pagination__item.prev .pagination__link::before, .pagination__item.next .pagination__link::before {
        content: "";
        position: absolute;
        left: -12px;
        top: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 21px 12px 21px 0;
        border-color: transparent #fff transparent transparent;
        transition: all .2s linear; }
    .pagination__item.next .pagination__link {
      padding-right: 15px; }
      .pagination__item.next .pagination__link::before {
        left: auto;
        right: -12px;
        transform: scaleX(-1); }
    .pagination__item.prev .pagination__link {
      padding-left: 15px; }
    .pagination__item.spread {
      pointer-events: none; }
    .pagination__item:hover .pagination__link {
      color: #fff;
      background-color: #0099ff; }
      .pagination__item:hover .pagination__link::before {
        border-color: transparent #0099ff transparent transparent; }
  .pagination__link {
    background-color: #fff;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    transition: .2s linear;
    transition-property: background-color, color; }

.sorting-block {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: normal;
  flex-shrink: 0;
  z-index: 5; }
  .sorting-block .sorting-select-block {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: #333;
    margin-left: 7px; }
    .sorting-block .sorting-select-block .arrow svg {
      fill: #000;
      margin-left: 8px;
      transition: .2s linear;
      transition-property: transform;
      opacity: .36;
      margin-bottom: 2px; }
    .sorting-block .sorting-select-block .text {
      color: #0099ff; }
  .sorting-block.is-active .arrow svg {
    transform: rotateX(180deg); }
  .sorting-block.is-active .sorting-select__list {
    visibility: visible; }
    .sorting-block.is-active .sorting-select__list a {
      opacity: 1;
      transform: translateY(0); }
  .sorting-block .sorting-select__list {
    width: 100%;
    max-height: none;
    left: 0;
    transform: none;
    min-width: 120px;
    display: block;
    position: absolute;
    top: 100%;
    padding: 0;
    margin-top: 15px;
    list-style: none;
    box-shadow: 0 5px 20px 0 rgba(36, 47, 83, 0.1);
    visibility: hidden;
    transition: visibility .2s linear;
    overflow: auto;
    z-index: 1; }
    .sorting-block .sorting-select__list li {
      overflow: hidden; }
    .sorting-block .sorting-select__list a {
      position: relative;
      display: block;
      padding: 8px 10px;
      text-decoration: none;
      background-color: #fff;
      transform: translateY(-15px);
      opacity: 0;
      transition: .2s linear;
      transition-property: opacity,transform; }
      .sorting-block .sorting-select__list a:hover {
        background-color: #ececec; }
