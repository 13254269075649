// label.default-checkbox
//   input(type="checkbox" name="measure" hidden)
//   span.default-checkbox__custom
//       svg(width='14' height='10' viewBox="0 0 14 10" xmlns="http://www.w3.org/2000/svg")
//       use(xlink:href='#icon-check')
//   span.default-checkbox__text De ahorque

.default-checkbox {
  cursor: pointer;
  display: inline-flex;
  align-items: flex-start;
  font-size: 14px;
  line-height: 20px;
  &__custom {
    position: relative;
    border: 2px solid #fff;
    border-radius: 7px;
    flex-shrink: 0;
    width: 28px;
    height: 28px;
    margin-right: 20px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      //fill: #575049;
      fill: none;
      transform: scale(0);
      transition: all .3s cubic-bezier(.18,.89,.32,1.28);
    }
  }
  &__text {
    padding-top: 2px;
    text-align: left;
    transition: all .3s cubic-bezier(.18,.89,.32,1.28);
  }
  input {
    display: none;
    &:checked {
      & ~ .default-checkbox {
        &__custom {
          svg {
            transform: scale(1);
          }
        }
      }
    }
    &.error ~ .default-checkbox__custom {
      border-color: #FF0000;
    }
  }
  label.error {
    display: none !important;
  }
  //input.error ~ .default-checkbox__custom {
  //  border-color: #ff5730;
  //}
}
@media (max-width: $mobile) {
  .default-checkbox {
    &__custom {
      width: 20px;
      height: 20px;
      border-width: 1px;
      border-radius: 5px;
      svg {
        max-width: 9px;
        max-height: 8px;
      }
    }
  }
}
@media (max-width: $mobile-sm) {
  .default-checkbox {
    &__custom {
      margin-right: 8px;
    }
    &__text {
      font-size: 10px;
      line-height: 15px;
    }
  }
}