/* open-sans-300 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-Light';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-300.eot'), /* IE9 Compat Modes */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - cyrillic_latin 400*/
@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.eot'), /* IE9 Compat Modes */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-SemiBold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-600.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-600.eot'), /* IE9 Compat Modes */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-700.eot'), /* IE9 Compat Modes */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - cyrillic_latin */
@font-face {
  font-family: 'OpenSans-ExtraBold';
  font-style: normal;
  font-weight: normal;
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-800.woff') format('woff'), /* Modern Browsers */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-800.eot'), /* IE9 Compat Modes */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/open-sans/open-sans-v16-cyrillic_latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* play-regular - cyrillic_latin 400*/
@font-face {
  font-family: 'Play-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Play Regular'), local('Play-Regular'),
  url('../fonts/play/play-v10-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/play/play-v10-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/play/play-v10-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/play/play-v10-cyrillic_latin-regular.eot'), /* IE9 Compat Modes */
  url('../fonts/play/play-v10-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/play/play-v10-cyrillic_latin-regular.svg#Play') format('svg'); /* Legacy iOS */
}
/* play-700 - cyrillic_latin */
@font-face {
  font-family: 'Play-Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Play Bold'), local('Play-Bold'),
  url('../fonts/play/play-v10-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/play/play-v10-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/play/play-v10-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/play/play-v10-cyrillic_latin-700.eot'), /* IE9 Compat Modes */
  url('../fonts/play/play-v10-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/play/play-v10-cyrillic_latin-700.svg#Play') format('svg'); /* Legacy iOS */
}
/* roboto-900 - cyrillic_latin */
@font-face {
  font-family: 'Roboto-Black';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Black'), local('Roboto-Black'),
  url('../fonts/roboto/roboto-v19-cyrillic_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto/roboto-v19-cyrillic_latin-900.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto/roboto-v19-cyrillic_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto/roboto-v19-cyrillic_latin-900.eot'), /* IE9 Compat Modes */
  url('../fonts/roboto/roboto-v19-cyrillic_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto/roboto-v19-cyrillic_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* Bebas Neue Cyrillic */
@font-face {
  font-family: 'BebasNeueCyrillic';
  font-style: normal;
  font-weight: normal;
  src: local('BebasNeueCyrillic'),
  url('../fonts/bebas/BebasNeueCyrillic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/bebas/BebasNeueCyrillic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/bebas/BebasNeueCyrillic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/bebas/BebasNeueCyrillic.eot'), /* IE9 Compat Modes */
  url('../fonts/bebas/BebasNeueCyrillic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/bebas/BebasNeueCyrillic.svg#BebasNeueCyrillic') format('svg'); /* Legacy iOS */
}