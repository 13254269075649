.btn {
  position: relative;
  display: inline-block;
  width: auto;
  padding: 5px 20px;
  border: 2px solid $blue-00;
  border-radius: 17px;
  outline: none;
  cursor: pointer;
  z-index: 1;
  background-color: $blue-00;
  transition: background-color .25s linear;
  .btn-inner {
    font-size: 14px;
    line-height: 21px;
    color: #fff;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    transition: .25s linear;
    transition-property: color, background-color, border-color;
  }
  &:hover {
    background-color: rgba(0,0,0,0);
    .btn-inner {
      color: $blue-00;
    }
  }
}