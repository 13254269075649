.slider-ctrl {
  display: flex;
  align-items: center;
  .default-arrow {
    &--prev {
      order: -1;
    }
    &--next {
      order: 1;
    }
  }
}
.default-arrow {
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    transition: fill .25s linear;
  }
  &:hover {
    svg {
      fill: $blue-00;
    }
  }
}
.slider-dots,
.slick-dots {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 20px;
  li {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10px;
    height: 10px;
    cursor: pointer;
    margin-right: 10px;
    background: none;
    &:last-child {
      margin-right: 0;
    }
    &.is-active,
    &.slick-active,
    &:hover {
      button {
        background-color: $blue-00;
      }
    }
  }
  button {
    font-size: 0;
    width: 6px;
    height: 6px;
    padding: 0;
    margin: 0;
    border-radius: 50%;
    background-color: #bebebe;
    transition: background-color .25s linear;
  }
}