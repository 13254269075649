.footer {
  background-color: rgb(24, 24, 24);
  color: #fff;
  padding: 94px 0 100px;
  &__grid {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__logo {
    flex-shrink: 0;
  }
  &__tools {
    display: none;
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background-color: rgba(#ececec,.12);
      margin-right: 6px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__text-block {
    max-width: 374px;
    width: 100%;
    padding: 0 10px;
  }
  &__text {
    font-size: 12px;
    line-height: 24px;
    color: rgba(255,255,255,.26);
  }
  &__agreement {
    color: $blue-00;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    margin-top: 27px;
    transition: color .2s linear;
    &:hover {
      color: #fff;
    }
  }
  &__copy {
    font-size: 14px;
    line-height: 20px;
    color: rgba(255,255,255,.26);
    margin-top: 20px;
  }
  &__nav {
    column-count: 2;
    max-width: 470px;
    padding-right: 10px;
    width: 100%;
    font-family: $default-font-bold;
    font-size: 13px;
    line-height: 1.2;
    text-transform: uppercase;
  }
  &__nav-link {
    transition: color .2s linear;
    &:hover {
      color: $blue-00;
    }
  }
  &__nav-item {
    margin-bottom: 20px;
  }
  &__work-text {
    font-size: 12px;
    line-height: 18px;
    color: rgba(255,255,255,.26);
    text-align: left;
    margin-bottom: 12px;
  }
  &__work {
    text-align: right;
    a {
      display: inline-block;
    }
  }
  &__work-copy {
    display: none;
    font-size: 12px;
    line-height: 20px;
    color: rgba(255,255,255,.26);
  }
}

@media (max-width: $desktop) {
  .footer {
    padding: 50px 0;
    &__text-block {
      max-width: 330px;
    }
  }
}
@media (max-width: $tablet) {
  .footer {
    &__grid {
      flex-wrap: wrap;
    }
    &__text-block {
      order: 1;
      width: calc(100% - 175px);
      max-width: none;
    }
    &__work {
      order: 2;
    }
    &__nav {
      width: calc(100% - 175px);
      max-width: none;
      padding-right: 0;
      padding-left: 50px;
      margin-bottom: 30px;
    }
  }
}
@media (max-width: $mobile-big) {
  .footer {
    &__nav {
      padding-left: 20px;
    }
  }
}
@media (max-width: 639px) {
  .footer {
    &__nav {
      font-size: 12px;
    }
  }
}
@media (max-width: $mobile) {
  .footer {
    padding: 45px 0 30px;
    &__grid {
      flex-direction: column;
    }
    &__copy {
      display: none;
    }
    &__logo {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 40px;
    }
    &__tools {
      display: flex;
      align-items: center;
    }
    &__nav {
      width: 100%;
      padding-left: 0;
      margin-bottom: 25px;
    }
    &__text-block {
      width: 100%;
      padding: 0;
      margin-bottom: 30px;
    }
    &__text {
      line-height: 16px;
    }
    &__agreement {
      margin-top: 22px;
    }
    &__work {
      display: flex;
      align-items: center;
    }
    &__work-text-block {
      margin-right: 17px;
    }
    &__work-text {
      margin-bottom: 2px;
    }
    &__work-copy {
      display: block;
    }
  }
}
@media (max-width: $mobile-sm) {
  .footer {
    &__nav {
      column-count: unset;
      margin-bottom: 10px;
    }
  }
}