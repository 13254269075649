.article {
	background-color: $gray-ec;

	.container {
		display: flex;
		justify-content: space-between;
	}
	.article-bg {
		position: absolute;
		right: 0;
		top: 0;
		//width: 100%;
		//width: 1328px;
		width: calc(100vw - 360px - 32px);
		height: 100%;
		background-color: #000;
		span {
			display: block;
			width: 100%;
			height: 100%;
			background: right/cover no-repeat;
			opacity: .33;
		}
	}
	.article-item {
		&__tags {
			margin-bottom: 53px;
		}
		&__title {
			margin-bottom: 63px;
		}
	}

	.text-large {
		font-family: $default-font-light;
		font-size: 24px;
		line-height: 42px;
	}
	.line-top {
		font-size: 14px;
		line-height: 24px;
		&::before {
			content: '';
			display: block;
			width: 26px;
			height: 2px;
			background-color: #000;
			margin-bottom: 50px;
		}
	}
	.text-float {
		display: inline-block;
		font-family: $default-font-semibold;
		font-size: 24px;
		line-height: 36px;
		text-align: center;
		width: 445px;
		padding: 25px 0;
		transform: translateX(-90px);
		float: left;
	}
	.block-img {
		display: flex;
		align-items: flex-start;
		margin: 80px 0;
		img {
			max-width: calc(100% - 170px - 50px + 90px);
			transform: translateX(-90px);
			margin-right: -90px;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				width: 100%;
			}
		}
		p {
			display: inline-block;
		}
		.line-top {
			display: inline-block;
			width: 170px;
			margin-left: 50px;
		}
	}
	.row-share {
		margin-top: 80px;
	}
}
.article-content {
	width: calc(100% - 360px - 32px);
	h3 {
		font-family: $default-font-bold;
		font-size: 30px;
		line-height: 36px;
		margin-bottom: 35px;
	}
}
.article-aside {
	width: 360px;
	padding-top: 25px;
	.card-article {
		margin-bottom: 25px;
	}
}
.article-header {
	position: relative;
	padding-top: 60px;
	padding-bottom: 74px;
	padding-right: 80px;
	background-color: #000;
	color: #fff;
	.article-header-inner {
		position: relative;
	}
	.link-back {
		margin-bottom: 210px;
	}
}

.article-body {
	position: relative;
	padding-top: 110px;
	padding-left: 130px;
	padding-right: 30px;
	padding-bottom: 80px;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100%;
		background-color: #fff;
	}
	.article-body-inner {
		position: relative;
		max-width: 786px;
	}
	p {
		margin-bottom: 28px;
	}
}

.article-comments {
	padding-top: 60px;
	padding-bottom: 110px;
	padding-left: 130px;
}
.article-comments-header {
	display: flex;
	align-items: center;
	margin-bottom: 32px;
	.sorting-block {
		margin-left: auto;
	}
	.add-comment {
		margin-left: 85px;
	}
}

.article-comments-grid {
	margin-bottom: 53px;
}

.comment-item {
	background-color: #fff;
	border-radius: 4px;
	padding: 39px 36px;
	margin-bottom: 7px;
	display: flex;
	position: relative;
	&.reply {
		margin-left: 92px;
		&::before {
			content: '';
			position: absolute;
			top: 50px;
			left: -44px;
			width: 9px;
			height: 15px;
			background: url(../img/icons/arr-top-comment.svg) center/contain no-repeat;
		}
	}
	&--gray {
		.comment-item__body {
			color: rgba(#000, .58);
		}
	}
	&__rating {
		position: absolute;
		top: 25px;
		right: 26px;
		display: flex;
		align-items: center;
		button {
			padding: 0;
			background: none;
			&.is-active {
				pointer-events: none;
			}
			&:hover, &.is-active {
				svg {
					fill: $blue-00;
				}
			}
			svg {
				fill: #a8a8a8;
				transition: fill .2s linear;
			}
		}
	}
	&__rating-val {
		width: 40px;
		text-align: center;
		font-size: 11px;
		&.minus {
			color: #e03a0d;
		}
	}
	&__avatar {
		flex-shrink: 0;
		width: 44px;
		height: 44px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 22px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__content {
		width: calc(100% - 44px - 22px);
	}
	&__header {
		font-size: 14px;
		line-height: 28px;
		margin-bottom: 10px;
		a {
			transition: color .2s linear;
			&:last-of-type {
				&:after {
					display: none;
				}
			}
			&:after {
				content: '→';
				display: inline-block;
				color: $blue-00;
				margin: 0 10px;
			}
			&:hover {
				color: $blue-00;
			}
		}
	}
	&__time {
		color: #7f8187;
	}
	&__body {
		margin-bottom: 15px;
	}
	&__reply {
		font-size: 14px;
		color: $blue-00;
		transition: color .2s linear;
		&:hover {
			color: #000;
		}
	}
}

.article-row-btns {
	display: flex;
	justify-content: center;
}

.article-comments-more {
	display: inline-flex;
	align-items: center;
	color: $blue-00;
	font-size: 14px;
	&:hover {
		.icon {
			background-color: #fff;
			color: $blue-00;
		}
	}
	.icon {
		display: inline-flex;
		justify-content: center;
		flex-shrink: 0;
		align-items: center;
		text-align: center;
		width: 28px;
		height: 28px;
		border-radius: 50%;
		border: 1px solid $blue-00;
		background-color: $blue-00;
		color: #fff;
		line-height: 1;
		font-size: 12px;
		padding-bottom: 2px;
		margin-left: 12px;
		transition: .2s linear;
		transition-property: color, background-color;
		&:after {
			content: '↓';
		}
	}
}

.article-comment-widget {
	margin-top: 54px;
	padding-top: 38px;
	background-color: #fff;
	.title {
		padding-left: 64px;
		//margin-bottom: 34px;
		color: #23252a;
	}
}

.comment-widget {
	background-color: #fff;
	padding: 38px 64px 38px 36px;
	border-radius: 4px;
	display: flex;

	&__avatar {
		width: 44px;
		height: 44px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 20px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__form {
		width: calc(100% - 64px);
	}

	&__container {
		//height: 256px;
		height: 214px;
		margin-bottom: 17px;
		scrollbar-color: $blue-00 $gray-ec;
		scrollbar-width: thin;
		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
			background-color: $gray-ec;
		}
		&::-webkit-scrollbar-thumb:vertical {
			background-color: $blue-00;
		}
		&::-webkit-scrollbar-thumb:horizontal {
			background-color: $blue-00;
		}

		.ql-editor {
			scrollbar-color: $blue-00 $gray-ec;
			scrollbar-width: thin;
			&::-webkit-scrollbar {
				width: 4px;
				height: 4px;
				background-color: $gray-ec;
			}
			&::-webkit-scrollbar-thumb:vertical {
				background-color: $blue-00;
			}
			&::-webkit-scrollbar-thumb:horizontal {
				background-color: $blue-00;
			}
		}
	}

	&__form-footer {
		display: flex;
		align-items: center;
		font-size: 14px;

		.icon {
			width: 12px;
			height: 11px;
			fill: $blue-00;
			margin-right: 10px;
		}
	}

	&__notice {
		margin-right: 30px;
	}

	&__form-submit {
		margin-left: auto;
	}

	.link-btn {
		color: $blue-00;
		text-transform: uppercase;
		border: 1px solid $blue-00;
		padding: 6px 12px;
		margin-left: 10px;
		transition: .2s linear;
		transition-property: color, background-color;
		&:hover {
			color: #fff;
			background-color: $blue-00;
		}
	}

}

@media (max-width: 1400px) {
	.article-header {
		padding-top: 40px;
		padding-bottom: 40px;
		padding-right: 40px;
	}
	.article-content {
		width: calc(100% - 360px - 20px);
	}
	.article-aside {
		padding-top: 20px;
		.card-article {
			margin-bottom: 20px;
		}
	}
	.article {
		.like-box .like-bar-box {
			width: 200px;
		}
		.block-img {
			img {
				transform: translateX(-100px);
			}
			.line-top {
				width: 190px;
				margin-left: 30px;
			}
		}
	}
	.article-item {
		&__footer {
			font-size: 14px;
		}
	}
	.article-body {
		padding: 80px 30px 40px 80px;
	}
}
@media (max-width: 1280px) {
	.comment-widget {
		padding: 38px 30px 38px 30px;
	}
}
@media (max-width: $desktop) {
	.article-content {
		width: 100%;
	}
	.article-aside {
		display: none;
	}
	.article {
		.article-bg {
			width: 100%;
		}
	}
	.article-header {
		padding: 40px 20px;
		.link-back {
			margin-bottom: 160px;
		}
	}
	.article {
		.container {
			padding: 0;
		}
		.block-img {
			img {
				max-width: calc(100% - 400px - 50px + 90px);
			}
			.line-top {
				width: 400px;
			}
		}
		.text-float {
			width: 500px;
			transform: translateX(-20px);
		}
	}
	.article-comments {
		padding: 40px 20px;
	}
	.article-body {
		padding: 40px 20px;
		.article-body-inner {
			max-width: none;
		}
	}
}
@media (max-width: $tablet) {
	.article {
		.like-box {
			.like-bar-box {
				width: 155px;
			}
		}
		.text-large {
			line-height: normal;
		}
	}
	.comment-widget {
		&__form-footer {
			display: block;
		}
		&__notice {
			margin-right: 0;
			margin-bottom: 15px;
		}
		.link-btn {
			line-height: 35px;
		}
	}
}
@media (max-width: 900px) {
	.article {
		.block-img {
			img {
				max-width: calc(100% - 250px - 50px + 90px);
			}
			.line-top {
				width: 250px;
			}
		}
	}
}
@media (max-width: $mobile-big) {
	.article {
		.like-box .like-bar-box {
			width: 260px;
		}
		.text-float {
			width: 100%;
			transform: none;
			float: none;
			text-align: left;
			font-size: 18px;
			line-height: normal;
			padding: 10px 0 20px;
		}
		.block-img {
			margin: 50px 0;
		}
		.row-share {
			margin-top: 50px;
		}
		.text-large {
			font-size: 18px;
		}
	}
	.article-content h3 {
		font-size: 24px;
		line-height: normal;
	}
	.article-header {
		.link-back {
			margin-bottom: 60px;
		}
	}
	.article-header,
	.article-body,
	.article-comments {
		padding: 40px 27px;
	}
	.article-item {
		&__footer {
			flex-wrap: wrap;
		}
		&__rating {
			width: 100%;
		}
		&__published,
		&__comments,
		&__view {
			margin-bottom: 20px;
		}
	}
	.article-comments-header {
		flex-wrap: wrap;
		.add-comment {
			margin-left: 0;
			margin-top: 20px;
			margin-right: 300px;
		}
		.sorting-block .sorting-select__list {
			left: auto;
			right: 0;
		}
	}
	.comment-item {
		&__rating {
			top: 15px;
		}
	}
}
@media (max-width: 639px) {
	.comment-item {
		padding: 35px 25px 25px;
		&.reply {
			margin-left: 40px;
			&::before {
				left: -30px;
			}
		}
	}
}
@media (max-width: $mobile) {
	.article {
		.block-img {
			display: block;
			text-align: center;
			margin: 30px 0;
			img {
				width: 100%;
				max-width: 300px;
				transform: none;
				margin: auto;
			}
			.line-top {
				display: block;
				width: 100%;
				margin-left: 0;
				text-align: left;
				&::before {
					margin: 15px auto;
				}
			}
		}
		.article-item {
			&__title {
				font-size: 28px;
				line-height: normal;
			}
		}
	}
	.article-content h3 {
		font-size: 18px;
		margin-bottom: 25px;
	}
	.comment-widget {
		padding: 35px 15px;
	}
}
@media (max-width: $mobile-sm) {
	.article-comments-header {
		margin-bottom: 25px;
		.sorting-block {
			width: 100%;
			margin-left: 0;
			margin-top: 20px;
		}
		.sorting-block .sorting-select__list {
			left: 0;
			right: auto;
		}
		.add-comment {
			margin-right: 0;
		}
	}
	.comment-item {
		display: block;
		padding: 35px 15px 25px;
		&.reply {
			margin-left: 25px;
			&::before {
				left: -20px;
			}
		}
		&__avatar {
			width: 40px;
			height: 40px;
		}
		&__header {
			line-height: 24px;
		}
		&__avatar {
			margin-bottom: 10px;
		}
		&__content {
			width: 100%;
		}
	}
	.comment-widget {
		padding: 25px 15px;
		&__avatar {
			width: 40px;
			height: 40px;
			margin-right: 15px;
		}
	}
	.article-comment-widget {
		padding-top: 25px;
		.title {
			padding-left: 15px;
		}
	}
}
@media (max-width: 400px) {
	.comment-widget {
		padding: 25px 8px 25px 15px;
	}
}