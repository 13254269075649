.related-articles {
	padding-top: 120px;
	padding-bottom: 90px;
	background-color: #242424;
	color: #fff;

	.section-title {
		margin-bottom: 50px;
	}

	.card-article {
		color: #000;
		margin-bottom: 30px;
		&:last-child {
			margin-bottom: 0;
		}
		&.inverted {
			color: #fff;
		}
		&.big {
			height: 100%;
		}
		&.icon {
			height: 100%;
			min-height: 460px;
		}
	}
}

.related-articles-grid {
	display: flex;
	justify-content: space-between;
}
.related-articles-col {
	display: flex;
	flex-direction: column;
	width: calc(50%/2 - 30px/2);
	&:nth-child(3) {
		width: calc(50% - 60px/2);
	}
}
@media (max-width: 1400px) {
	//.related-articles {
	//	.card-article {
	//		&__inner {
	//			padding: 34px 30px 32px;
	//		}
	//	}
	//}
}
@media (max-width: $desktop) {
	.related-articles {
		.card-article {
			margin-bottom: 15px;
			&.icon {
				min-height: 435px
			}
			//&__inner {
			//	padding: 34px 15px 32px;
			//}
		}
		.card-article.big .card-article__title {
			font-size: 36px;
			line-height: normal;
		}
	}
	.related-articles-col {
		width: calc(50%/2 - 15px/2);
		&:nth-child(3) {
			width: calc(50% - 30px/2);
		}
	}
}
@media (max-width: $tablet) {
	.related-articles {
		padding: 50px 0;
		.card-article {
			//margin-bottom: 40px;
		}
	}
	.related-articles-col {
		//width: calc(50% - 20px);
		width: calc(100%/3 - 10px);
		&:nth-child(3) {
			width: calc(100%/3 - 10px);
		//	width: 100%;
		//	margin-top: 40px;
		}
		.card-article.big .card-article__title {
			font-size: 24px;
		}
	}
}
@media (max-width: $mobile-big) {
	.related-articles-grid {
		flex-wrap: wrap;
	}
	.related-articles-col {
		width: calc(50% - 15px);
		&:nth-child(3) {
			width: 100%;
			margin-top: 30px;
		}
	}
}
@media (max-width: $mobile) {
	.related-articles {
		.section-title {
			margin-bottom: 20px;
		}
		.card-article {
			&.icon {
				min-height: auto;
			}
		}
	}
	.related-articles-col {
		width: 100%;
		margin-bottom: 15px;
		&:nth-child(3) {
			margin-top: 0;
		}
	}
}