.pagination {
  font-size: 14px;
  line-height: normal;
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
    &.prev,
    &.next {
      .pagination {
        &__link {
          width: auto;
          height: 42px;
          padding: 2px 20px;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            left: -12px;
            top: 0;
            bottom: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 21px 12px 21px 0;
            border-color: transparent #fff transparent transparent;
            transition: all .2s linear;
          }
        }
      }
    }
    &.next {
      .pagination {
        &__link {
          padding-right: 15px;
          &::before {
            left: auto;
            right: -12px;
            transform: scaleX(-1);
          }
        }
      }
    }
    &.prev {
      .pagination {
        &__link {
          padding-left: 15px;
        }
      }
    }
    &.spread {
      pointer-events: none;
    }
    &:hover {
      .pagination {
        &__link {
          color: #fff;
          background-color: $blue-00;
          &::before {
            border-color: transparent $blue-00 transparent transparent;
          }
        }
      }
    }
  }
  &__link {
    background-color: #fff;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    transition: .2s linear;
    transition-property: background-color, color;
  }
}