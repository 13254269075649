.cat-select-block {
  display: flex;
}
.cat-select {
  position: relative;
  z-index: 5;
  margin-right: 15px;
  &.is-active {
    .cat-select {
      &__list {
        visibility: visible;
      }
      &__item-link {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  &__main {
    font-size: 14px;
    display: flex;
    align-items: center;
    width: 215px;
    height: 35px;
    background-color: $blue-00;
    padding: 0 25px;
    border-radius: 70px;
    cursor: pointer;
    position: relative;
    z-index: 1;
  }
  &__main-init,
  &__main-ready {
    display: block;
    width: 100%;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #fff;
  }
  &__main-ready {
    display: none;
    span {
      margin-left: 3px;
    }
  }
  input {
    display: none;
  }
  &__list {
    font-size: 14px;
    display: block;
    width: 100%;
    max-height: 256px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0;
    margin: 0;
    list-style: none;
    box-shadow: 0 5px 20px 0 rgba(36,47,83,.1);
    visibility: hidden;
    transition: visibility .2s linear;
    overflow: auto;
    scrollbar-color: $blue-00 $gray-ec;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 2px;
      background-color: $gray-ec;
    }
    &::-webkit-scrollbar-thumb:vertical {
      background-color: $blue-00;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: $blue-00;
      border-radius: 2px;
    }
  }
  .cat-select__item {
    overflow: hidden;
    &:first-child {
      .cat-select__item-link {
        padding-top: calc(18px + 5px);
      }
    }
  }
  &__item {
    &.is-active {
      pointer-events: none;
      .cat-select {
        &__item-link {
          background-color: $gray-ec;
        }
      }
    }
  }
  &__item-link {
    position: relative;
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;
    transform: translateY(-15px);
    opacity: 0;
    transition: .2s linear;
    transition-property: opacity, transform;
    &:hover {
      background-color: $gray-ec;
    }
  }
}
.cat-list-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  &__item {
    font-size: 12px;
    line-height: normal;
    border: 1px solid $blue-00;
    color: $blue-00;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 4px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  &__arrow {
    width: 10px;
    height: 10px;
    display: block;
    position: relative;
    cursor: pointer;
    margin-left: 8px;
    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: calc(50% - 1px);
      background-color: $blue-00;
      width: 10px;
      height: 1px;
    }
    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }
  }
}