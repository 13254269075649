.rent {
  padding: 79px 0 69px;
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }
  &__grid {
    display: flex;
  }
  &__ctrl-block {
    flex-shrink: 0;
  }
  &__all-link {
    flex-shrink: 0;
    margin-left: 67px;
  }
  &__footer {
    display: none;
    text-align: center;
  }
}
.rent-item {
  width: calc((100% - 150px)/6);
  background-color: #f2f2f2;
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
  &__inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
      display: block;
    }
  }
  &__img-block {
    display: inline-block;
    width: 100%;
    height: 145px;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
  }
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 30px 27px;
  }
  &__class-block {
    position: absolute;
    right: 15px;
    top: 10px;
    background-color: $blue-00;
    padding: 5px 7px 5px 10px;
  }
  &__class {
    font-size: 12px;
    line-height: 14px;
    color: #fff;
  }
  &__title {
    font-family: $default-font-bold;
    font-size: 18px;
    margin-bottom: 10px;
  }
  &__text {
    font-size: 12px;
    line-height: 14px;
    color: rgba(0,0,0,.65);
    margin-bottom: 10px;
  }
  &__value {
    font-family: $default-font-bold;
    font-size: 16px;
    line-height: normal;
    margin-top: auto;
  }
}

@media (max-width: 1600px) {
  .rent-item {
    width: calc((100% - 100px)/6);
    margin-right: 20px;
    &__content {
      padding: 25px 20px;
    }
  }
}
@media (max-width: 1400px) {
  .rent-item {
    width: calc((100% - 75px)/6);
    margin-right: 15px;
  }
}
@media (max-width: 1280px) {
  .rent {
    .container {
      padding-right: 0;
    }
    &__title {
      margin-right: auto;
    }
  }
  .rent-item {
    width: 240px;
    min-height: 320px;
    &__inner {
      min-height: 320px;
    }
  }
}
@media (max-width: $mobile-big) {
  .rent {
    padding: 40px 0 47px;
    &__header {
      padding-right: 27px;
    }
    a.rent__all-link {
      display: none;
    }
    &__footer {
      display: block;
      a.rent__all-link {
        display: inline-block;
        margin-top: 28px;
        margin-left: 0;
      }
    }
  }
}
@media (max-width: $mobile) {
  .rent {
    &__ctrl-block {
      &.slider-ctrl {
        display: none;
      }
    }
    &__header {
      display: block;
    }
    &__title {
      margin-right: 0;
    }
  }
}
@media (max-width: $mobile-sm) {
  .rent {
    &__header {
      text-align: center;
    }
  }
  .rent-item {
    width: 232px;
    min-height: 330px;
    &__inner {
      min-height: 330px;
    }
    &__content {
      padding: 27px;
    }
  }
}