.cat-item {
  display: none;
  &.show {
    display: block;
  }
}
.cat-block {
  background-color: #fff;
  padding: 30px 55px 50px;
  &__title {
    font-family: $default-font-semibold;
    font-size: 24px;
    line-height: normal;
    padding-top: 10px;
  }
  &__more-item {
    display: inline-flex;
    align-items: center;
    color: $blue-00;
    line-height: normal;
    cursor: pointer;
    padding-left: 10px;
    margin-top: 15px;
    svg {
      margin-left: 16px;
      fill: $blue-00;
      transition: .2s linear;
      transition-property: transform;
    }
    &.is-active {
      svg {
        transform: rotateX(180deg);
      }
    }
  }
}
.cat-block-tabs {
  .wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__item-m-block,
  &__item-a-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__item {
    font-size: 16px;
    line-height: normal;
    cursor: pointer;
    margin-bottom: 10px;
    position: relative;
    background-color: rgba($blue-00,0);
    transition: all .25s linear;
    padding: 2px 10px;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      min-height: 26px;
    }
    &::before {
      content: '';
      position: absolute;
      left: -11px;
      top: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 13px 11px 13px 0;
      border-color: transparent #0099ff transparent transparent;
      opacity: 0;
      transition: opacity .25s linear;
    }
    &:last-child {
      margin-bottom: 0;
    }
    span {
      position: relative;
      transition: color .25s linear;
      display: inline-block;
    }
    &:hover,
    &.is-active {
      background-color: $blue-00;
      &::before {
        opacity: 1;
      }
      span {
        color: #fff;
      }
    }
  }
  &__item-m-block {
    margin-top: 15px;
  }
  &__item-a-block {
    display: none;
    margin-top: 15px;
  }
}
@media (max-width: 1600px) {
  .cat-block {
    padding: 30px;
  }
}
@media (max-width: 1279px) {
  .cat-block-tabs {
    &__item-m-block {
      flex-direction: row;
      flex-wrap: wrap;
      display: inline-block;
      margin-top: 0;
    }
    &__item-a-block {
      //display: flex;
      //flex-direction: row;
      //flex-wrap: wrap;
      //display: inline-block;
      margin-top: 0;
      display: none;
    }
    &__item {
      display: inline-block;
      margin-right: 10px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 10px;
      }
      &::before {
        display: none;
      }
    }
  }
  .cat-block {
    padding: 20px 20px 10px;
    &__more-item {
      display: none;
    }
  }
}
@media (max-width: $mobile-sm) {
  .cat-block-tabs {
    &__item-m-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &__item {
      &::before {
        display: block;
      }
    }
  }
  .cat-block {
    padding-bottom: 20px;
    &__more-item {
      font-size: 16px;
      display: flex;
      margin-top: 10px;
    }
  }
}