* {
  box-sizing: border-box;
  outline: none;
}

*::before, *::after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

ul, ol {
  margin: 0;
  &.no-style {
    padding: 0;
    list-style: none;
  }
}

button {
  outline: none;
  border: none;
}

img {
  border: none;
}

a {
  text-decoration: none;
  color: inherit;
}

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block; /* For Firefox */
}

select {
  appearance: none;
  &::-ms-expand { // для IE
    display: none;
  }
}

button {
  cursor: pointer;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
//fix yellow bg
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 9999s ease-in-out 0s;
  -webkit-text-fill-color: #404040 !important;
  //-webkit-box-shadow: 0 0 0 30px white inset !important; //maxh input = 2*30px
}

blockquote {
  margin: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: $default-font-semibold;
}
h1 {
  font-size: 60px;
  line-height: normal;
}
h2 {
  font-size: 24px;
  line-height: 1.2;
  @media (max-width: 639px) {
    font-size: 18px;
  }
}
h3 {
  font-size: 18px;
  line-height: 24px;
}