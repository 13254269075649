.training {
  background-color: $gray-ec;
  padding: 74px 0 22px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    overflow: hidden;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -28px;
  }
  .training-item {
    width: calc((100% - 112px)/4);
    margin-left: 28px;
    margin-bottom: 28px;
    background-repeat: no-repeat;
    background-size: cover;
    &--wide {
      width: calc((100% - 56px)/2);
    }
  }
  &__footer {
    display: none;
  }
}

.training-item {
  background-color: #fff;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 364px;
  padding: 30px 30px 25px 40px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  &__profile {
    font-size: 12px;
    line-height: normal;
    text-transform: uppercase;
    color: $blue-00;
    border: 1px solid $blue-00;
    padding: 4px 10px;
  }
  &__company {
    max-width: 150px;
    max-height: 50px;
  }
  &__main {
    display: flex;
  }
  &__img-wrap {
    flex-shrink: 0;
    border-radius: 50%;
    overflow: hidden;
    max-width: 100px;
    max-height: 100px;
    margin-right: 30px;
  }
  &__content {
    max-width: 125px;
  }
  &__title {
    font-family: $default-font-bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  &__info-time {
    display: flex;
    align-items: center;
    svg {
      flex-shrink: 0;
      fill: #272727;
    }
  }
  &__time-block {
    font-size: 14px;
    line-height: 18px;
    color: rgba(0,0,0,.63);
    margin-left: 12px;
  }
  &__footer {
    display: flex;
    align-items: center;
    margin-top: auto;
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-top: 30px;
    }
  }
  &__reviews {
    display: flex;
    align-items: center;
    margin-left: 12px;
    img {
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
  &__reviews-text {
    font-size: 12px;
    line-height: normal;
  }
  &__link {
    display: inline-block;
    margin-left: auto;
    .icon {
      @extend %blue-circle-arrow;
    }
  }
  &--alone {
    .training-item {
      &__profile {
        background-color: $blue-00;
        color: #fff;
      }
      &__content {
        max-width: 245px;
        color: #fff;
      }
      &__title {
        font-family: $default-font-light;
        font-size: 44px;
        line-height: 57px;
        margin: 0;
      }
      &__footer {
        color: #fff;
      }
    }
  }
  &--wide {
    color: #fff;
    .training-item {
      &__header {
        margin-bottom: 65px;
      }
      &__profile {
        color: #fff;
        background-color: $blue-00;
      }
      &__content {
        max-width: 420px;
      }
      &__title {
        font-family: $default-font-light;
        font-size: 44px;
        line-height: 57px;
        margin: 0;
        margin-bottom: 20px;
      }
      &__info-time {
        svg {
          fill: #fff;
        }
      }
      &__time-block {
        display: flex;
        color: #fff;
        margin-left: 16px;
      }
      &__link {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height: normal;
        color: $blue-00;
        order: -1;
        margin-left: 0;
        margin-right: auto;
        .text {
          margin-left: 14px;
        }
      }
    }
  }
}

@media (max-width: 1600px) {
  .training {
    &__grid {
      margin-left: -20px;
    }
    .training-item {
      width: calc((100% - 80px)/4);
      margin-left: 20px;
      padding: 30px 20px 25px 20px;
      margin-bottom: 20px;
      &--wide {
        width: calc((100% - 40px)/2);
      }
    }
  }
}
@media (max-width: 1280px) {
  .training {
    &__wrap {
      max-width: 1111px;
      margin: auto;
    }
    .training-item {
      //width: 295px;
      width: calc((100% - 60px)/3);
      &--wide {
        //width: 660px;
        width: calc(100% - 100%/3 - 20px);
      }
      &__img-wrap {
        margin-right: 20px;
      }
    }
  }
}
@media (max-width: $tablet) {
  .training {
    &__grid {
      justify-content: space-between;
      margin-left: 0;
    }
    .training-item {
      width: calc(50% - 10px);
      margin-left: 0;
      &--wide {
        width: 100%;
      }
      &__content {
        max-width: none;
      }
    }
  }
}
@media (max-width: 700px) {
  .training {
    .training-item {
      width: calc(50% - 4px);
      margin-bottom: 8px;
    }
  }
}
@media (max-width: 639px) {
  .training {
    .training-item {
      width: 100%;
      display: none;
      margin-bottom: 0;
      min-height: 300px;
      &:first-child,
      &:nth-child(2),
      &:nth-child(3) {
        display: flex;
      }
      &:nth-child(2) {
        order: 2;
      }
      &--alone {
        order: 1;
      }
    }
  }
}
@media (max-width: $mobile) {
  .training {
    padding-top: 45px;
    &__header {
      margin-bottom: 25px;
    }
  }
}
@media (max-width: $mobile-sm) {
  .training {
    .container {
      padding: 0;
    }
    &__header {
      display: block;
      text-align: center;
      padding: 0 27px;
    }
    .training-item {
      min-height: 364px;
      padding-left: 27px;
      padding-right: 27px;
    }
    a.training__all-link {
      display: none;
    }
    &__footer {
      display: block;
      text-align: center;
      a.training__all-link {
        display: inline-block;
        margin-top: 45px;
        margin-left: 0;
      }
    }
  }
}