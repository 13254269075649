.sorting-block {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: normal;
  flex-shrink: 0;
  z-index: 5;
  .sorting-select-block {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: $text-color;
    margin-left: 7px;
    .arrow {
      svg {
        fill: #000;
        margin-left: 8px;
        transition: .2s linear;
        transition-property: transform;
        opacity: .36;
        margin-bottom: 2px;
      }
    }
    .text {
      color:$blue-00;
    }
  }
  &.is-active {
    .arrow {
      svg {
        transform: rotateX(180deg);
      }
    }
    .sorting-select__list {
      visibility: visible;
      a {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .sorting-select {
    &__list {
      width: 100%;
      max-height: none;
      left: 0;
      transform: none;
      min-width: 120px;

      display: block;
      position: absolute;
      top: 100%;
      padding: 0;
      margin-top: 15px;
      list-style: none;
      box-shadow: 0 5px 20px 0 rgba(36,47,83,.1);
      visibility: hidden;
      transition: visibility .2s linear;
      overflow: auto;
      z-index: 1;
      li {
        overflow: hidden;
      }
      a {
        position: relative;
        display: block;
        padding: 8px 10px;
        text-decoration: none;
        background-color: #fff;
        transform: translateY(-15px);
        opacity: 0;
        transition: .2s linear;
        transition-property: opacity,transform;
        &:hover {
          background-color: #ececec;
        }
      }
    }
  }
}