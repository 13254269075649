// Widths
$mobile-sm:     479px;
$mobile:        575px;
$mobile-big:    767px;
$tablet:        991px;
$desktop:       1199px;

// Fonts
$default-font: "OpenSans-Regular", sans-serif;
$default-font-light: "OpenSans-Light", sans-serif;
$default-font-semibold: "OpenSans-SemiBold", sans-serif;
$default-font-bold: "OpenSans-Bold", sans-serif;
$default-font-exbold: "OpenSans-ExtraBold", sans-serif;

$font-play: "Play-Regular", sans-serif;
$font-play-bold: "Play-Bold", sans-serif;

$font-roboto-black: "Roboto-Black", sans-serif;

$font-bebas: "BebasNeueCyrillic", sans-serif;

// Colors
$text-color: #333;

$color-placeholder: #929292;

$black-01: #010101;
$black-3: #333;
$blue-00 : #0099ff;
$blue-01: #018ae6;
$gray-ec: #ececec;
$gray-93: #939393;
$gray-68: #686868;