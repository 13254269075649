.articles {
  background-color: $gray-ec;
  padding: 50px 0 150px;
  //.like-box {
  //  .like-bar-box {
  //    margin: 0;
  //    width: 100%;
  //  }
  //}
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -31px;
  }
  .card-article {
    width: calc((100% - 124px)/4);
    margin-left: 31px;
    margin-bottom: 31px;
    display: none;
    &.img {
      height: auto;
    }
    &.show {
      display: block;
    }
    &:last-child {
      margin-bottom: 31px;
    }
    &.inverted {
      width: calc((100% - 62px)/2);
      .card-article {
        &__inner {
          height: 100%;
          justify-content: center;
          align-items: center;
        }
        &__title {
          font-family: $default-font-light;
          font-size: 44px;
          line-height: 57px;
          margin-bottom: 30px;
        }
      }
    }

  }
  &__footer {
    text-align: center;
  }
  &__more-block {
    margin-top: 50px;
  }
  &__more-text {
    font-size: 14px;
    line-height: normal;
    margin-bottom: 20px;
  }
  &__more-link {
    @extend %blue-circle-arrow;
    margin: auto;
    svg {
      transform: rotate(90deg);
    }
  }
}

.articles-cat-block {
  width: calc((100% - 124px)/4);
  //margin-left: 31px;
  margin-bottom: 31px;
  margin-left: auto;
  &.mobile {
    display: none;
  }
  .cat-block {
    height: 100%;
  }
}

@media (max-width: 1700px) {
  .articles {
    .sorting-block .sorting-select__list {
      left: auto;
      right: 0;
    }
  }
}
@media (max-width: 1279px) {
  .articles-cat-block {
    display: none;
    width: 100%;
    &.mobile {
      display: block;
    }
    .cat-block {
      width: 100%;
      &__title {
        padding-top: 0;
        margin-bottom: 20px;
      }
    }
  }
  .articles {
    padding: 50px 0;
    &__footer {
      margin-top: 40px;
    }
    &__grid {
      margin-left: -15px;
    }
    .card-article {
      width: calc((100% - 60px)/4);
      margin-left: 15px;
      margin-bottom: 15px;
      &.inverted {
        width: calc((100% - 30px)/2);
        .card-article {
          &__title {
            font-size: 40px;
            line-height: 52px;
          }
        }
      }
      &__inner {
        //padding: 25px;
      }
      //&__title {
      //  font-size: 16px;
      //  line-height: 22px;
      //}
    }
  }
}
@media (max-width: 1100px) {
  .articles {
    .card-article {
      width: calc((100% - 46px)/3);
      &__inner {
        //padding: 15px;
      }
      &.img {
        .card-article {
          &__bg {
            height: 180px;
          }
        }
      }
      &.inverted {
        width: calc((100% - 46px)/3);
        .card-article {
          &__title {
            font-size: 24px;
            line-height: normal;
          }
        }
      }
      &:last-child {
        margin-bottom: 15px;
      }
    }
  }
}
@media (max-width: $mobile-big) {
  .articles {
    .card-article {
      width: calc(50% - 15px);
      &.inverted {
        width: calc(50% - 15px);
      }
    }
  }
}
@media (max-width: $mobile) {
  .articles {
    .pagination {
      display: none;
    }
    &__footer {
      margin-top: 25px;
    }
    &__more-block {
      margin-top: 0;
    }
    .cat-block__title {
      font-size: 18px;
    }
    &__header {
      display: block;
      margin-bottom: 30px;
    }
    &__title {
      margin-bottom: 15px;
    }
    //&__grid {
    //  margin: 0 -27px;
    //}
    .card-article {
      width: 100%;
      //margin-left: 0;
      &.inverted {
        width: 100%;
        .card-article {
          &__inner {
            padding: 30px 29px 50px;
          }
        }
      }
      &.img {
        .card-article {
          &__inner {
            padding: 30px 29px 50px;
          }
        }
      }
    }
  }
}
@media (max-width: $mobile-sm) {
  .articles {
    &__grid {
      margin: 0 -27px;
    }
    .card-article {
      margin-left: 0;
    }
  }
}
@media (max-width: 420px) {
  .articles .card-article.img .card-article__bg {
    height: 132px;
  }
}