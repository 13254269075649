html {
	touch-action: manipulation;
}
body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: $default-font;
	font-size: 16px;
	line-height: 24px;
	//color: $text-color;
	overflow-x: hidden;
	@media screen and (max-width: 991px) {
		font-size: 14px;
		line-height: 24px;
	}
	@media screen and (max-width: 480px) {
		font-size: 12px;
		line-height: 20px;
	}
}

.container {
	max-width: 1580px;//1540
	width: 100%;
	margin: auto;
	padding: 0 20px;
	@media screen and (max-width: 767px) {
		padding: 0 27px;
	}
}

.visually-hidden {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	clip: rect(0 0 0 0);
	overflow: hidden;
}

.apple-fix {
	width: 100%;
	position: fixed;
}

.no-scroll {
	overflow: hidden;
}

.img-wrapper {
	display: inline-block;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.clearfix {
	&::after {
		content: ".";
		display: block;
		clear: both;
		visibility: hidden;
		line-height: 0;
		height: 0;
	}
}

.wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	width: 100%;
	overflow-x: hidden;
	position: relative;
	.header {
		flex: 0 0 auto;
	}
	main {
		position: relative;
		flex: 1 0 auto;
	}
	.footer {
		flex: 0 0 auto;
	}
}

.logo {
	display: inline-block;
	font-family: $font-play;
	position: relative;
	flex-shrink: 0;
	padding-right: 24px;
	&__main {
		font-family: $font-play-bold;
		font-size: 30px;
		line-height: 30px;
		text-transform: uppercase;
		display: flex;
		align-items: center;
		span {
			width: 27px;
			height: 27px;
			background-color: #0099ff;
			display: flex;
			justify-content: center;
			align-items: center;
			border-radius: 50%;
			margin: 0 6px;
			svg {
				fill: #fff;
			}
		}
	}
	&__bot {
		font-size: 11px;
		line-height: 1;
		letter-spacing: 0.39em;
	}
	&__abr {
		font-size: 12px;
		letter-spacing: 0.2em;
		position: absolute;
		right: 0;
		top: -4px;
	}
}

%gray-circle {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: $gray-ec;
}
%gray-circle-hover {
	.icon {
		transition: .2s linear;
		transition-property: background-color;
	}
	&:hover {
		.icon {
			background-color: lighten($blue-00, 20);
		}
	}
}

%blue-circle-arrow {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	border-radius: 50%;
	width: 28px;
	height: 28px;
	background-color: $blue-00;
	border: 1px solid $blue-00;
	transition: .2s linear;
	transition-property: background-color, border-color;
	&:hover {
		background-color: #fff;
		svg {
			fill: $blue-00;
		}
	}
	svg {
		width: 8px;
		height: 7px;
		fill: #fff;
		transition: fill .2s linear;
	}
}
%white-circle-arrow {
	@extend %blue-circle-arrow;
	background-color: #fff;
	border-color: #fff;
	&:hover {
		background: $blue-00;
		svg {
			fill: #fff;
		}
	}
	svg {
		fill: $blue-00;
	}
}
%comment2 {
	fill: none;
	stroke-width: 1px;
	stroke: #000;
}
.link-back {
	display: inline-flex;
	align-items: center;
	&:hover {
		.icon {
			background-color: #fff;
			svg {
				fill: $blue-00;
			}
		}
	}
	.icon {
		@extend %blue-circle-arrow;
		margin-right: 20px;
		svg {
			transform: rotate(180deg);
		}
	}
}
.select {
	position: relative;
	z-index: 1;
	&.is-active {
		.select-list {
			visibility: visible;
			a {
				opacity: 1;
				transform: translateY(0);
			}
		}
	}
	.select-toggle {
		font-size: 14px;
		display: flex;
		align-items: center;
		width: 184px;
		height: 35px;
		background-color: $blue-00;
		padding: 0 28px;
		border-radius: 70px;
		cursor: pointer;
		position: relative;
		z-index: 1;
	}
	.select-val {
		display: block;
		width: 100%;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: #fff;
		&:empty {
			&:after {
				content: attr(data-placeholder);
			}
		}
	}
	.select-list {
		font-size: 14px;
		display: block;
		width: 100%;
		max-height: 256px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%);
		padding: 0;
		margin: 0;
		list-style: none;
		box-shadow: 0 5px 20px 0 rgba(36, 47, 83, 0.1);
		visibility: hidden;
		transition: visibility .2s linear;
		overflow: auto;
		//z-index: 1;
		scrollbar-color: $blue-00 $gray-ec;
		scrollbar-width: thin;
		&::-webkit-scrollbar {
			width: 4px;
			height: 4px;
			border-radius: 2px;
			background-color: $gray-ec;
		}
		&::-webkit-scrollbar-thumb:vertical {
			background-color: $blue-00;
			border-radius: 2px;
		}
		&::-webkit-scrollbar-thumb:horizontal {
			background-color: $blue-00;
			border-radius: 2px;
		}

		li {
			overflow: hidden;
			&:first-child {
				a {
					padding-top: calc(18px + 5px);
				}
			}
		}
		a {
			position: relative;
			display: block;
			padding: 5px 10px;
			text-decoration: none;
			background-color: #fff;
			transform: translateY(-15px);
			opacity: 0;
			transition: .2s linear;
			transition-property: opacity, transform;
			&:hover {
				background-color: $gray-ec;
			}
		}
	}
}

.card-article {
	position: relative;
	background-color: #fff;
	&.inverted {
		background-color: #000;
		color: #fff;
		.card-article__tag {
			color: #fff;
			background-color: $blue-00;
		}
		.card-article__link {
			@extend %white-circle-arrow;
		}
	}
	&.img {
		height: 460px;
		.card-article__inner {
			height: calc(100% - 220px);
			padding-top: 25px;
			padding-bottom: 25px;
		}
		.card-article__bg {
			position: relative;
			width: 100%;
			height: 220px;
			opacity: 1;
		}
		.card-article__link {
			margin-top: auto;
		}
	}
	&.icon {
		padding-top: 45px;
		.card-article__bg {
			width: 168px;
			height: 168px;
			border-radius: 50%;
			margin: 0 auto 30px;
		}
		.card-article__inner {
			height: calc(100% - 198px);
			padding-top: 0;
		}
	}
	&.big {
		.card-article__inner {
			height: 100%;
			justify-content: center;
			align-items: center;
		}
		.card-article__title {
			font-family: $default-font-light;
			font-size: 44px;
			line-height: 57px;
			margin-bottom: 30px;
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				width: 100%;
			}
		}
		.card-article__wrapper {
			text-align: center;
			display: inline-block;
			&:hover {
				.card-article__link {
					background: $blue-00;
					border-color: #fff;
					svg {
						fill: #fff;
					}
				}
				.text {
					color: $blue-00;
				}
			}
			.text {
				font-family: $default-font-light;
				font-size: 16px;
				line-height: normal;
				display: inline-block;
				transition: all .2s linear;
				margin-top: 22px;
			}
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
	&__bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: .5;
		background: center/cover no-repeat;
	}
	&__inner {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 34px 50px 32px;
	}
	&__tag {
		display: inline-block;
		font-size: 12px;
		line-height: 1;
		text-transform: uppercase;
		color: $blue-00;
		padding: 7px 12px;
		border: 1px solid $blue-00;
		text-align: center;
		margin: 0 auto 25px;
	}
	&__title {
		font-family: $default-font;
		font-weight: normal;
		font-size: 18px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 20px;
		transition: color .2s linear;
		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
			width: 100%;
		}
		&:hover {
			color: $blue-00;
		}
	}
	&__link {
		@extend %blue-circle-arrow;
		margin: 0 auto;
	}
}
.card-article {
	&__info {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	&__info,
	&__like-block {
		display: none;
	}
	&__view {
		svg {
			fill: rgb(166, 166, 166);
		}
	}
	&__review {
		svg {
			fill: #fff;
			stroke: rgb(166, 166, 166);
		}
	}
	.articles-item-link {
		text-align: center;
		display: inline-block;
		&:hover {
			.card-article__link {
				background: $blue-00;
				border-color: #fff;
				svg {
					fill: #fff;
				}
			}
			.text {
				color: $blue-00;
			}
		}
		.text {
			font-family: $default-font-light;
			font-size: 16px;
			line-height: normal;
			display: inline-block;
			transition: all .2s linear;
			margin-top: 22px;
		}
	}
}

.like-box {
	display: flex;
	align-items: center;
	.like-btn {
		background: none;
		padding: 0;
		width: 20px;
		height: 20px;
		border-radius: 0;
		&.is-active {
			pointer-events: none;
		}
		&.is-active, &:hover {
			svg {
				fill: $blue-00;
			}
		}
		svg {
			fill: #dcdcdc
		}
	}
	.like-bar-box {
		width: 260px;
		margin: 0 25px;
	}
	.like-value {
		display: flex;
		justify-content: space-between;
		font-size: 12px;
		line-height: 1;
		margin-bottom: 8px;
		span {
			&:before {
				content: attr(data-before);
				margin-right: 4px;
			}
		}
		&__like {
			color: $blue-00;
		}
		&__dislike {

		}
	}
	.like-bar-wrap {
		position: relative;
		width: 100%;
		height: 4px;
		background-color: #dcdcdc;
	}
	.like-bar {
		position: absolute;
		left: 0;
		height: 100%;
		background-color: $blue-00;
	}
}

@media (max-width: 1400px) {
	.card-article {
		&__inner {
			padding: 34px 30px 32px;
		}
	}
}
@media (max-width: 1279px) {
	.card-article {
		&__title {
			font-size: 16px;
			line-height: 22px;
		}
	}
}
@media (max-width: $desktop) {
	.card-article {
		&__inner {
			padding: 34px 15px 32px;
		}
	}
}
@media (max-width: $tablet) {
	.card-article {
		&.icon {
			.card-article {
				&__bg {
					width: 120px;
					height: 120px;
				}
				&__inner {
					height: calc(100% - 165px);
				}
			}
		}
	}
}
@media (max-width: $mobile) {
	.logo {
		padding-right: 14px;
		&__main {
			font-size: 25px;
			line-height: 30px;
			span {
				width: 23px;
				height: 23px;
			}
		}
		&__bot {
			font-size: 10px;
		}
		&__abr {
			font-size: 10px;
		}
	}
	.card-article {
		&.img {
			.card-article {
				&__inner {
					height: auto;
				}
			}
		}
		&__info {
			display: flex;
			width: 100%;
			font-size: 12px;
			color: rgba(0,0,0,.52);
			margin-bottom: 15px;
			svg {
				margin-right: 10px;
			}
		}
		&__like-block {
			display: block;
			width: 100%;
		}
		&__view,
		&__review {
			display: flex;
			align-items: center;
		}
		&__view {
			svg {
				width: 23px;
				height: 17px;
			}
		}
		&.inverted {
			.card-article {
				&__info {
					color: #fff;
				}
				svg {
					fill: #fff;
				}
				&__view {
					svg {
						fill: #fff;
					}
				}
				&__link {
					display: none;
				}
				.articles-item-link {
					display: none;
				}
			}
		}
		.like-box .like-bar-box {
			width: 100%;
			margin: 0;
		}
		&__link {
			display: none;
		}
		.articles-item-link {
			display: none;
		}
	}
}